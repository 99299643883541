// @flow
/* global window */
import { useFela, } from 'react-fela';
import React, { type Node, } from 'react';

import { border, } from '@haaretz/htz-css-tools';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Image from '../../../Image/Image';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserAuthors from '../../../TeaserAuthors/TeaserAuthors';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useWebViewChecker from '../../../../hooks/useWebViewChecker';
import getTeaserHref from '../../../../utils/getTeaserHref';
import HtzLink from '../../../HtzLink/HtzLink';
import useDocumentEventListener from '../../../../hooks/useDocumentEventListener';
import SaveToReadingList from '../../../ActionButtons/ActionSaveToReadingList/ActionSaveToReadingList';
import { writeToLocalStorage, } from '../../../../utils/writeToStorage';
import useOneTime from '../../../../hooks/useOneTime';

function firstParagraphStyles({ theme, hasFistParagraph, }) {
  return {
    flexGrow: '1',
    flexShrink: '0',
    marginTop: '1rem',
    overflow: 'hidden',
    gridArea: 'paragraph',
    maxHeight: '17rem',
    '&:before': {
      color: theme.color('primary'),
      bottom: hasFistParagraph ? '1rem' : '-1rem',
      content: '"להמשך קריאה >"',
      start: 0,
      position: 'absolute',
      fontWeight: 'bold',
      zIndex: 1,
      ...theme.type(-1),
    },
    '&:after': {
      backgroundImage: theme.spotStyle.gradient,
      bottom: 0,
      content: '""',
      height: '18rem',
      start: 0,
      end: 0,
      position: 'absolute',
    },
  };
}

SpotView.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
  biImpression: null,
  gaAction: null,
  articleId: null,
};
export default function SpotView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
  biImpression,
  updatePersonalList,
  inView,
  ...props
}: ListViewProps): Node {
  const { theme, css, } = useFela();
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = React.useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const onLoadElement = React.useCallback(e => {
    setIsLoaded(true);
  }, []);
  useDocumentEventListener('loadElement', onLoadElement, false);

  const { items, } = list;

  React.useEffect(() => {
    inView && isLoaded && items.map((item, i) => {
      i < 1 && biImpression && typeof setBiImpression === 'function' && setBiImpression(i, item, biImpression)();
      return null;
    }
    );
  }, [ biImpression, inView, isLoaded, items, ]);

  useOneTime(isWebView && Array.isArray(items) && items[0], () => writeToLocalStorage({
    key: 'spotExclude',
    // eslint-disable-next-line
    value: items[0]?.contentId,
    errorMessage: 'error writing from last article id to local storage',
  })
  );

  if (!isWebView) return null;
  if (!items || !items.length) return null;

  return (
    <Section
      className={css({
        backgroundColor: theme.color('bg', 'base'),
        display: 'grid',
        gridGap: '1rem 4rem',
        marginBottom: '4rem',
        padding: '2rem',
        width: '100%',
        gridTemplateColumns: '1fr',
        extend: [
          border(
            '1px',
            0,
            'solid',
            theme.color('neutral', '-6')
          ),
        ],
      })}
      data-test="spot"
    >
      <Section isFragment>
        {SpotItem(items[0], isLoaded && setBiAction(0, items[0], biAction), isLazyloadImages, 0)}
      </Section>
    </Section>
  );
}

function SpotItem(item: TeaserDataType, biAction, isLazyloadImages, i): Node {
  const { css, } = useFela({ hasFistParagraph: !!item.firstParagraph, });
  const href = getTeaserHref(item);
  const image = item?.mobileImage || item?.image;

  return (
    <HtzLink href={href} onClick={biAction} target={item.linkTarget}>
      <Teaser
        key={item.contentId}
        areasTemplate='"content" "media" "paragraph"'
        rowTemplate="auto 1fr auto"
        rowGap="2rem"
        backgroundColor={[ 'bg', 'base', ]}
      >
        <TeaserHeader
          {...item}
          excludeLink
          exclusiveMobile={(
            <TeaserAuthors authors={item.authors || item.credit} />)}
          showKicker
          isGridItem
        />
        <TeaserMedia data={item}>
          <Image
            image={image}
            imgOptions={{ transforms: { aspect: 'landscape', width: '514', }, }}
            lazyLoad={isLazyloadImages}
          />
        </TeaserMedia>
        <p className={css(firstParagraphStyles)}>{item.firstParagraph}</p>
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <SaveToReadingList
            isSpotList
            size={4.5}
            spotArticleId={item.contentId}
            buttonStyles={{
              justifyContent: 'end',
            }}
          />
        </button>
      </Teaser>
    </HtzLink>
  );
}
