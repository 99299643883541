// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import ListView from '../../../ListView/NewListView';
import TopNewsItems from './TopNewsItems';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import MichelleTeaser from './MichelleTeaser';
import MichelleTeaserPromoted from './MichelleTeaserPromoted';
import Section from '../../../AutoLevels/Section';

import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useDarkModeChecker from '../../../../hooks/useDarkModeChecker';
import useTopNewsData from '../../../../hooks/Page/useTopNewsData';
import { useIsBot, } from '../../../../hooks/useIsBot';


import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  list: ListDataType,
  listId: string,
  isLazyloadImages: boolean,
  inView: boolean,
  articleType?: 'magazine' | 'liveBlog' | 'default',
  gaAction: ?() => void,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLabel: boolean,
  isPhotoBlog: boolean,
};

const isMagazine = type => type === 'magazine';
const isLiveBlog = type => type === 'liveBlog';

const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "t1"
    "t2"
    "t3"
    "t4"
    "t5"
    "t6"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he he he"
    "t1 t2 t3"
    ".  .   ."
    "t4 t5 t6"
    `,
  },
  {
    from: 'l',
    value: `
    "he         he        he"
    "topNews topNews topNews"
    "t1         t2        t3"
    ".          .          ."
    "t4         t5        t6"
    `,
  },
];

MichelleView.defaultProps = { articleType: 'default', isLabel: false, isPhotoBlog: false, };
export default function MichelleView({
  list,
  listId,
  isLazyloadImages,
  articleType,
  gaAction,
  biAction,
  biImpression,
  inView,
  isLabel,
  isPhotoBlog,
}: Props): React.Node {
  const { theme, css, } = useFela();
  const { items, banners, extraLinks, ...restOfList } = list;
  const { clickTrackerBanners, } = banners || {};
  const isDarkMode = useDarkModeChecker();
  const { isBot, } = useIsBot();

  const headerNewsData = useTopNewsData({ ssr: isBot, });


  const backgroundColor = isLiveBlog(articleType) || isDarkMode ? 'transparent' : 'white';
  const inMagazine = isMagazine(articleType);

  const [ hasImpressionSent, setHasImpressionSent, ] = React.useState(null);

  React.useEffect(() => {
    inView && !hasImpressionSent && items.map((item, i) => {
      i < 6 && biImpression && setBiImpression(i, item, biImpression)();
      setHasImpressionSent(true);
      return null;
    }
    );
  }, [ biImpression, hasImpressionSent, inView, items, list, ]);
  if (!items || !items.length) return null;

  const clickTracker = clickTrackerBanners && clickTrackerBanners[0];
  const clickTacketItem = clickTracker?.clickTrackers?.[0];

  const itemsToDisplay = items.filter(Boolean).slice(0, clickTracker ? 5 : 6);

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[ { until: 's', value: '1fr', }, { from: 's', value: '1fr 1fr 1fr', }, ]}
      padding={[ { until: 'l', value: '0 2rem', }, { from: 'l', value: '0', }, ]}
      marginTop={[ { until: 's', value: 6, }, ]}
      rowGap={[ { until: 's', value: '0', }, { from: 's', value: '2rem', }, ]}
      colGap={[
        { until: inMagazine ? 'l' : 'm', value: '2rem', },
        { from: inMagazine ? 'l' : 'm', value: '4rem', },
      ]}
      gridGap={null}
      innerBackgroundColor={backgroundColor}
      miscStyles={{
        color: isPhotoBlog ? theme.color('bodyText', 'base') : undefined,
        // TODO: remove showOnMobile from theme after gb check. falsy value it's not needed anymore
        ...(theme.michelleStyle.showOnMobile ? {} : { display: [ { until: 's', value: 'none', }, { from: 's', value: 'grid', }, ], }),
      }}
      disableWrapper
      attrs={{
        'data-test': 'michelle',
      }}
    >
      {/* list header */}
      <ListViewHeader
        isHorizontal
        isCommercial={isLabel}
        miscStyles={{
          marginBottom: '-1rem',
          paddingBottom: '0',
        }}
        {...restOfList}
      />
      <Section isFragment>
        {isLabel || !headerNewsData
          ? null
          : (
            <TopNewsItems data={{ contentLists: headerNewsData?.topNews?.items || [], contentName: headerNewsData?.topNews?.name || '', }} css={css} theme={theme} />
          ) }
        {/* list items */}
        {itemsToDisplay.map(
          (item, i) => (
            <MichelleTeaser
              key={item.contentId}
              itemData={item}
              backgroundColor={backgroundColor}
              inMagazine={inMagazine}
              isLazyloadImages={isLazyloadImages}
              gridArea={`t${i + 1}`}
              biAction={setBiAction(i, item, biAction)}
              theme={theme}
            />
          )
        )}
        {clickTracker ? (
          <MichelleTeaserPromoted
            key={clickTracker.id}
            itemData={clickTacketItem}
            isLazyloadImages={isLazyloadImages}
            gridArea="t6"
            biAction={setBiAction(5, clickTacketItem, biAction)}
            theme={theme}
          />
        ) : null}
      </Section>
    </ListView>
  );
}
