// @flow
import React, { Fragment, useState, useEffect, memo, } from 'react';
import { FelaComponent, } from 'react-fela';

import type { ComponentType, } from 'react';
import type { AdSlotType, } from '../../flowTypes/AdSlotType';
import type { GridElementType, } from '../../flowTypes/GridElementType';
import type { ListDataType, } from '../../flowTypes/ListDataType';
import type { ContentType, } from '../../flowTypes/ContentType';

import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import ToggleFade from '../Transitions/ToggleFade';
import useIsBlock from '../../hooks/useIsBlock';
import useIsBot from '../../hooks/useIsBot';

export type ItemType = {
  displayDuration: number,
  content: AdSlotType | GridElementType | ListDataType | ContentType,
};

export type Props = {
  scrollY: number,
  velocity: number,
  items: Array<ItemType>,
  totalDisplay: number,
  isBlockedArticle: boolean,
};

export type State = {
  elementIndex: ?number,
  maxIndex: number,
  someoneIsAnimating: boolean,
};

export type ElementGroupProps = Props & {
  getComponent: string => ComponentType<any>,
};

const getElementIndex: (number, number, Array<ItemType>, boolean) => ?number = (
  scrollY,
  totalDisplay = 2000,
  items,
  isBlockedArticle
) => {
  const posY: number = scrollY % totalDisplay;
  let prev: number = 0;
  for (const [ index, item, ]: [number, ItemType, ] of items.entries()) {
    const displayDuration = isBlockedArticle ? 800 : item.displayDuration;
    if (posY > displayDuration + prev) {
      prev += displayDuration;
    }
    else return index;
  }
  return null;
};

const ChangeableElementGroup = memo<ElementGroupProps>(
  ({
    scrollY,
    velocity,
    items,
    totalDisplay,
    getComponent,
    isBlockedArticle,
  }: ElementGroupProps) => {
    const { isBot, } = useIsBot();

    const [ someoneIsAnimating, setSomeoneIsAnimating, ] = useState(false);
    const [ elementIndex, setElementIndex, ] = useState(null);
    const [ maxIndex, setMaxIndex, ] = useState(0);

    useEffect(() => {
      const nextElementIndex: ?number = getElementIndex(
        scrollY,
        totalDisplay,
        items,
        isBlockedArticle
      );
      if (nextElementIndex !== elementIndex) {
        setElementIndex(nextElementIndex);
        if (nextElementIndex && nextElementIndex > maxIndex) {
          setMaxIndex(nextElementIndex);
        }
      }
    }, [
      scrollY,
      totalDisplay,
      items,
      elementIndex,
      setElementIndex,
      maxIndex,
      setMaxIndex,
      isBlockedArticle,
    ]);

    return (
      <Fragment>
        {items.map(({ content: element, }, index) => {
          const Element: ComponentType<any> = getComponent(element.inputTemplate);
          const show: boolean = elementIndex === index
            || (elementIndex === null && !!element.isExpanded && index === 0);

          // delayRender will cause list components to render once,
          // only when scrolling to the element above them for the first time.
          // This is needed so the listDuplication prevention will work.
          // The reason it is just for lists is because the dfp wont work if its not loaded at once.
          // We should remove this once we fix the client side dfp problems,
          // and rewrite the List component in a way that might allow a better solution.
          const delayRender: boolean = element.inputTemplate === 'htz_list_List' && maxIndex < index - 1;

          // $FlowFixMe
          const { properties, ...elementWithoutProperties } = element;

          return (
            <FelaComponent
              key={element.contentId}
              style={({ theme, }) => ({
                transform: `translateY(${show ? '0' : '8'}rem)`,
                transitionProperty: 'transform',
                ...theme.getDuration('transition', 1),
                ...theme.getTimingFunction('transition', 'linear'),
                ...theme.getDelay('transition', 0),
              })}
            >
              <ToggleFade
                show={isBot || show}
                delay={0}
                durationIn={3}
                durationOut={0}
                render={({ animating, }) => {
                  setSomeoneIsAnimating(animating);
                  return (
                    <FelaComponent
                      style={{
                        display: !show && !someoneIsAnimating && !isBot ? 'none' : 'block',
                      }}
                    >
                      {delayRender ? null : (
                        <Element {...(properties || {})} {...(elementWithoutProperties || {})} />
                      )}
                    </FelaComponent>
                  );
                }}
              />
            </FelaComponent>
          );
        })}
      </Fragment>
    );
  },
  (prevProps, nextProps) => {
    const { items, isBlockedArticle, totalDisplay, } = nextProps;

    const prevElementIndex: ?number = getElementIndex(
      prevProps.scrollY,
      totalDisplay,
      items,
      prevProps.isBlockedArticle
    );
    const nextElementIndex: ?number = getElementIndex(
      nextProps.scrollY,
      totalDisplay,
      items,
      isBlockedArticle
    );

    return nextElementIndex === prevElementIndex;
  }
);

export default function ChangeableElementGroupMemoiezed(props: Props) {
  const getComponent = useGetComponent();
  const isBlocked = useIsBlock();
  return (
    <ChangeableElementGroup
      {...props}
      getComponent={getComponent}
      isBlockedArticle={isBlocked}
    />
  );
}
