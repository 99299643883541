/* globals OBR */
import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, borderEnd, } from '@haaretz/htz-css-tools';
import Article from './article';
import { stylesPropType, } from '../../../propTypes/stylesPropType';
import HtzLink from '../../HtzLink/HtzLink';
import EventTracker from '../../../utils/EventTracker';
import TeaserLabelDisclaimer from '../../TeaserLabelDisclaimer/TeaserLabelDisclaimer';

const propTypes = {
  /**
   * An array of articles to display.
   */
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * A special property holding miscellaneous CSS values that
   * trumps all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  /**
   * Is the the list of outbrain content.
   */
  outbrain: PropTypes.bool,
  /**
   * Is the the list of promoted content.
   */
  promoted: PropTypes.bool,
};

const defaultProps = {
  miscStyles: null,
  outbrain: false,
  promoted: false,
};

// eslint-disable-next-line react/prop-types
function Promoted({ outbrain, children, }) {
  const { css, theme, } = useFela();
  return (
    <p
      className={css({
        color: theme.color('osaka', 'footerText'),
        marginBottom: '0.25rem',
        marginStart: theme.osakaStyle.articleFooterMarginStart,
        marginTop: '1rem',
        position: 'relative',
        width: theme.osakaStyle.outbrainFooterWidth,
        ...(outbrain
          ? {
            paddingStart: theme.osakaStyle.outbrainFooterPadding,
            ':after': {
              background:
                  'url(//widgets.outbrain.com/images/widgetIcons/ob_logo_16x16.png) no-repeat center top',
              backgroundSize: '100% 200%',
              content: '""',
              height: '1.75rem',
              position: 'absolute',
              right: theme.osakaStyle.outbrainLogoRight,
              bottom: '50%',
              transform: 'translateY(50%)',
              width: '1.75rem',
            },
            ':hover': {
              color: theme.color('neutral'),
              textDecoration: 'underline',
              lineSkip: 'ink',
              ':after': {
                backgroundPosition: 'center bottom',
              },
            },
          }
          : {}),
        extend: [ theme.type(-4), ],
      })}
    >
      {children}
    </p>
  );
}

function List({ articles, promoted, outbrain, miscStyles, }) {
  const { css, theme, } = useFela();
  return (
    <div
      className={css({
        paddingTop: promoted || outbrain ? '3rem' : '0',
        paddingBottom: '1rem',
        display: 'flex',
        [promoted || outbrain ? 'justifyContent' : 'alignItems']: 'center',
        flexDirection: promoted || outbrain ? 'column' : 'row',
        extend: [
          theme.mq(
            { from: 'l', until: 'xl', },
            {
              paddingInlineStart: outbrain
                ? 0
                : promoted
                  ? theme.osakaStyle.itemPaddingLtoXlInner
                  : theme.osakaStyle.itemPaddingLtoXl,
              paddingInlineEnd: !outbrain
                ? theme.osakaStyle.itemPaddingLtoXlInner
                : theme.osakaStyle.itemPaddingLtoXl,
            }
          ),
          theme.mq(
            { until: 'l', },
            {
              paddingInlineStart: theme.osakaStyle.itemPaddingUntilL,
              paddingInlineEnd: theme.osakaStyle.itemPaddingUntilL,
            }
          ),
          ...(miscStyles ? parseStyleProps(miscStyles) : []),
        ],
      })}
    >
      {articles.map((article, i) => (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
          })}
          key={article.contentId || article.url}
        >
          <EventTracker key={article.url}>
            {({ biAction, gaAction, }) => (
              <HtzLink
                className={css({
                  color: theme.color('osaka', 'text'),
                  width: '100%',
                  extend: [
                    theme.mq(
                      { from: 'xl', },
                      i === 0 && theme.osakaStyle.itemPadding !== 0 && !promoted && !outbrain
                        ? { paddingInlineEnd: theme.osakaStyle.itemPadding, }
                        : {}
                    ),
                    ...(promoted
                      ? [ borderEnd('1px', 'solid', theme.color('osaka', 'borderEnd')), ]
                      : []),
                  ],
                })}
                onClick={() => {
                  biAction({
                    actionCode: 109,
                    ArticleId: promoted ? 'promoted' : outbrain ? 'outbrain' : 'htz-label',
                    NoInList: i + 1,
                    ViewName: 'Osaka',
                  });
                }}
                href={article.url}
              >
                <Article {...article} />
              </HtzLink>
            )}
          </EventTracker>
          <div className={css({
            marginStart: '1rem',
          })}
          >
            {promoted ? (
              <TeaserLabelDisclaimer
                companyName={article.note || ''}
                miscStyles={
                  {
                    ...theme.type(-3.5),
                    fontWeight: '700',
                  }}
              />
            ) : null}
            {outbrain ? (
              <a
                href="#"
                onMouseDown={event => {
                  // eslint-disable-next-line no-param-reassign
                  event && event.cancelBubble && (event.cancelBubble = true);
                  event && event.stopPropagation && event.stopPropagation();
                  return true;
                }}
                onClick={() => {
                  OBR.extern.callWhatIs(
                    'https://www.outbrain.com/what-is/default/he',
                    '',
                    -1,
                    -1,
                    true,
                    null
                  );
                  return false;
                }}
              >
                <Promoted outbrain>Recommended by</Promoted>
              </a>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
