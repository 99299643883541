// @flow
import React from 'react';
import { ARTICLE_QUERY, } from '@haaretz/graphql';

import { useQuery, } from 'react-apollo';
import { breakUrl, } from '@haaretz/app-utils';
import useArticleId from '../../hooks/Page/useArticleId';
import usePreview from '../../hooks/Page/usePreview';
import useLiveBlogQueryVariables from '../../hooks/Page/useLiveBlogQueryVariables';
import Error from '../Error/Error';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import redirect from '../../utils/redirect';
import { buildNextUrl, } from '../HtzLink/HtzLink';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';

const { isTM, isHTZ, } = checkSiteFromConfig();


type ArticleDataGetterProps = {
  children: Function,
  path: string,
  asPath: string,
  responseEnd: Function,
  responseWriteHead: Function,
}

export default function ArticleDataGetter({
  children,
  path,
  asPath,
  responseEnd,
  responseWriteHead,
}: ArticleDataGetterProps) {
  const articleId = useArticleId();
  const { isPreview, } = usePreview();
  const isWebView = useWebViewChecker();
  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(ARTICLE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,

  });

  if (error) {
    console.error(typeof error === 'object' ? JSON.stringify(error) : error);
  }

  if (loading && !data?.Page) return null;
  if (error) {
    if (
      error.graphQLErrors[0]
      && error.graphQLErrors[0].extensions
      && error.graphQLErrors[0].extensions.response
      && error.graphQLErrors[0].extensions.response.status
      && typeof error.graphQLErrors[0].extensions.response.status === 'number'
    ) {
      return (
        <Error
          errorCode={error.graphQLErrors[0].extensions.response.status}
          kind="error"
          message="Brighspot is down, Call Avi Kaufman."
          path={asPath || path}
        />
      );
    }
    return <Error path={asPath || path} errorCode={503} kind="error" message="Brighspot is down, Call Avi Kaufman." />;
  }

  // Exclude tmr and hrtz sections from server redirects
  const isAllowedtoRedirect = /^(?!\/?(tmr|hrtz)\/).*$/.test(path);

  const { redirect: redirectUri, seoData, } = data?.Page || {};

  // Check if server redirect (301) is needed
  if (
    !isPreview
  && isAllowedtoRedirect
  && seoData?.canonicalUrl
  && typeof seoData?.canonicalUrl === 'string'
  && typeof responseEnd === 'function'
  && typeof responseWriteHead === 'function'
  ) {
    const { path: canonicalPath, domain, } = breakUrl(seoData.contentUrl.trim());
    const isValidDomain = [ 'haaretz.co.il', 'themarker.com', 'haaretz.com', ].includes(domain);

    if ((
      encodeURI(canonicalPath) !== path || canonicalPath !== decodeURI(path)
    ) && isValidDomain) {
      const fullQs = ((asPath || '').match(/\?.*/) || [ '', ])[0];

      if (isWebView) {
        const articleUrl = canonicalPath.startsWith('/') ? canonicalPath.substr(1) : articleId;

        if (isHTZ && seoData.canonicalUrl.includes('themarker.com')) {
          responseWriteHead(301, { Location: encodeURI(`/tmr/${articleUrl}${fullQs}`), });
          responseEnd();

          return null;
        }

        if (isTM && seoData.canonicalUrl.includes('haaretz.co.il')) {
          responseWriteHead(301, { Location: encodeURI(`/hrtz/${articleUrl}${fullQs}`), });
          responseEnd();

          return null;
        }
      }

      responseWriteHead(301, { Location: encodeURI(canonicalPath + fullQs), });
      responseEnd();

      return null;
    }
  }

  if (redirectUri) {
    if (responseEnd && responseWriteHead) {
      const { computedHref, computedPathname, } = buildNextUrl(redirectUri);
      const qs = ((asPath || '').match(/\?(.*)/) || [])[1];
      const redirectUriWithQS = qs
        ? `${redirectUri}${redirectUri.includes('?') ? '&' : '?'}${qs}`
        : redirectUri;

      redirect(
        responseEnd,
        responseWriteHead,
        redirectUriWithQS,
        301,
        computedHref,
        computedPathname
      );
    }
    return null;
  }

  if (!data?.Page) {
    return <Error path={path || asPath} errorCode={404} kind="error" message="Something went wrong. Call Eran Meshulam." />;
  }

  if (typeof children !== 'function') return null;

  return children({ articleData: data, });
}
