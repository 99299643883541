
// @flow
/* global OBR window navigator document */
import React, { useCallback, useEffect, useState, } from 'react';
import config from 'config';
import { FelaComponent, } from 'react-fela';
import { useApolloClient, } from 'react-apollo';
import { appendScript, } from '../../utils/scriptTools';
import useMedia from '../../hooks/useMedia';
import Media from '../Media/Media';

import useWebViewChecker from '../../hooks/useWebViewChecker';
import useDarkModeChecker from '../../hooks/useDarkModeChecker';
import useUseragent from '../../hooks/useUseragent';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';
import useArticleId from '../../hooks/Page/useArticleId';
import useOneTime from '../../hooks/useOneTime';
import usePreview from '../../hooks/Page/usePreview';
import useIsReadyToRenderExternals from '../../hooks/useIsReadyToRenderExternals';
import { useUser, } from '../User/UserDispenser';

type OutbrainProps = {
  contentName: string,
  widgetId: string,
  appWidgetIds: { [key: string]: string, },
}

function Outbrain({ contentName, widgetId, appWidgetIds, }: OutbrainProps) {
  const client = useApolloClient();
  const isWebView = useWebViewChecker();
  const articleId = useArticleId();
  const { user, } = useUser();

  const isPayingUser = user?.type === 'paying';
  const site = config.get('domain');
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const [ isAppend, setIsAppend, ] = useState(false);
  const [ deviceId, setDeviceId, ] = useState(typeof window === 'undefined' ? undefined : window.deviceId);
  const [ appVersion, setAppVersion, ] = useState();
  const isDarkMode = useDarkModeChecker();

  const { isAndroid, } = useUseragent(typeof navigator === 'undefined' ? undefined : navigator.userAgent);

  let dataWidgetId = widgetId;
  const widgetName = `${isDarkMode ? 'dark' : 'regular'}${isAndroid() ? 'Android' : 'IPhone'}`;
  const widgetIdVewbView = typeof appWidgetIds === 'object' && appWidgetIds[widgetName];

  const shouldInitiate = isWebView ? widgetIdVewbView && isLoaded : !(contentName || '').includes('application');

  const onLoadElement = useCallback(e => {
    const args = e?.detail || [];

    if (args[0]) {
      setDeviceId(args[0]);
    }

    if (args[1]) {
      setAppVersion(args[1]);
    }
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  useEffect(() => {
    if (shouldInitiate) {
      if (isPayingUser && window.OB_ContextKeyValue !== 'subscribed') window.OB_ContextKeyValue = 'subscribed';
      else if (window.OB_ContextKeyValue) window.OB_ContextKeyValue = undefined;
    }

    return () => {
      window.OB_ContextKeyValue = undefined;
    };
  }, [ isPayingUser, shouldInitiate, widgetId, ]);

  useOneTime(shouldInitiate && !isAppend, () => {
    const id = 'outbrain';
    appendScript({
      src: 'https://widgets.outbrain.com/outbrain.js',
      id,
      isAsync: false,
      updateFunction: () => {
        if (typeof OBR !== 'undefined') {
          OBR.extern.researchWidget();
        }
      },
    });

    setIsAppend(true);
    return () => {
      const scriptEl = document.querySelector(`script#${id}`);
      if (scriptEl && typeof scriptEl.remove === 'function') { scriptEl.remove(); }
    };
  });


  useOneTime(isAppend, () => {
    client.writeData({ data: { osakaCanRender: true, }, });
  });


  if (!shouldInitiate) return null;


  return (
    <Media query={{ from: 'l', }} matchOnServer>
      {matches => {
        if (!isWebView && (dataWidgetId || '').toLowerCase() === 'mb_6' && matches) {
          return null;
        }
        if (isWebView) {
          dataWidgetId = widgetIdVewbView || dataWidgetId;
        }
        return (
          <FelaComponent style={({ theme, }) => ({ overflow: 'hidden', clear: 'both', })}>
            {({ className, }) => (
              <div className={`OB-REACT-WRAPPER ${className}`}>
                <div
                  className="OUTBRAIN"
                  data-src={`https://www.${site}/${articleId}`}
                  data-ob-template="haaretz-heb"
                  data-widget-id={dataWidgetId}
                  data-ob-user-id={deviceId}
                  data-ob-app-ver={appVersion}
                  data-test="outbrain"
                />
              </div>
            )}
          </FelaComponent>
        );
      }}
    </Media>
  );
}


const OutbrainWrapper = (props: OutbrainProps) => {
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: true, });
  const { isPreview, } = usePreview();
  const isReadyToRenderExternals = useIsReadyToRenderExternals();
  const [ shouldRender, setShouldRender, ] = useState(false);
  const { user, } = useUser();

  const isUniversity = user.university;

  // here we check if the user is from university and if so we don't render the widget AR_14
  useOneTime(!(isUniversity && (props.widgetId === 'AR_14' || props.widgetId === 'AR_12')), () => {
    setShouldRender(true);
  });

  if (!shouldRender) return null;
  if ((isMobile && props.widgetId === 'SB_4') || isPreview || !isReadyToRenderExternals) return null;

  return (
    <Outbrain isMobile={isMobile} {...props} />
  );
};


export default OutbrainWrapper;
