/* global localStorage */
import PropTypes from 'prop-types';

const ReadingHistoryCountProvider = ({ children, }) => {
  let readingHistoryCount;
  if (typeof window !== 'undefined') {
    try {
      readingHistoryCount = JSON.parse(localStorage.getItem('readingHistoryCount'));
    }
    catch (err) {
      console.warn('unable to parse readingHistoryCount from localStorage\n', err);
      readingHistoryCount = null;
    }
  }
  return children(readingHistoryCount || []);
};

ReadingHistoryCountProvider.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ReadingHistoryCountProvider;
