import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';

FadeinViewport.propTypes = {
  threshold: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  mediaQuery: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FadeinViewport.defaultProps = {
  threshold: 0,
  mediaQuery: null,
};
export default function FadeinViewport({ threshold, mediaQuery, children, }) {
  const [ ref, inView, ] = useInView({ threshold, triggerOnce: true, });
  const rule = React.useMemo(() => createRule(mediaQuery), [ mediaQuery, ]);
  const className = useFela({ inView, }).css(rule);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
}

function createRule(mediaQuery) {
  return ({ theme, inView, }) => theme.mq(mediaQuery, {
    opacity: 0,
    ...theme.getDuration('animation', 8),
    animationFillMode: 'forwards',
    animationName: inView
      ? [
        {
          '0%': { opacity: 0, },
          '100%': { opacity: 1, },
        },
      ]
      : undefined,
  });
}
