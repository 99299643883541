// @flow
import * as React from 'react';
import dynamic from 'next/dynamic';

const SoccerLeaguesTable = dynamic(
  () => import('./SoccerTableScore/SoccerLeagues/SoccerLeaguesTable'),
  { ssr: true, }
);
const SoccerGroupsTable = dynamic(
  () => import('./SoccerTableScore/SoccerGroups/SoccerGroupsTable'),
  { ssr: true, }
);
const NBATable = dynamic(() => import('./NBATableScore/NBATable'), {
  ssr: true,
});

const BasketballEuroLeague = dynamic(() => import('./BasketballEuroLeague/BasketballEuroLeague'), {
  ssr: true,
});

type League = "2125" | "2021" | "2019" | "2015" | "2014" | "2002";
export type CoastType = "east" | "west";

type SoccerLeagueProps = {
  tableType: "soccer-leagues",
  league: League,
  isOpen: boolean,
  isPlayoff?: boolean,
};
type ChampionsProps = {
  tableType: "soccer-champions" | "soccer-european-championship",
  number: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8",
};
type NbaProps = {
  tableType: "nba",
  isOpen: boolean,
  coastType: CoastType,
};

type Props = NbaProps | ChampionsProps | SoccerLeagueProps;

function TableScore(props: Props): React.Node {
  if (isNba(props)) { return <NBATable coastType={props.coastType} isOpen={props.isOpen} />; }
  if (isEuroLeague(props)) { return <BasketballEuroLeague tableType={props.tableType} isEuroLeague />; }
  if (isLeague(props)) {
    return (
      <SoccerLeaguesTable
        league={props.league}
        isOpen={props.isOpen}
        isPlayoff={props.isPlayoff}
      />
    );
  }
  if (isChampions(props)) {
    const attrs: ChampionsProps = (props: any);
    return (
      <SoccerGroupsTable tableType={attrs.tableType} number={attrs.number} />
    );
  }
  return null;
}

function isNba(props: Props): boolean %checks {
  return props.tableType === 'nba';
}

function isEuroLeague(props: Props): boolean %checks {
  return props.tableType === 'basketball-euro-league';
}

function isChampions(props: Props): boolean %checks {
  return (
    props.tableType === 'soccer-champions'
    || props.tableType === 'soccer-world-cup'
    || /soccer-.+-championship/.test(props.tableType)
  );
}

function isLeague(props: Props): boolean %checks {
  return props.tableType === 'soccer-leagues';
}

export default TableScore;
