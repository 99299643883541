// @flow
import React from 'react';
import type { Node, } from 'react';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';

import NoData from '../NoData/NoData';

type Props = {
  minHeight: ?string,
};

Loading.defaultProps = {
  minHeight: null,
};

export function Loading({ minHeight, }: Props): Node {
  const geElements = useGetComponent();
  const LoaderIcon = geElements('loaderIcon');
  return (
    <NoData
      bgc={[ 'neutral', '-1', ]}
      color={[ 'neutral', '-10', ]}
      miscStyles={{
        minHeight: minHeight || '310px',
      }}
    >
      <LoaderIcon size={9} color={[ 'primary', '-1', ]} />
    </NoData>
  );
}

export default Loading;
