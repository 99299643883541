import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { FelaComponent, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import IconDislike from '../Icon/icons/IconDislike';
import IconLike from '../Icon/icons/IconLike';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';

const colorShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

const propTypes = {
  /**
   * used by initVote func to idenfy comment that is voted for
   */
  commentId: PropTypes.string.isRequired,
  /**
   * Insert a color from the htz-theme color pallete
   */
  iconColor: PropTypes.oneOfType([
    colorShape,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: colorShape,
      })
    ),
  ]),
  /**
   * A callaback that initiates a vote, `<Likes />` sends up the commentId and the rate ("plus"/"minus")
   */
  initVote: PropTypes.func.isRequired,
  /**
   * Is this a disLike (minus vote)
   */
  isDisLike: PropTypes.bool,
  /**
   * are the like buttons disabled
   */
  isDisabled: PropTypes.bool.isRequired,
  /** update parent component that the user clicked the like button */
  updateUserLike: PropTypes.func.isRequired,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  // eslint-disable-next-line react/no-unused-prop-types
  miscStyles: stylesPropType,
  /**
   * number of votes
   */
  rate: PropTypes.number,
};

const defaultProps = {
  iconColor: 'primary',
  isDisLike: false,
  miscStyles: null,
  rate: 0,
};

const counterStyle = ({ theme, }) => ({
  color: theme.color('comments', 'likeCounter'),
  verticalAlign: 'center',
  marginInlineStart: '1rem',
  fontWeight: 'bold',
  extend: [ theme.type(-2), ],
});

const styles = ({ theme, miscStyles, }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  ':disabled': {
    cursor: 'default',
  },
  extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
});

export function Like({
  commentId,
  iconColor,
  initVote,
  isDisLike,
  isDisabled,
  miscStyles,
  updateUserLike,
  rate,
}) {
  function executeVote() {
    const likeSign = isDisLike ? { dislike: true, } : { like: true, };
    updateUserLike();
    initVote({ commentId, ...likeSign, });
  }
  // todo: get actual disabled color from designer
  const computedIconColor = isDisabled ? [ 'neutral', '-3', ] : iconColor;
  return (
    <FelaComponent miscStyles={miscStyles} style={styles}>
      {({ className, theme: { commentsSectionI18n, }, }) => (
        <button
          className={className}
          type="button"
          onClick={() => executeVote()}
          disabled={isDisabled}
          data-test={isDisLike ? 'dislikeButton' : 'likeButton'}
        >
          {isDisLike ? (
            <Fragment>
              <IconDislike size={4} color={computedIconColor} />
              <VisuallyHidden>{commentsSectionI18n.likes.dislike}</VisuallyHidden>
            </Fragment>
          ) : (
            <Fragment>
              <IconLike size={4} color={computedIconColor} />
              <VisuallyHidden>{commentsSectionI18n.likes.like}</VisuallyHidden>
            </Fragment>
          )}

          <FelaComponent
            style={counterStyle}
            render={({ className, }) => (<span className={className} data-test={isDisLike ? 'dislikeCount' : 'likeCount'}>{rate}</span>)}
          />
        </button>
      )}
    </FelaComponent>
  );
}

Like.propTypes = propTypes;

Like.defaultProps = defaultProps;

export default Like;
