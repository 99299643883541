/* global window */
// @flow
import React from 'react';
import Head from 'next/head';
import config from 'config';
import { format as formatFn, isValid, } from 'date-fns';
import { format as formatTzFn, utcToZonedTime, } from 'date-fns-tz';
import { useFela, } from 'react-fela';

import TwitterMeta from '../TwitterMeta/TwitterMeta';
import { buildUrl, } from '../../utils/buildImgURLs';

import useWebViewChecker from '../../hooks/useWebViewChecker';
import useSeoData from '../../hooks/Page/useSeoData';
import { getFormatting, } from '../Time/Time';
import usePaywallType from '../../hooks/Page/usePaywallType';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';

// get site alias name from config
const { siteAlias, } = checkSiteFromConfig();

const TimeZone = 'Asia/Jerusalem';
const ObTimeFormat = 'dd.MM.yyyy HH:mm';
const PublishDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ssXXX";

export default function ArticleSeo() {
  const seoData = useSeoData();
  const { theme, } = useFela();
  const { isOpen, isFree, } = usePaywallType();
  const isWebView = useWebViewChecker();
  const breadCrumbs = useBreadcrumbsData();
  // const router = useRouter();

  const titleSEO = (seoData && seoData.title)
    || `${breadCrumbs?.[0]?.name} - ${breadCrumbs?.[1] ? breadCrumbs[1]?.name : ''} - ${breadCrumbs?.length > 2 ? breadCrumbs?.[breadCrumbs?.length - 1]?.name : ''
    }`;

  const titleSEOWebView = breadCrumbs?.[0]?.name || seoData?.title || '';

  const title = isWebView ? titleSEOWebView : titleSEO;

  const titlePrefix = isWebView ? '' : theme.seoI18n.titlePrefix;
  const metaTitle = `${title}${titlePrefix}`;

  const isFoodSection = Array.isArray(breadCrumbs) && breadCrumbs.slice(-2, -1)[0]?.pathSegment === 'food';
  const {
    description,
    keywords,
    canonicalUrl,
    ogUrl,
    ogTitle,
    ogImage,
    ogDescription,
    obTitle,
    twitterTitle,
    pubDate,
    amphtml,
    robots,
    author,
    articleType,
    firstParagraph,
    isLockedPush,
    alternate,
  } = seoData || {};


  // TODO: remove this when the GA4 implementation is complete meanwjile it's commented out
  // because it is causing a bug in mobile app where gtag is not defined
  // and double render the gtag per site.

  // The useEffect hook is used to execute a function that sends the custom title to
  // GA4 using the gtag('config', ...) command after
  // the component is rendered and the DOM is available.
  // This ensures that GA4 receives and records the custom title for the page.
  // React.useEffect(() => {
  //   const gtagIdBySite = gtagId[siteAlias];
  //   window.gtag('config', gtagIdBySite, {
  //     page_title: title,
  //   });
  // }, [ title, ]);

  const { contentId, files, } = ogImage || {};

  const ogImageUrl = Array.isArray(files) && files[0]
    ? withWatermark(contentId, files[0], pubDate, !isFoodSection)
    : '';

  const ogImageSquareUrl = Array.isArray(files) && files[0]
    ? buildUrl(
      contentId,
      files[0],
      {
        width: '200',
        height: '200',
        quality: 'auto',
        aspect: files[0].aspects && ('square' in files[0].aspects)
          ? 'square' : 'full',
      }
    )
    : '';

  const parsedDate = new Date(pubDate || pubDate === 0 ? pubDate : '');
  // default time zone has been set to IST in all sites (if no different specific timeZone prop has been set)
  const fixedTime = TimeZone && pubDate ? utcToZonedTime(parsedDate, TimeZone) : parsedDate;

  let obFormattedTime = pubDate;
  let publishDateFormattedTime = pubDate;
  if (isValid(parsedDate)) {
    const format = getFormatting(parsedDate, ObTimeFormat);
    const publishDateFormat = getFormatting(parsedDate, PublishDateTimeFormat);

    obFormattedTime = TimeZone
      ? formatTzFn(fixedTime, format, { timeZone: TimeZone, })
      : formatFn(fixedTime, format);

    publishDateFormattedTime = TimeZone
      ? formatTzFn(fixedTime, publishDateFormat, { timeZone: TimeZone, })
      : formatFn(fixedTime, publishDateFormat);
  }
  const ogImageType = getOgImageType(ogImageUrl);

  // TODO: REMOVE THIS WHEN EVERTHING IS OK OR UNCOMMENT!
  /*
     Mark pages that are "external" to the site they are displayed in, e.g.,
    `tmr` in Haaretz or `hrtz` in TheMarker, with `noindex`,
     so that they dont "compete" over SEO with the original content and there is no duplication
 */
  // const robotsOrNoIndex = (!isHDC && /\/(hrtz|tmr)\//.test(router.query.path)) ? 'noindex' : (null || robots);

  return (
    <Head>
      <meta name="title" content={metaTitle} key="title" />
      <meta name="description" content={description} key="description" />
      <meta name="keywords" content={keywords} key="keywords" />
      <meta property="og:title" content={ogTitle} key="og:title" />
      <meta property="og:description" content={ogDescription} key="og:description" />
      <meta property="og:type" content="article" key="of:type" />
      <meta property="og:url" content={ogUrl || canonicalUrl} key="og:url" />
      <meta property="og:image" content={ogImageUrl} key="og:image" />
      <meta property="og:image:width" content="1200" key="og:image:width" />
      <meta property="og:image:height" content="630" key="og:image:height" />
      {ogImageType ? <meta property="og:image:type" content={ogImageType} key="og:image:type" /> : null}
      <meta property="ob:title" content={obTitle} key="ob:title" />
      <meta
        property="article:published"
        content={publishDateFormattedTime}
        key="article:published"
      />

      <TwitterMeta
        ogImageUrl={ogImageUrl}
        metaTitle={ogTitle || ''}
        metaDescription={description}
        twitterTitle={twitterTitle}
        ogDescription={ogDescription}
        site={config.get('domain')}
      />
      <link rel="canonical" href={canonicalUrl} />
      {amphtml ? <link rel="amphtml" href={amphtml} /> : null}
      {/* TODO: REMOVE THIS WHEN EVERTHING IS OK OR UNCOMMENT! */}
      {/* Conditionally render the robots meta tag with either noindex or the value from the CMS */}
      {/* {robotsOrNoIndex ? <meta name="robots" content={robotsOrNoIndex} /> : null} */}
      {robots ? <meta name="robots" content={robots} /> : null}
      <meta
        property="ob:pubDate"
        content={obFormattedTime}
      />
      <meta property="publishDate" content={publishDateFormattedTime || ''} />
      <meta name="publishDate" content={publishDateFormattedTime || ''} />
      <meta name="canonicalPath" content={canonicalUrl || ''} />
      <meta name="news_keywords" content={keywords} />
      <meta key="author" name="author" content={author?.contentName || ''} />
      <meta key="authorId" name="authorId" content={author?.contentId || ''} />
      <meta name="articleType" content={articleType || ''} />
      <meta name="cost" content={isOpen || isFree ? 1 : 2} />
      <meta name="canonical" content={canonicalUrl || ''} />
      <meta name="ImageURL" content={ogImageUrl || ''} />
      <meta name="SquareImageUrl" content={ogImageSquareUrl || ''} />
      <meta name="firstParagraph" content={firstParagraph || ''} />
      <meta name="isLockedPush" content={(typeof isLockedPush === 'boolean' ? isLockedPush : false).toString()} />
      {alternate ? <link rel="alternate" type="application/rss+xml" href={alternate} /> : null}
    </Head>
  );
}

function getOgImageType(imageUrl) {
  let retVal = null;
  const polopolyFilenameRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/(.+\.[A-Za-z]{3,4})/;
  const matches = polopolyFilenameRegex.exec(imageUrl);
  const imageName = matches?.[2] || null;
  if (imageName) {
    const extensionRegex = /\.[A-Za-z]{3,4}/;
    const extensionMatches = extensionRegex.exec(imageName);

    if (extensionMatches?.[0]) {
      const lowerCaseMatch = extensionMatches?.[0].toLowerCase();
      switch (lowerCaseMatch) {
        case '.jpg':
        case '.jpeg':
          retVal = 'image/jpeg';
          break;
        case '.png':
          retVal = 'image/png';
          break;
        case '.gif':
          retVal = 'image/gif';
          break;
        default:
          break;
      }
    }
  }
  return retVal;
}

function withWatermark(contentId, imgFile, pubDate, condition) {
  // get present year to compare with article's year
  const nowDate = new Date();
  const formattedTime = new Date(pubDate || pubDate === 0 ? pubDate : '');
  // show watermark only for articles older than 12 months
  const enableWatermark = condition && getMonthDifference(nowDate, formattedTime) > 12;
  // extract article's year
  const pubYear = formattedTime.getFullYear();
  // watermark only for articles not published in this year.
  const waterMark = enableWatermark && pubYear > 2009
    ? `watermark=${siteAlias}-${pubYear}`
    : (pubYear > 2010 ? undefined : `watermark=${siteAlias}-archive`);

  // build image url
  const imgUrl = buildUrl(
    contentId,
    imgFile,
    {
      // facebook requires image size to be at least 1200x630
      height: '630',
      width: '1200',
      quality: 'auto',
      // headline aspect is used because its 16/9 ratio, close to facebook's 1.91/1 ratio
      aspect: imgFile.aspects && ('headline' in imgFile.aspects)
        ? 'headline' : 'full',
    }
  );

  return waterMark ? `${imgUrl}&${waterMark}` : imgUrl;
}

function getMonthDifference(startDate, endDate) {
  return (
    startDate.getMonth()
    - endDate.getMonth()
    + 12 * (startDate.getFullYear() - endDate.getFullYear())
  );
}
