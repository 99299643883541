// @flow

import React, { type Node, Fragment, } from 'react';
import usePreview from '../../hooks/Page/usePreview';

type PreviewExcludeProps = {
  children?: any,
};


function PreviewExclude({ children, }: PreviewExcludeProps): Node {
  const { isPreview, } = usePreview();

  if (isPreview) return null;

  return <Fragment>{children}</Fragment>;
}

PreviewExclude.defaultProps = {
  children: null,
};

export default PreviewExclude;
