import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';
import isEmail from 'validator/lib/isEmail';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button'; // eslint-disable-line import/no-named-as-default
import Form from '../Form/Form'; // eslint-disable-line import/no-named-as-default
import IconClose from '../Icon/icons/IconClose';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import { useEventTracker, } from '../../utils/EventTracker';
import DisplayFomoToolTip from '../FomoToolTip/DisplayFomoToolTip';

const { isHDC, } = checkSiteFromConfig();

const contStyle = ({ theme, displayThankYou, isReplyForm, }) => ({
  position: 'relative',
  backgroundColor: theme.color('input', 'primaryBg'),
  paddingInlineStart: '4rem',
  paddingInlineEnd: '4rem',
  paddingTop: '8.2rem',
  fontFamily: theme.fontStacks[theme.commentsStyle.formFontStack],
  extend: [
    borderBottom('1px', 3, 'solid', theme.color('comments', 'divider')),
    {
      condition: displayThankYou === true,
      style: {
        textAlign: 'center',
        paddingTop: '9.4rem',
        ...borderBottom('1px', 16, 'solid', theme.color('comments', 'divider')),
      },
    },
    {
      condition: isReplyForm === true,
      style: {
        marginTop: '2rem',
        marginBottom: 'calc(1px - 2rem)',
      },
    },
  ],
});

CommentSent.propTypes = {
  /** Callback that gets called when the close button is clicked */
  closeDisplayThankYou: PropTypes.func.isRequired,
  /** When true displays ThankYou screen, when false, displays sign up to email notifications screen */
  displayThankYou: PropTypes.bool.isRequired,
  /**
   * Indicates if This is a reply form (affects style)
   */
  isReplyForm: PropTypes.bool.isRequired,
  /**
   * A callback that gets the called when submitting the Form
   * @param {Boolean} didSignUp- True if the user clicked the sign up button, false if clicked no thanks button
   * @param {String} userEmail - The email the user entered
   */
  signUpNotification: PropTypes.func.isRequired,
  /**
   * The user email if there is a logged in user
   */
  userEmail: PropTypes.string,
  hasError: PropTypes.bool,
  errorCode: PropTypes.number.isRequired,
};
CommentSent.defaultProps = {
  userEmail: null,
  hasError: null,
};
function CommentSent({
  closeDisplayThankYou,
  displayThankYou,
  isReplyForm,
  signUpNotification,
  userEmail,
  hasError,
  errorCode,
}) {
  const { css, theme, } = useFela({ displayThankYou, isReplyForm, });
  const focusEl = React.useRef(null);
  const { biAction, biImpression, } = useEventTracker();


  // const manageFocus = el => {
  //   if (!el || el === focusEl) return;
  //   focusEl = el;
  //   focusEl.focus();
  // };
  React.useEffect(() => {
    focusEl.current && focusEl.current.focus();
  }, [ ]);

  React.useEffect(() => {
    biImpression({
      feature: 'New comment alert',
      campaignName: 'new comment',
      featureType: 'Content',
    });
  }, [ biImpression, ]);

  const {
    commentSentI18n: {
      buttons: {
        getNotificationsBtnTxt,
        // dontGetNotificationsBtnTxt,
        closeBtnText,
        goBackBtnText,
      },
      labels: { emailLabelTxt, },
      notes: { emailNoteTxt, },
      errorNotes: { emailErrorNoteTxt, },
      texts: {
        commentRecievedBoldText,
        commentRecievedText,
        commentRecievedTextSecondRow,
        commentRecievedBoldTextThankYouPage,
        commentRecievedTextThankYouPage,
        commentDidNotRecieved,
        tryAgainText,
        nicknameAlreadyInUse,
        tryAnotherName,
      },
    },
  } = theme;
  return (
    <div className={css(contStyle)}>
      <button
        onClick={() => {
          closeDisplayThankYou();
        }}
        type="button"
        className={css({
          top: '1rem',
          end: '1rem',
          position: 'absolute',
          color: theme.color('bodyText', 'base'),
        })}
      >
        <IconClose size={3} />
      </button>
      {displayThankYou || hasError ? (
        <div
          className={css({
            ...(hasError ? { textAlign: 'center', } : {}),
          })}
        >
          {!hasError ? (
            <span
              tabIndex={-1}
              className={css({
                fontWeight: 'bold',
              })}
              ref={focusEl}
            >
              {commentRecievedBoldTextThankYouPage}
            </span>
          ) : null}
          <p
            className={css({
              ...(!hasError ? { marginBottom: '5rem', } : { marginBottom: '1rem', }),
            })}
          >
            {hasError
              ? errorCode === 303 && nicknameAlreadyInUse ? nicknameAlreadyInUse : commentDidNotRecieved
              : commentRecievedTextThankYouPage}
          </p>
          {hasError ? (
            <p
              className={css({
                marginBottom: '5rem',
                fontWeight: 'bold',
              })}
            >
              {errorCode === 303 && tryAnotherName ? tryAnotherName : tryAgainText}
            </p>
          ) : null}

          <Button
            miscStyles={{
              backgroundColor: 'transparent',
              fontWeight: 'bold',
            }}
            boxModel={{ hp: 3.5, vp: 1, }}
            onClick={closeDisplayThankYou}
          >
            {hasError ? goBackBtnText : closeBtnText}
          </Button>
        </div>
      ) : (
        <Form
          attrs={{
            'data-test': 'newCommentSentForm',
          }}
          onSubmit={({ notificationEmail, }) => {
            signUpNotification(true, notificationEmail);
            biAction({
              actionCode: 173,
              feature: 'New comment  alert',
              campaignName: 'new comment',
              featureType: 'Content',
            });
          }}
          {...(userEmail ? { initialValues: { notificationEmail: userEmail, }, } : {})}
          validate={({ notificationEmail = '', }) => {
            const errors = [];
            if (!isEmail(notificationEmail)) {
              errors.push({
                name: 'notificationEmail',
                order: 1,
              });
            }

            return errors;
          }}
          render={({ getInputProps, handleSubmit, }) => (
            <>
              <div>
                <p tabIndex={-1} ref={focusEl}>
                  <span
                    className={css({
                      fontWeight: 'bold',
                    })}
                  >
                    {commentRecievedBoldText}
                  </span>
                  {commentRecievedText}
                </p>
                <p>{commentRecievedTextSecondRow}</p>
                <TextInput
                  {...getInputProps({
                    name: 'notificationEmail',
                    noteText: emailNoteTxt,
                    errorText: emailErrorNoteTxt,
                    label: emailLabelTxt,
                    maxLength: 200,
                    variant: 'primaryOpaque',
                    type: 'email',
                    noteStyle: {
                      color: theme.color('bodyText', 'base'),
                    },
                    miscStyles: {
                      color: theme.color('neutral', '-1'),
                      marginTop: '4rem',
                    },
                    attrs: { 'data-test': 'commentRecievedTextInput', },
                  })}
                />
                <div
                  className={css({
                    marginTop: '3rem',
                  })}
                >
                  <Button
                    miscStyles={{
                      backgroundColor: 'transparent',
                      marginInlineEnd: '2rem',
                      fontWeight: 'bold',
                    }}
                    boxModel={{ hp: 5, vp: 1, }}
                    onClick={handleSubmit}
                    fontSize={-1}
                  >
                    {getNotificationsBtnTxt}
                  </Button>
                </div>
              </div>
              <DisplayFomoToolTip impressionData={{ feature: 'New comment alert',
                featureType: 'Marketing',
                campaignName: 'fomo',
                campaignDetails: 'new comment', }}
              >
                <div className={css({ marginTop: '4rem',
                  paddingTop: '4rem',
                  borderTop: `1px solid ${theme.color('neutral', '-4')}`,
                  textAlign: 'center',
                  ...theme.type(1),
                })}
                >
                  <div className={css({ fontWeight: '700', marginBottom: '2rem', })}>{theme.fomoToolTipI18n.text.comments}</div>
                  <Button
                    onClick={() => biAction({
                      actionCode: 3,
                      feature: 'New comment alert',
                      featureType: 'Marketing',
                      campaignName: 'fomo',
                      campaignDetails: 'new comment',
                    })
                }
                    href={theme.fomoToolTipI18n.href.comments}
                    variant="salesOpaque"
                    fontSize={0}
                    miscStyles={{
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      paddingInlineStart: '3rem',
                      paddingInlineEnd: '3rem',
                      marginBottom: '2.5rem',
                      borderRadius: '50px',
                    }}
                  >
                    {theme.fomoToolTipI18n.text.button}
                  </Button>
                </div>
              </DisplayFomoToolTip>
            </>
          )}
        />
      )}
    </div>
  );
}

export default CommentSent;
