/* eslint-disable react/no-danger */
import React from 'react';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';

export default function Enhancement(params) {
  const getComponent = useGetComponent();

  return (params.items || []).map(item => {
    const Component = getComponent(item.inputTemplate);
    return <Component {...item} />;
  });
}
