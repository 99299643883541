// @flow
import React from 'react';
import Head from 'next/head';

type Props = {
  section: string,
  author: string,
  site: string,
};

function ArticleChartbeatConfig({ section, author, site, }: Props) {
  const safeSection = section.replace(/'/g, "\\'");
  const safeAuthor = author.replace(/'/g, "\\'");

  const domain = site === 'tm' ? 'themarker.com' : site === 'hdc' ? 'haaretz.com' : 'haaretz.co.il';

  return (
    <Head>
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              var _sf_async_config = _sf_async_config || {};
              /** ChartBeat CONFIGURATION START **/
              _sf_async_config.uid = 5952;
              _sf_async_config.domain = "${domain}";
              var _sf_startpt = (new Date()).getTime();
              /** ChartBeat CONFIGURATION END **/
          `,
        }}
      />
      <script async src="//static.chartbeat.com/js/chartbeat.js" />
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              var _sf_async_config={uid:5952,domain:"${domain}"};
              (function(){
                function loadChartbeat() {
                  window._sf_endpt=(new Date()).getTime();
                  var e = document.createElement('script');
                  e.setAttribute('language', 'javascript');
                  e.setAttribute('type', 'text/javascript');
                  e.setAttribute('src',(("https:" == document.location.protocol) ? "https://s3.amazonaws.com/" : "http://") +
                  "static.chartbeat.com/js/chartbeat.js");
                  document.body.appendChild(e);
                  _sf_async_config.sections = '${safeSection}';
                  _sf_async_config.authors = '${safeAuthor}';
                }
                var oldonload = window.onload;
                window.onload = (typeof window.onload != 'function') ?
                loadChartbeat : function() { oldonload(); loadChartbeat(); };
              })();
          `,
        }}
      />
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              var _sf_async_config = _sf_async_config || {};
              /** CONFIGURATION START **/
              _sf_async_config.sections = '${safeSection}'; //THIS CHANGED
              _sf_async_config.authors = '${safeAuthor}'; //THIS CHANGED
              /** CONFIGURATION END **/
              (function() {
                function loadChartbeat() {
                  var e = document.createElement('script');
                  e.setAttribute('language', 'javascript');
                  e.setAttribute('type', 'text/javascript');
                  e.setAttribute('src', '//static.chartbeat.com/js/chartbeat.js');
                  document.body.appendChild(e);
                }
                  var oldonload = window.onload;
                  window.onload = (typeof window.onload != 'function') ?
                  loadChartbeat : function() {
                  oldonload();
                  loadChartbeat();
                };
                })();
          `,
        }}
      />
      {/* The mab script causes screen flickering in article pages  */}
      {/* <script async src="//static.chartbeat.com/js/chartbeat_mab.js" /> */}
    </Head>
  );
}

export default ArticleChartbeatConfig;
