// @flow
/* global window */
import React from 'react';
import { SPOT_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import useWebViewChecker from '../../../../hooks/useWebViewChecker.js';
import ShouldRenderSpotProvider from './ShouldRenderSpotProvider.js';
import useOneTime from '../../../../hooks/useOneTime';
import { writeToLocalStorage, } from '../../../../utils/writeToStorage';
import SpotView from './SpotView.js';

type SpotWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function SpotWrapper(props: SpotWrapperProps): React.Node {
  const isWebView = useWebViewChecker();
  // make sure to reset spotExclude key before should render check,
  // because sometimes there is no spot list rendered and we don't want to exclude the last id stored
  useOneTime(isWebView, () => writeToLocalStorage({
    key: 'spotExclude',
    // eslint-disable-next-line
    value: '',
    errorMessage: 'error writing from last article id to local storage',
  })
  );
  if (!isWebView) return null;
  return (
    <ShouldRenderSpotProvider renderFrequency={props?.listData?.renderFrequency}>
      {shouldRenderList => {
        if (shouldRenderList === false) {
          return null;
        }
        return (
          <ListWrapper {...props} query={SPOT_LIST_QUERY} view="Spot">
            {dataProps => {
              const { items, } = dataProps?.list || {};
              const renderTeaser = Array.isArray(items) && (items[0]?.origin === 'joni' || items[0]?.origin === 'userHistory');
              if (!renderTeaser) return null;
              return <SpotView {...dataProps} />;
            }}
          </ListWrapper>
        );
      }}
    </ShouldRenderSpotProvider>
  );
}
