// @flow

import type { HTMLEmbedDataType, } from '../flowTypes/HTMLEmbedDataType';
import getImageAssets from './getImageAssets';

export function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

export function getImageProps(media: ImageDataType, options: Object): Object {
  return {
    data: media,
    imgOptions: getImageAssets(options),
  };
}
