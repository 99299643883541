/* globals OBR window */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, } from 'react-apollo';
import Query from '../ApolloBoundary/Query';
import { appendScript, } from '../../utils/scriptTools';
import fromCache from './queries/osakaCacheQuery.graphql';
import isOsakaDisabledQuery from './queries/isOsakaDisabledQuery.graphql';
import useScrollYPosition from '../../hooks/useScrollYPosition';
import OsakaWrapper from './OsakaWrapper';
import useMedia from '../../hooks/useMedia';
import ClickTrackerWrapper from '../ClickTracker/ClickTrackerWrapper';

const propTypes = {
  /**
   * This is an array of clickTrackers data objects.
   */
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /**
   * apollo client object
   */
  client: PropTypes.shape({ writeData: PropTypes.func, }).isRequired,
};
const keys = new Map([
  [ 'haaretz.co.il', 'HAAREPDLHNAQD24GF05E6D3F5', ],
  [ 'themarker.com', 'THEMA156KEJ20O1N23B59L29D', ],
  [ 'haaretz.com', 'HAARE792EQLA7DO04OFQMLI35', ],
]);

function OsakaWithOutbrain(props) {
  /* eslint-disable react/prop-types */
  const {
    host,
    articleId,
    isLabel,
    clickTracker: { text, image, url, },
  } = props;
  /* eslint-enable react/prop-types */

  const [ articles, setArticles, ] = React.useState(null);
  React.useEffect(() => {
    const getArticles = () => {
      const urlLink = `https://www.${host}/${articleId}`;
      const siteKey = keys.get(host);
      OBR
        && OBR.extern.callRecs(
          {
            permalink: urlLink,
            installationKey: siteKey,
            widgetId: 'APP_3',
          },
          json => {
            const outbrainArticles = json.doc;
            setArticles({
              local: [
                {
                  title: outbrainArticles[0].content,
                  image: outbrainArticles[0].thumbnail.url,
                  url: outbrainArticles[0].url,
                },
                {
                  title: outbrainArticles[1].content,
                  image: outbrainArticles[1].thumbnail.url,
                  url: outbrainArticles[1].url,
                },
              ],
              promoted: [
                {
                  title: text || '',
                  image,
                  url,
                },
              ],
              outbrain: [
                {
                  title: outbrainArticles[2].content,
                  image: outbrainArticles[2].thumbnail.url,
                  url: outbrainArticles[2].url,
                  sourceName: outbrainArticles[2].source_display_name,
                },
              ],
            });
          }
        );
    };

    appendScript({
      src: '//widgets.outbrain.com/outbrain.js',
      id: 'outbrain',
      isAsync: false,
      onLoadFunction: getArticles,
      updateFunction: getArticles,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return articles ? <OsakaWrapper {...{ ...props, articles, }} /> : null;
}

function OsakaWithApollo(props) {
  // eslint-disable-next-line react/prop-types
  const { promotedElement, ...restProps } = props;
  return (
    <Query query={fromCache}>
      {({ loading, error, data, client, }) => {
        if (loading) return null;
        if (error) return null;
        const { osakaCanRender, hostname, articleSection, isLabel, } = data;
        const host = hostname.match(/^(?:.*?\.)?(.*)/i)[1];
        //  makes sure that if we have another outbrain element on the page it calls outbrains
        //  reload function script before calling the outbrain json api
        return (
          <ClickTrackerWrapper
            // eslint-disable-next-line react/prop-types
            {...(promotedElement?.[0]?.content || {})}

            render={banner => (osakaCanRender ? (
              <OsakaWithOutbrain
                {...{
                  ...restProps,
                  clickTracker: banner,
                  articleSection,
                  isLabel,
                  host,
                  client,
                }}
              />
            ) : null)
          }
          />
        );
      }}
    </Query>
  );
}

// eslint-disable-next-line react/prop-types
function OsakaController({ items, articleId, }) {
  const { y, velocity, } = useScrollYPosition();
  const { data: osakaDisabledData, } = useQuery(isOsakaDisabledQuery);
  const isOsakaDisabled = osakaDisabledData?.isOsakaDisabled || false;
  const passedVelocity = isOsakaDisabled ? 1 : velocity;
  const isUnderMedium = useMedia({ query: { until: 'm', }, matchOnServer: true, });


  return !isUnderMedium ? (
    <OsakaWithApollo
      promotedElement={items}
      velocity={passedVelocity}
      y={y}
      articleId={articleId}
    />
  ) : null;
}

OsakaController.propTypes = propTypes;

export default OsakaController;
