// @flow
/* global document  */
import React, { useState, useEffect, } from 'react';
import { useFela, } from 'react-fela';
import gql from 'graphql-tag';
import { borderBottom, } from '@haaretz/htz-css-tools';
import { CHECK_USER_NICKNAME_QUERY, SET_USER_NICKNAME_MUTATION, } from '@haaretz/graphql';
import { useMutation, } from 'react-apollo';
import Query from '../ApolloBoundary/Query';
import Button from '../Button/Button'; // eslint-disable-line import/no-named-as-default
import CommentSent from './CommentSent';
import Form from '../Form/Form'; // eslint-disable-line import/no-named-as-default
import TextInput from '../TextInput/TextInput';
import UserDispenser from '../User/UserDispenser';
import EventTracker from '../../utils/EventTracker';
import NonPayingNickNameAlert from './NonPayingNickNameAlert';
import useArticleId from '../../hooks/Page/useArticleId';
import { type SubmitNewCommentParams, } from './utils/comment';

export const GET_USER_NICKNAME = gql`
  query GetUserNickName {
    userNickName @client
  }
`;

const textInputWrapperStyle = {
  flexGrow: 1,
  display: 'block',
};

const inputAndToggleWrapperStyle = {
  display: 'flex',
  alignItems: 'flex-start',
};

const toggleButtonStyle = (theme, isFirst) => ({
  width: '16rem',
  color: theme.color('comments', 'formElementsText'),
  marginTop: '1rem',
  paddingInlineStart: '1rem',
  paddingInlineEnd: '1rem',
  ':focus': {
    outline: 'none',
  },
  extend: [
    theme.type(-2, { lines: '2.714', }),
    borderBottom('1px', 0.7, 'solid', 'transparent'),
    {
      ':hover': {
        ...borderBottom(
          '1px',
          0.7,
          'solid',
          theme.color('comments', 'formElementsText')
        ),
      },
    },
    isFirst
      ? {
        textAlign: 'center',
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          height: '100%',
          end: '0',
          top: '0',
          width: '1px',
          backgroundColor: theme.color('neutral', '-3'),
        },
      }
      : {},
  ],
});

const AddCommentFooterStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const sendCommentButtonsContStyle = {
  marginTop: '1rem',
  marginInlineStart: 'auto',
};

function getPrintableCharsCount(html = '') {
  const regex = /(<([^>]+)>)/gi;
  return html.replace(regex, '').length;
}

type CommentFormPropTypes = {
  /**
   * Update the parent element state that the `<CommentForm />`
   * that is used to reply to another comment should close
   * undefined for the main `<CommentForm />` which never needs to close
   */
  closeReplyForm?: ?Function,
  /**
   * A callback that gets called when subbmiting a new comment
   * @param {String} commentAuthor
   *   The new comment author
   * @param {String} commentTextHtml
   *   The new comment text innerHTML
   * @param {String} parentCommentId
   *   The parent CommentId - defaults to '0' if there is no `parentCommentId`
   */
  initNewComment: (params: SubmitNewCommentParams) => Promise<any>,
  /**
   * The parent commentId when the `<CommentForm />` is a reply
   * form to another Comment, defaults to '0' because of polopoly legacy
   */
  parentCommentId?: string,
  /**
   * A callback that gets the called when submitting the sign up to notification form in `<CommentSent />`
   * @param {string} - notificationEmail - The email the user entered
   */
  signUpNotification: Function,
  userType?: string,
  /**
   * Enables / disables choosing a nickname
   */
  enableNickname?: boolean,
  isDisabled?: boolean,
  fwRef: ?{ current: null | HTMLElement, },
};

CommentForm.defaultProps = {
  parentCommentId: '0',
  closeReplyForm: undefined,
  userType: null,
  enableNickname: true,
  isDisabled: false,
  fwRef: null,
};
function CommentForm({
  parentCommentId,
  signUpNotification,
  closeReplyForm,
  initNewComment,
  userType,
  enableNickname,
  isDisabled,
  fwRef,
}: CommentFormPropTypes) {
  const [ displaySentComp, setDisplaySentComp, ] = useState(false);
  const [ displayThankYou, setDisplayThankYou, ] = useState(false);
  const [ promotedNicknameContent, setPromotedNicknameContent, ] = useState(false);
  const [ sending, setSending, ] = useState(false);
  const [ hasError, setHasError, ] = useState(false);
  const [ errorCode, setErrorCode, ] = useState(0);
  const [ nonPayingNickName, setNonPayingNickName, ] = useState(false);
  const [ identifiedComment, setIdentifiedComment, ] = useState(false);
  const [ isFirstRender, setIsFirstRender, ] = useState(true);
  const [ commentData, setCommentData, ] = useState(null);
  const [ usedNickname, setUsedNickname, ] = React.useState(false);
  const [ newNickNameIsUsed, setNewNickNameIsUsed, ] = React.useState(false);
  const [ isEnabledInput, setIsEnabledInput, ] = React.useState(false);
  const [ changedNickName, setChangedNickName, ] = React.useState(false);
  const [ retrySetNickname, setRetrySetNickname, ] = React.useState(false);
  const [
    chooseNicknameButtonIsDisabled,
    setChooseNicknameButtonIsDisabled,
  ] = React.useState(false);
  const [ authorName, setAuthorName, ] = React.useState(null);
  const [ noNickName, setNoNickName, ] = React.useState(false);
  const articleId = useArticleId();
  const [ setUserNickname, ] = useMutation(SET_USER_NICKNAME_MUTATION);

  const { css, theme, } = useFela();
  let commentAuthorElem = null;
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    else {
      commentAuthorElem && commentAuthorElem.focus();
    }
    // eslint-disable-next-line
  }, [identifiedComment]);

  const isReplyForm = parentCommentId !== '0';

  const TextInputVariant = theme.commentsStyle.textInputVariant;

  const handleSubmitComment = (commentAuthor, commentTextHtml) => {
    // calling setSending(true) on the first line of the onSubmit function
    initNewComment({
      articleId,
      text: commentTextHtml,
      authorName: commentAuthor,
      parentCommentId: isReplyForm ? parentCommentId : undefined,
    })
      .then(data => {
        setDisplaySentComp(true);
        setCommentData(null);
        setSending(false);
      })
      .catch(err => {
        const [ , errorStatus, ] = /^(\d+)?(\W-\W)?(.+)$/.exec(err.message || '') || [];

        setSending(false);
        setDisplaySentComp(true);
        setHasError(true);

        setErrorCode(+errorStatus);

        setCommentData({
          commentAuthor,
          commentTextHtml,
        });
      });
  };

  const handleSignUpNotification = (didSignUp, notificationEmail) => {
    if (!didSignUp) {
      setDisplaySentComp(false);
    }
    else {
      signUpNotification(notificationEmail);
      setDisplaySentComp(false);
    }
  };

  const {
    buttons: {
      sendBtnTxt,
      cancelBtnTxt,
      toggleUserBtnText,
      addUserNickName,
      nickNameChangeNowTxt,
      cancelNickName,
      confirmSetNickname,
      cancelSetNickname,
      retrySetNicknameTxt,
      // haaretzSheliUrl,
    },
    labels: {
      nameLabelTxt,
      commentLabelTxt,
      nicknameLabelTxt,
      chooseNewNickname,
    },
    notes: { nameNoteTxt, commentNoteTxt, nickNameNoteTxt, termsOfUse, },
    errorNotes: {
      nameErrorNoteTxt,
      nicknameErrorNoteTxt,
      nickNameChangeError,
      usedNicknameErrorNoteTxt,
      commentErrorNoteTxt,
      commentErrorTooLongNoteTxt,
    },
  } = theme.commentFormI18n;

  const focusEl = null;
  return (
    <EventTracker>
      {({ biAction, biActionMapper, biImpression, }) => (
        <UserDispenser
          render={({ user, isLoggedIn, }) => (userType !== 'paying'
            && (!displaySentComp || promotedNicknameContent)
            && nonPayingNickName ? (
              <div ref={fwRef}>
                <NonPayingNickNameAlert
                  isReplyForm={isReplyForm}
                  biImpression={biImpression}
                  closeDisplay={() => {
                    setNonPayingNickName(false);
                    if (isReplyForm && !hasError && typeof closeReplyForm === 'function') {
                      closeReplyForm();
                    }
                  }}
                />
              </div>
            ) : displaySentComp || hasError ? (
              <div ref={fwRef}>
                <CommentSent
                  errorCode={errorCode}
                  hasError={hasError}
                  focusEl={focusEl}
                  userEmail={user.email}
                  closeDisplayThankYou={() => {
                    setDisplayThankYou(false);
                    setDisplaySentComp(false);
                    if (enableNickname && userType !== 'paying') {
                      setPromotedNicknameContent(true);
                      setNonPayingNickName(true);
                    }
                    if (userType === 'paying' && isReplyForm && !hasError && typeof closeReplyForm === 'function') {
                      closeReplyForm();
                    }
                    setHasError(false);
                  }}
                  displayThankYou={displayThankYou}
                  isReplyForm={isReplyForm}
                // showPromotedNicknameContent={() => setPromotedNicknameContent(true)}
                  signUpNotification={(didSignUp, notificationEmail) => handleSignUpNotification(didSignUp, notificationEmail)
                }
                />
              </div>
            ) : (
              <Query query={GET_USER_NICKNAME}>
                {({ loading, error, data, client, }) => {
                  if (loading) return null;
                  if (error) console.log(error);
                  const { userNickName, } = data || {};
                  return (
                    <Form
                      fwRef={fwRef}
                      attrs={{
                        'data-test': 'newCommentForm',
                      }}
                      clearFormAfterSubmit={false}
                      disableSubmitOnEnterKeyDown
                      manualErrors={
                      usedNickname
                        ? [
                          {
                            name: 'commentAuthor',
                            order: 1,
                            errorText: nicknameErrorNoteTxt,
                          },
                        ]
                        : null
                    }
                      {...(commentData ? { initialValues: commentData, } : {})}
                      onSubmit={async ({ commentAuthor, commentTextHtml, }) => {
                        setSending(true);
                        if (!userNickName || noNickName) {
                        // next line added to make sure that the user sees some kind of
                        // indication as to what went wrong, should the nickname be unavailable
                          setUsedNickname(false);
                          let checkResponse = null;
                          try {
                            checkResponse = await client.query({
                              query: CHECK_USER_NICKNAME_QUERY,
                              fetchPolicy: 'network-only',
                            });
                          }
                          catch (error) {
                            setCommentData({
                              commentAuthor,
                              commentTextHtml,
                            });
                            setSending(false);
                            setHasError(true);

                            return null;
                          }

                          if (checkResponse?.checkUserNickname?.status === 'used') {
                            setUsedNickname(true);
                            setSending(false);
                          }
                          else {
                            handleSubmitComment(commentAuthor, commentTextHtml);
                          }
                          return null;
                        }
                        return handleSubmitComment(
                          commentAuthor,
                          commentTextHtml
                        );
                      }}
                      validate={({ commentAuthor, commentTextHtml, }) => {
                        const errors = [];
                        if (!commentAuthor && !chooseNicknameButtonIsDisabled) {
                          errors.push({
                            name: 'commentAuthor',
                            order: 1,
                          });
                        }
                        if (
                          !commentTextHtml
                        || getPrintableCharsCount(commentTextHtml) === 0
                        ) {
                          errors.push({ name: 'commentTextHtml', order: 2, });
                        }
                        if (getPrintableCharsCount(commentTextHtml) > 1000) {
                          errors.push({
                            name: 'commentTextHtml',
                            errorText: commentErrorTooLongNoteTxt,
                            order: 2,
                          });
                        }

                        return errors;
                      }}
                      render={({
                        getInputProps,
                        handleSubmit,
                        commentAuthor,
                      }) => (
                        <div
                          className={css({
                            backgroundColor: theme.color('bg', 'base'),
                            display: 'flex',
                            flexDirection: 'column',
                            extend: [
                              borderBottom(
                                '1px',
                                2,
                                'solid',
                                theme.color('comments', 'border')
                              ),
                              {
                                condition: isReplyForm === true,
                                style: {
                                  paddingInlineStart: '8.5rem',
                                  paddingInlineEnd: '2rem',
                                  paddingTop: '3rem',
                                  marginTop: '2rem',
                                  marginBottom: 'calc(1px - 2rem)',
                                },
                              },
                            ],
                          })}
                        >
                          <div className={css(inputAndToggleWrapperStyle)}>
                            <div className={css(textInputWrapperStyle)}>
                              <TextInput
                                {...getInputProps({
                                  onChange: evt => setAuthorName(evt.target.value),
                                  ...(commentData && !noNickName
                                    ? {
                                      defaultValue: commentData.commentAuthor,
                                    }
                                    : {}),
                                  name: 'commentAuthor',
                                  errorText: usedNickname
                                    ? nicknameErrorNoteTxt
                                    : nameErrorNoteTxt,
                                  noteStyle: { color: theme.color('bodyText', 'base'), },
                                  noteText:
                                    (userNickName
                                      && !isEnabledInput
                                      && !noNickName)
                                    || changedNickName ? (
                                      <React.Fragment>
                                        {nickNameNoteTxt}
                                        {' '}
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setIsEnabledInput(true);
                                            setChangedNickName(null);
                                            setRetrySetNickname(false);
                                          }}
                                          className={css({
                                            color: theme.color(
                                              'comments',
                                              'formElementsText'
                                            ),
                                            ':visited': {
                                              color: theme.color(
                                                'comments',
                                                'formElementsText'
                                              ),
                                            },
                                          })}
                                        >
                                          {nickNameChangeNowTxt}
                                        </button>
                                        {theme.commentFormI18n.nickName.or}
                                        <button
                                          data-test="cancelSetNicknameButton"
                                          type="button"
                                          onClick={async () => {
                                            const { data: result, } = await setUserNickname({
                                              variables: {
                                                input: {
                                                  nickname: '',
                                                },
                                              },
                                            });

                                            if (result?.setUserNickname?.status === 'ok') {
                                              setNoNickName(true);
                                              setChooseNicknameButtonIsDisabled(
                                                false
                                              );
                                              setChangedNickName(null);
                                            }
                                            else {
                                              setRetrySetNickname(true);
                                            }
                                          }}
                                          className={css({
                                            color: theme.color(
                                              'comments',
                                              'formElementsText'
                                            ),
                                            ':visited': {
                                              color: theme.color(
                                                'comments',
                                                'formElementsText'
                                              ),
                                            },
                                          })}
                                        >
                                          {cancelNickName}
                                        </button>
                                      </React.Fragment>
                                      ) : (userNickName
                                        || chooseNicknameButtonIsDisabled
                                        || !noNickName)
                                      && isEnabledInput ? (
                                        <div
                                          className={css({
                                            textAlign: 'end',
                                            marginInlineEnd: '1rem',
                                            ...(retrySetNickname
                                          || newNickNameIsUsed
                                              ? { color: 'red', }
                                              : {}),
                                          })}
                                        >
                                          {!retrySetNickname
                                            ? null : newNickNameIsUsed ? usedNicknameErrorNoteTxt : nickNameChangeError}
                                          <button
                                            data-test="addUserNickNameButton"
                                            type="button"
                                            onClick={async () => {
                                              try {
                                                const { data: result, } = await setUserNickname({
                                                  variables: {
                                                    input: {
                                                      nickname: authorName || '',
                                                    },
                                                  },
                                                });

                                                if (result?.setUserNickname?.status === 'ok') {
                                                  setChangedNickName(authorName);
                                                  setIsEnabledInput(false);
                                                  setNoNickName(false);
                                                  setRetrySetNickname(false);
                                                  setNewNickNameIsUsed(false);
                                                }
                                                else {
                                                  result?.setUserNickname?.status === 'exist' && setNewNickNameIsUsed(true);
                                                  setRetrySetNickname(true);
                                                }
                                              }
                                              catch (err) {
                                                setRetrySetNickname(true);
                                                console.error(
                                                  'update nickname error: ',
                                                  err
                                                );
                                              }
                                            }}
                                            className={css({
                                              marginInlineEnd: '2rem',
                                              marginInlineStart: '2rem',
                                              color: theme.color('primary'),
                                              ':visited': {
                                                color: theme.color('primary'),
                                              },
                                            })}
                                          >
                                            {retrySetNickname
                                              ? retrySetNicknameTxt
                                              : confirmSetNickname}
                                          </button>
                                          {' '}
                                          <button
                                            data-test="cancelSetNicknameButton"
                                            type="button"
                                            onClick={() => {
                                              setIsEnabledInput(false);
                                              setRetrySetNickname(false);
                                              setChooseNicknameButtonIsDisabled(
                                                false
                                              );
                                              setNewNickNameIsUsed(false);
                                            }}
                                            className={css({
                                              color: theme.color('primary'),
                                              ':visited': {
                                                color: theme.color('primary'),
                                              },
                                            })}
                                          >
                                            {cancelSetNickname}
                                          </button>
                                        </div>
                                        ) : (
                                          nameNoteTxt
                                        ),
                                  label:
                                    (userNickName
                                      || chooseNicknameButtonIsDisabled)
                                    && !isEnabledInput
                                    && !noNickName
                                      ? nicknameLabelTxt
                                      : (userNickName
                                          || chooseNicknameButtonIsDisabled)
                                        && isEnabledInput
                                        ? chooseNewNickname
                                        : nameLabelTxt,
                                  maxLength: 50,
                                  variant: TextInputVariant,
                                  isDisabled:
                                    isDisabled
                                    || (!isEnabledInput
                                      && !noNickName
                                      && (identifiedComment
                                        || userNickName
                                        || chooseNicknameButtonIsDisabled)),
                                  refFunc: elem => {
                                    commentAuthorElem = elem;
                                  },
                                  ...(identifiedComment
                                    ? {
                                      value: `${user.firstName} ${user.lastName}`,
                                    }
                                    : {}),
                                  ...((userNickName
                                    || chooseNicknameButtonIsDisabled)
                                  && !noNickName
                                    ? {
                                      value:
                                          !isEnabledInput
                                          && !changedNickName
                                          && userNickName
                                          && !noNickName
                                            ? userNickName
                                            : '',
                                    }
                                    : {}),
                                })}
                                isCommentForm
                                miscStyles={{
                                  backgroundColor: theme.color('comments', 'formFieldBg'),
                                  ':focus': {
                                    color: theme.color('comments', 'formFieldBg'),
                                  },
                                  '& [disabled]': { cursor: 'default', },
                                }}
                              />
                            </div>
                            {enableNickname
                            && userType === 'paying'
                            && (!userNickName || noNickName)
                            && !chooseNicknameButtonIsDisabled ? (
                              <button
                                data-test="nicknameButton"
                                type="button"
                                onClick={() => {
                                  setIsEnabledInput(true);
                                  setChooseNicknameButtonIsDisabled(true);
                                  setRetrySetNickname(false);
                                  biAction({
                                    actionCode: 169,
                                    feature: 'Talkbacks',
                                    featureType: 'Content',
                                    campaignName: 'Nickname link',
                                    campaignDetails: addUserNickName,
                                  });
                                }}
                                className={css({
                                  ...toggleButtonStyle(theme, true),
                                  color: theme.color(
                                    'comments',
                                    'formElementsText'
                                  ),
                                  ':visited': {
                                    color: theme.color(
                                      'comments',
                                      'formElementsText'
                                    ),
                                  },
                                })}
                              >
                                {addUserNickName}
                              </button>
                              ) : null}
                            {enableNickname && userType !== 'paying' ? (
                              <button
                                className={css(
                                  toggleButtonStyle(theme, !!isLoggedIn)
                                )}
                                type="button"
                                onClick={() => {
                                  setNonPayingNickName(true);
                                  biAction({
                                    actionCode: 169,
                                    feature: 'Talkbacks',
                                    featureType: 'Content',
                                    campaignName: 'Nickname link',
                                    campaignDetails: addUserNickName,
                                  });
                                }}
                              >
                                {addUserNickName}
                              </button>
                            ) : null}
                            {enableNickname
                            && isLoggedIn
                            && (!userNickName || noNickName)
                            && !chooseNicknameButtonIsDisabled ? (
                              <button
                                data-test="identifiedCommentButton"
                                className={css(toggleButtonStyle(theme, false))}
                                type="button"
                                onClick={() => {
                                  setIdentifiedComment(prevState => !prevState);
                                }}
                              >
                                {toggleUserBtnText(identifiedComment)}
                              </button>
                              ) : null}
                          </div>

                          <TextInput
                            {...getInputProps({
                              name: 'commentTextHtml',
                              ...(commentData
                                ? { defaultValue: commentData.commentTextHtml, }
                                : {}),
                              errorText: commentErrorNoteTxt,
                              height: [ { until: 's', value: 25, }, { from: 's', value: 14, }, ],
                              toggleCommandBiCallBack: command => {
                                biAction({
                                  actionCode: biActionMapper.get(
                                    'text_design_tools'
                                  ),
                                  additionalInfo: {
                                    effect: command,
                                  },
                                });
                              },
                              isContentEditable: true,
                              noteText: commentNoteTxt,
                              linkAfterText: termsOfUse,
                              noteStyle: { color: theme.color('bodyText', 'base'), },
                              label: commentLabelTxt,
                              miscStyles: { marginTop: '4.14rem', backgroundColor: theme.color('comments', 'formFieldBg'), },
                              variant: TextInputVariant,
                              isDisabled,
                            })}
                            isCommentForm
                          />

                          <div className={css(AddCommentFooterStyle)}>
                            <div className={css(sendCommentButtonsContStyle)}>
                              {isReplyForm ? (
                                <Button
                                  variant="negative"
                                  boxModel={{ hp: 5, vp: 0.5, }}
                                  miscStyles={{
                                    marginInlineEnd: '2rem',
                                    backgroundColor: theme.color('bg', 'base'),
                                    color: theme.color('comments', 'cancelReplayBtn'),
                                    borderColor: theme.color('comments', 'cancelReplayBtn'),
                                  }}
                                  onClick={closeReplyForm}
                                >
                                  {cancelBtnTxt}
                                </Button>
                              ) : null}
                              {isDisabled ? null : (
                                <Button
                                  attrs={{
                                    'data-test': 'sendComment',
                                  }}
                                  variant={
                                    theme.commentsStyle.sendButtonVariant
                                  }
                                  miscStyles={{
                                    backgroundColor: theme.color('bg', 'base'),
                                  }}
                                  boxModel={{ hp: 5, vp: 0.5, }}
                                  isBusy={sending}
                                  onClick={evt => {
                                    biAction({
                                      actionCode: biActionMapper.get(
                                        'send_comment'
                                      ),
                                    });
                                    handleSubmit(evt);
                                  }}
                                >
                                  {sendBtnTxt}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  );
                }}
              </Query>
            ))
}
        />
      )}
    </EventTracker>
  );
}

export default CommentForm;
