// @flow
import * as React from 'react';
import { useEffect, } from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import CommentForm from './CommentForm';
import Button from '../Button/Button';
import HtzLink from '../HtzLink/HtzLink';
import type { CommentsBannerProps, } from './ApolloCommentsWrapper';
import HtmlNode from '../HtmlNode/HtmlNode';


type CommentPaywallDataProps = {
    biAction: ?Function,
    biImpression: ?Function,
    bannerData: ?CommentsBannerProps,
  }

CommentPaywall.defaultProps = {
  biAction: null,
  biImpression: null,
  bannerData: null,
};


function CommentPaywall({
  biAction,
  biImpression,
  bannerData, }: CommentPaywallDataProps): React.Node {
  const [ inViewRef, inView, ] = useInView({ triggerOnce: true, });

  if (inView) {
    biImpression && biImpression({
      feature: 'Talkbacks',
      featureType: 'Marketing',
      campaignName: 'Subs join faster',
      campaign_details: 'SUBSCRIBE',
    });
  }

  const { css, theme, } = useFela();

  const commentPaywallWrapper = () => ({
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '32rem',
      zIndex: 1,
      top: 0,
      right: 0,
      backgroundImage: `linear-gradient(179deg, transparent -76%, ${theme.color('neutral', '-10')} 95%)`,
      extend: [ theme.mq({ until: 'l', }, { left: '0rem', }),
        theme.mq({ from: 'l', }, { left: '22rem', }), ],
    },
  });

  const bannerStyle = () => ({
    position: 'relative',
    padding: '4rem',
    fontFamily: theme.fontStacks[theme.commentsSectionI18n.signin.fontFamily],
    color: theme.color(theme.commentsSectionI18n.signin.color),
    backgroundColor: theme.color('commentsPaywall', 'bgc'),
    zIndex: 2,
    extend: [ theme.mq({ until: 's', }, { marginTop: '-18rem', marginBottom: '4rem', }),
      theme.mq({ from: 's', }, { marginTop: '-15rem', marginBottom: '6rem', }), ],
  });

  if (!bannerData) {
    return null;
  }

  return (
    <>
      <div className={css(commentPaywallWrapper)}>
        <CommentForm isDisabled />
      </div>
      <div className={css(bannerStyle)} ref={inViewRef}>
        <h3 className={css({
          extend: [
            theme.type(1),
          ], })}
        >
          {bannerData.bannerHeader}
        </h3>

        { bannerData.bannerText
          ? bannerData.bannerText.map(htmlNode => (
            <HtmlNode
              className={css({ extend: [ theme.type(2), ], })}
              {...htmlNode}
            />
          ))
          : null }

        <Button
          href={theme.commentsPaywallBlocker.subscribeUrl}
          variant="salesOpaque"
          onClick={() => biAction && biAction({
            actionCode: 3,
            featureType: 'Marketing',
            feature: 'Talkbacks',
            campaignName: 'Subs join faster',
            campaign_details: 'SUBSCRIBE',
          })}
        >
          {bannerData.bannerAction}
        </Button>

        <div className={css({
          marginTop: '1rem',
        })}
        >
          <span>{theme.commentsPaywallBlocker.askIfSignUp}</span>
          {' '}
          <HtzLink
            className={css({
              textDecoration: 'underline',
            })}
            href={theme.commentsPaywallBlocker.loginUrl}
            onClick={() => biAction && biAction({
              actionCode: 51,
              featureType: 'Marketing',
              feature: 'Talkbacks',
              campaignName: 'Subs join faster',
              campaign_details: 'LOG IN',
            })}
          >
            {theme.commentsPaywallBlocker.logIn}
          </HtzLink>
        </div>
      </div>
    </>
  );
}
export default CommentPaywall;
