// @flow
/* global window */
import * as React from 'react';
import { ZOIDBERG_LIST_QUERY, } from '@haaretz/graphql';
import ZoidbergView from './ZoidbergView';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type ZoidbergWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
};

export default function ZoidbergWrapper(props: ZoidbergWrapperProps): React.Node {
  return (
    <ListWrapper
      {...props}
      query={ZOIDBERG_LIST_QUERY}
      view="Zoidberg"
    >
      {dataProps => (<ZoidbergView isPersonal={props.isPersonal} {...dataProps} />)}
    </ListWrapper>
  );
}
