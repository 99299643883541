// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import GraphController from './GraphController';
import type { Props, } from './GraphController';
import Caption from '../Caption/Caption';
import useMedia from '../../hooks/useMedia';
import MobileGraphController from '../MobileGraphController/MobileGraphController';

export default function ArticleGraphController({
  contentName,
  miscStyles,
  ...rest
}: Props & { contentName: ?string, }) {
  const { css, theme, } = useFela();
  const isMobile = useMedia({ query: { until: 's', }, });

  return (
    <figure
      className={css({
        extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
      })}
    >
      {isMobile
        ? <MobileGraphController {...rest} />
        : <GraphController {...rest} />
      }
      {contentName ? <Caption caption={contentName} /> : null}
    </figure>
  );
}
