/* global window */
import React, { Component, } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import NoSSR from '../../NoSSR/NoSSR';

const createPageConfiguration = ({
  authors,
  category,
  description,
  tags,
  pubDate,
  titleSEO,
}) => ({
  publisher: 'haaretz', // always haaretz
  category,
  description,
  dayofweek: new Date().toLocaleDateString('en-us', { weekday: 'long', }), // day of the week
  article: {
    author: authors,
    publishingdate: pubDate,
    editortags: tags,
  },
});

class Permutive extends Component {
  static propTypes = {
    authors: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.shape({}),
    pubDate: PropTypes.string,
    titleSEO: PropTypes.string,
  };

  // set default Haaretz params
  static defaultProps = {
    authors: 'Haaretz',
    category: 'haaretz/',
    description: 'Haaretz',
    tags: { defaultTag: 'Haaretz', },
    pubDate: '',
    titleSEO: 'Haaretz Title',
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      authors,
      category,
      description,
      tags,
      pubDate,
      titleSEO,
    } = this.props;
    const page = {
      ...createPageConfiguration({
        authors,
        category,
        description,
        tags,
        pubDate,
        titleSEO,
      }),
      publisher: 'haaretz', // always haaretz
    };
    return (
      <NoSSR>
        <Head>
          <script
            type="text/javascript"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
          !function(n,e,i) {
      if(!n){n=n||{},window.permutive=n,n.q=[],n.config={}||{},n.config.apiKey=e,n.config.environment=n.config.environment||"production";for(var o=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<o.length;r++){var t=o[r];n[t]=function(e){return function(){var i=Array.prototype.slice.call(arguments,0);n.q.push({functionName:e,arguments:i})}}(t)}}}(window.permutive,"b22711f5-0497-4009-9336-19f02a418aa1");
    window.googletag=window.googletag||{},window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var g=window.localStorage.getItem("_pdfps");window.googletag.pubads().setTargeting("permutive",g?JSON.parse(g):[])}});
     permutive.addon("web", {
            page: ${JSON.stringify(page)}
          });
          `,
            }}
          />
          <script
            async
            id="permutive_load"
            src="https://fe68d1f7-c9f4-45b3-8905-7cda73d3fd74.edge.permutive.app/5df8e9c7-77da-4197-8c3e-fc21422d0462-web.js"
          />
        </Head>
      </NoSSR>
    );
  }
}

export default Permutive;
