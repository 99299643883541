// @flow
import * as React from 'react';

import { useRainbowSlot, } from './RainbowDataProvider';
import RainbowTool from './RainbowTool';

export type SlotPropsType = {
  /** Id of the slot */
  id: string,
  /** extra styles to pass to the rendered rainbow element */
  miscStyles?: StyleProps,
  /** event-handler to fire when slot renders a rainbow-tool */
  onToolRendered?: ?(() => void),
};

RainbowSlot.defaultProps = {
  miscStyles: null,
  onToolRendered: null,
};

export default function RainbowSlot({ id, miscStyles, onToolRendered, }: SlotPropsType) {
  const tool = useRainbowSlot(id);

  React.useEffect(() => {
    if (tool && onToolRendered) {
      onToolRendered(tool);
    }
  }, [ onToolRendered, tool, ]);

  return (
    tool
      ? <RainbowTool key={tool.toolId} tool={tool} miscStyles={miscStyles} />
      : null
  );
}
