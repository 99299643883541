import React, { useState, } from 'react';
import PropTypes, { oneOf, shape, oneOfType, } from 'prop-types';
import Downshift from 'downshift';
import { useFela, } from 'react-fela';
import { borderTop, borderBottom, parseStyleProps, } from '@haaretz/htz-css-tools';
import { attrsPropType, } from '../../propTypes/attrsPropType';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';
import selectStyle from './selectStyle';
import Note from '../Note/Note';

const selectVariants = oneOf([ 'primary', 'graph', ]);

const itemPropType = shape({
  display: PropTypes.string,
  value: oneOfType([ PropTypes.string, PropTypes.number, ]).isRequired,
  key: oneOfType([ PropTypes.string, PropTypes.number, ]),
});

const ItemStyle = ({ theme, variant = 'primary', isSelected, activeItem, buttonMiscStyles, }) => ({
  width: '100%',
  textAlign: 'start',
  paddingInlineStart: '1rem',
  paddingInlineEnd: '1rem',
  ...(isSelected ? { fontWeight: 'bold', } : {}),
  backgroundColor:
    activeItem || isSelected
      ? theme.color('select', `${variant}HighlightedBg`)
      : theme.color('select', `${variant}Bg`),
  ':focus': {
    outline: 'none',
  },
  extend: [
    borderTop(
      `${theme.selectStyle.borderWidth}px`,
      theme.selectStyle.lines,
      theme.selectStyle.borderStyle,
      theme.color('select', `${variant}BorderItem`)
    ),
    {
      ':before': {
        left: '0',
        right: '0',
      },
    },
    {
      paddingBottom: theme.selectStyle.verticalPaddingButton,
      paddingTop: theme.selectStyle.verticalPaddingButton,
    },
    ...(buttonMiscStyles ? parseStyleProps(buttonMiscStyles, theme.mq, theme.type) : []),
  ],
});

const selectedItemStyle = ({
  // noHighlitedItems,
  theme,
  variant,
  isOpen,
  shouldBoldSelectedItem,
}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...(!isOpen
    ? {
      ':focus': {
        backgroundColor: theme.color('select', `${variant}FocusBg`),
      },
    }
    : {
      ...shouldBoldSelectedItem ? { fontWeight: '700 ', } : {},
    }),
  ':after': {
    content: '""',
    width: 0,
    height: 0,
    borderLeft: '0.3em solid transparent',
    borderRight: '0.3em solid transparent',
    borderTop: `0.5em solid ${theme.color('select', `${variant}ArrowColor`)}`,

    transitionProperty: 'all',
    transformOrigin: 'center center',
    ...theme.getTransition(0, 'swiftIn'),
    ...(isOpen ? { transform: 'rotate(180deg)', } : {}),
  },
  extend: [
    {
      ':before': { content: '', },
    },
  ],
});

Select.propTypes = {
  /**
   * An object of attrbutes to set on the DOM element.
   * Passed to the underlying react element
   */
  attrs: attrsPropType,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  buttonMiscStyles: stylesPropType,
  /**
   * selectedItem of a controlled `<Select />`.
   * Should never be passed manually by the consumer, but rather
   * set by the controlling component.
   */
  notesMiscStyles: stylesPropType,
  /**
   * selectedItem of a controlled `<Select />`.
   * Should never be passed manually by the consumer, but rather
   * set by the controlling component.
   */
  controlledSelectedItem: itemPropType,
  /**
   * The initial selected item of an uncontrolled `<Select />`.
   *
   * Only relevant when using an uncontrolled select.
   * Allows specifying the initial item but leaving subsequent
   * updates uncontrolled.
   */
  defaultSelectedItem: itemPropType,
  /** error note to display if input is passed a `isError` prop */
  errorText: PropTypes.string,
  /** Is this RadioGroup in error state */
  isError: PropTypes.bool,
  /** Determines if the text for the selected item should be bold */
  shouldBoldSelectedItem: PropTypes.bool,
  /**
   * An Array of option Objects for the Select,
   *
   * display key is the display string that will show in the Select
   *
   * the value key is the value of the input when option is chosen
   *
   * if the value is not unique, a unique key should be given to each option
   */
  items: PropTypes.arrayOf(itemPropType).isRequired,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  wrapperMiscStyles: stylesPropType,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  dropDownMiscStyles: stylesPropType,
  /**
   * Id used to connect the note to `RadioGroup` with aria-describedby for a11y reasons,
   * default will generate random id
   */
  noteId: PropTypes.string,
  /** Note explaining the RadioGroup field  */
  noteText: PropTypes.string,
  /**
   * A callback that gets called when a RadioButton is Blurred
   * @param {SyntheticEvent} evt - The event object
   */
  onBlur: PropTypes.func,
  /**
   * A callback that gets the the new selectedItem
   * @param {object} item - The selected Item Object
   */
  onChange: PropTypes.func,
  /**
   * A callback that gets called when a `RadioButton` is focused
   * @param {SyntheticEvent} evt - The event object
   */
  onFocus: PropTypes.func,
  /** The placeholder to display when no item is selected */
  placeholder: PropTypes.string,
  /**
   * The refFunc is passed to the wrapping div
   * A callback function to allow parent component to get ref of Select,
   * example use case: focusing the Select.
   */
  refFunc: PropTypes.func,
  /** The `<Select />`'s stylistic variant */
  variant: PropTypes.oneOfType([
    selectVariants,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: selectVariants.isRequired,
      })
    ),
  ]),
};

Select.defaultProps = {
  attrs: null,
  buttonMiscStyles: null,
  notesMiscStyles: null,
  controlledSelectedItem: null,
  defaultSelectedItem: null,
  errorText: null,
  isError: false,
  shouldBoldSelectedItem: false,
  noteId: null,
  noteText: null,
  miscStyles: null,
  wrapperMiscStyles: null,
  dropDownMiscStyles: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  placeholder: 'בחר אחת מהאפשרויות הבאות',
  refFunc: null,
  variant: 'primary',
};

export default function Select({
  noteId: noteIdFromProps,
  errorText,
  noteText,
  onChange,
  attrs,
  buttonMiscStyles,
  controlledSelectedItem,
  defaultSelectedItem,
  items,
  isError,
  miscStyles,
  wrapperMiscStyles,
  dropDownMiscStyles,
  onBlur,
  onFocus,
  onClick,
  placeholder,
  refFunc,
  variant,
  notesMiscStyles,
  shouldBoldSelectedItem,
}) {
  const { css, theme, } = useFela();
  const [ stateSelectedItem, setSelectedItem, ] = useState(null);
  const [ search, setSearch, ] = useState('');
  const [ noteId, ] = useState(
    noteIdFromProps || (errorText || noteText ? Math.random().toString() : null)
  );

  const handleChange = selectedItem => {
    if (selectedItem) {
      setSelectedItem(selectedItem);
      if (onChange) onChange(selectedItem);
    }

    setSearch('');
  };
  const selectedItem = controlledSelectedItem || stateSelectedItem;
  const selectedItemIndex = items
    .map(item => item.key || item.value)
    .indexOf(selectedItem ? selectedItem.key || selectedItem.value : null);
  return (
    <div
      className={css({
        position: 'relative',
        extend: [
          ...(wrapperMiscStyles ? parseStyleProps(wrapperMiscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <Downshift
        selectedItem={selectedItem}
        {...(defaultSelectedItem ? { defaultSelectedItem, } : {})}
        defaultHighlightedIndex={selectedItem ? selectedItemIndex : 0}
        itemToString={item => (item ? item.display : null)}
        onChange={handleChange}
      >
        {({
          isOpen,
          getToggleButtonProps,
          getItemProps,
          highlightedIndex,
          toggleMenu,
          selectItemAtIndex,
          inputValue,
        }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            {...attrs}
            onKeyDown={evt => {
              if (isOpen) {
                if (evt.keyCode === 9) toggleMenu();
                if (evt.keyCode === 32) {
                  selectItemAtIndex(highlightedIndex);
                }
                if (evt.keyCode !== 9 && evt.keyCode !== 32) {
                  const symbol = String.fromCharCode(evt.keyCode);
                  if (/[a-zA-Z0-9]/.test(symbol)) {
                    // on press letters, numbers, space and symbols
                    setSearch(search + evt.key);
                  }
                  else if (evt.keyCode === 8) {
                    // on press backspace
                    setSearch(search.slice(0, -1));
                  }
                  else if (!evt.shiftKey && (evt.keyCode < 37 || evt.keyCode > 40)) {
                    setSearch('');
                  }
                }
                else if (search) {
                  setSearch('');
                }
              }
            }}
          >
            <div className={css(selectStyle({ theme, miscStyles, variant, isOpen, }))}>
              {/* Downshift takes care of a11y */}
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                {...getToggleButtonProps({
                  'data-test': 'selectButton',
                  'aria-label': theme.selectAriaLabel.text,
                  // buttonMiscStyles,
                  // variant,
                  // isOpen,
                  className: css(
                    ItemStyle({
                      theme,
                      variant,
                      isSelected: false,
                      activeItem: false,
                      buttonMiscStyles,
                    }),
                    selectedItemStyle({
                      // noHighlitedItems: highlightedIndex === null,
                      theme,
                      variant,
                      isOpen,
                      shouldBoldSelectedItem,
                    })
                  ),
                  // noHighlitedItems: highlightedIndex === null,
                  type: 'button',
                  ...(refFunc ? { ref: refFunc, } : {}),
                  ...(onBlur ? {
                    onBlur: (...rest) => {
                      setSearch('');
                      return onBlur(...rest);
                    }, } : { onBlur: () => {
                    setSearch('');
                  }, }),
                  ...(onFocus ? { onFocus, } : {}),
                  ...(onClick ? { onClick, } : {}),
                })}
              >
                {!search ? selectedItem ? selectedItem.display || selectedItem.value : placeholder : search}
              </button>
              <div
                className={css({
                  position: 'absolute',
                  top: '100%',
                  start: '-1px',
                  end: '-1px',
                  borderRightWidth: `${theme.selectStyle.borderWidth}px`,
                  borderLeftWidth: `${theme.selectStyle.borderWidth}px`,
                  borderColor: theme.color('select', `${variant}Border`),
                  borderRightStyle: theme.selectStyle.borderStyle,
                  borderLeftStyle: theme.selectStyle.borderStyle,
                })}
              >
                {isOpen ? (
                  <div
                    className={css({
                      display: 'flex',
                      flexDirection: 'column',
                      extend: [
                        borderBottom(
                          `${theme.selectStyle.borderWidth}px`,
                          0,
                          theme.selectStyle.borderStyle,
                          theme.color('select', `${variant}Border`)
                        ),
                        ...(dropDownMiscStyles
                          ? parseStyleProps(dropDownMiscStyles, theme.mq, theme.type)
                          : []),
                      ],
                    })}
                    data-test="dropdown-menu"
                  >
                    {items.filter(item => {
                      if (!search) {
                        return true;
                      }

                      const searchPhrase = search.toLowerCase();

                      return (
                        item.value.toString().toLowerCase().includes(searchPhrase)
                          || item.display.toString().toLowerCase().includes(searchPhrase)
                      );
                    }).map((item, index) => {
                      const isSelected = selectedItem === item;
                      const activeItem = highlightedIndex === index;
                      return (
                      // Downshift takes care of a11y
                      //  eslint-disable-next-line react/button-has-type
                        <button
                          className={css(
                            ItemStyle({ theme, variant, isSelected, activeItem, buttonMiscStyles, })
                          )}
                          {...getItemProps({
                            // buttonMiscStyles,
                            item,
                            key: item.key || item.value,
                            // activeItem,
                            // isSelected,
                            role: 'button',
                          })}
                        >
                          {item.display || item.value}
                        </button>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </Downshift>
      {errorText || noteText ? (
        <Note miscStyles={notesMiscStyles} text={isError ? errorText : noteText} isError={isError} noteId={noteId} />
      ) : null}
    </div>
  );
}
