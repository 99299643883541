// @flow
import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';

import ListItem from '../../elements/ListItem';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import BlockLink from '../../../BlockLink/BlockLink';
import Section from '../../../AutoLevels/Section';
import H from '../../../AutoLevels/H';
import Image from '../../../Image/Image';
import setBiAction from '../../../../utils/setBiAction';
import { isClickTrackerWrapper, } from '../../../../utils/validateType';

type PromotedItemProps = {
  path: string,
  title: ?string,
  image: ImageDataType,
  suffix?: boolean,
  paragraphHeight?: Object,
  textType?: number,
};

export function PromotedItem({
  path,
  title,
  image,
  suffix,
  paragraphHeight,
  textType,
}: PromotedItemProps): Node {
  const { theme, css, } = useFela();
  const wrapperClassName = css({ paddingTop: '1px', });
  const hClassName = css({
    flexGrow: '1',
    fontWeight: '700',
    marginStart: '1rem',
    marginEnd: '1rem',
    marginTop: '1rem',
    maxHeight: '12rem',
    overflow: 'hidden',
    fontFamily: theme.fontStacks.commercial,
    extend: [ theme.type(textType || -1), paragraphHeight || {}, ],
  });
  const pClassName = css({
    ...theme.type(-3),
    color: theme.color('commercial'),
    fontWeight: '700',
    position: 'absolute',
    bottom: '5px',
    left: '10px',
  });
  return (
    /* eslint-enable react/prop-types */
    <Fragment>
      <div className={wrapperClassName} data-test="leela">
        <Image
          image={image}
          imgOptions={{
            transforms: {
              width: '125',
              aspect: 'regular',
              quality: 'auto',
            },
          }}
          miscStyles={{
            width: '18rem',
            flexShrink: '0',
            flexGrow: '0',
          }}
        />
      </div>
      <div>
        <H className={hClassName}>{title}</H>
        {suffix ? (
          <p className={pClassName}>{theme.osakaI18n.promotedContent}</p>
        ) : null}
      </div>
    </Fragment>
  );
}

type Props = {
  gaAction: () => void,
  biAction: ?ListBiActionType,
  /**
   * data object from polopoly
   */
  list: ListDataType,
  /**
   * Determine if the images should be lazyloaded.
   */
  isLazyloadImages: boolean,
};

// eslint-disable-next-line react/prop-types
function Leela({ list, isLazyloadImages, gaAction, biAction, }: Props): Node {
  const { theme, css, } = useFela();
  const wrapperClassName = css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: `${300 / 7}rem`,
  });
  const hClassName = css({
    ...theme.type(1),
    fontWeight: '700',
    color: theme.color('neutral', '-2'),
    ...borderBottom('2px', 1, 'solid', theme.color('neutral', '-2')),
    marginBottom: '2rem',
  });
  return (
    <div className={wrapperClassName}>
      <H className={hClassName}>{list.title}</H>
      <Section>
        {list.items.map((item, index) => (isClickTrackerWrapper ? (
          <ListItem key={item.contentId}>
            <ClickTracker
              {...item}
              render={(banner: ClickTrackerBannerType) => {
                const { text, link, clicktrackerimage, } = banner;
                return (
                  <BlockLink
                    miscStyles={{
                      display: 'flex',
                      marginBottom: '2rem',
                      border: [
                        '1px',
                        0,
                        'solid',
                        theme.color('neutral', '-4'),
                      ],
                      borderEnd: [
                        '4px',
                        0,
                        'solid',
                        theme.color('neutral', '-4'),
                      ],
                    }}
                    href={link}
                    onClick={setBiAction(index, item, biAction)}
                  >
                    <PromotedItem
                      title={text}
                      image={clicktrackerimage}
                      path={link}
                    />
                  </BlockLink>
                );
              }}
            />
          </ListItem>
        ) : null)
        )}
      </Section>
    </div>
  );
}

export default Leela;
