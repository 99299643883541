import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseComponentProp, borderBottom, } from '@haaretz/htz-css-tools';
import List from './elements/list';
import useMedia from '../../hooks/useMedia';

const singleArticlePropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    url: PropTypes.string.isRequired,
    sourceName: PropTypes.string,
  })
);

const propTypes = {
  /**
   * The text for the NextArticle button.
   */
  nextArticleText: PropTypes.string.isRequired,
  /**
   * The path for the next article in the list.
   */
  nextArticleUrl: PropTypes.string.isRequired,
  /**
   * An object of Arrays, which each contains article objects:<br/>
   * `{exclusive: 'string', title: 'string', imageUrl: 'string', url: 'string'}`.
   */
  lists: PropTypes.shape({
    local: singleArticlePropTypes,
    promoted: singleArticlePropTypes,
    outbrain: singleArticlePropTypes,
  }).isRequired,
};
const osakaIndent = '3rem';
function Osaka({ nextArticleUrl, nextArticleText, lists, }) {
  const { css, theme, } = useFela();
  const isUntilXL = useMedia({ query: { until: 'xl', }, });

  return (
    <div
      className={css({
        backgroundColor: theme.color('neutral', '-10'),
        fontFamily: theme.fontStacks[theme.osakaStyle.fontStack],
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: osakaIndent,
        extend: [
          borderBottom('1px', 0, 'solid', theme.color('osaka', 'borderEnd')), ],
        ...theme.mq({ from: 'l', until: 'xl', }, { gridTemplateColumns: '1fr 2fr', }),
      })}
    >
      <List
        articles={lists.local.slice(0, isUntilXL ? 1 : 2)}
        miscStyles={{
          display: 'grid',
          columnGap: osakaIndent,
          paddingInlineStart: osakaIndent,
          ...theme.mq({ from: 'l', until: 'xl', }, {
            paddingInlineStart: osakaIndent,
            gridTemplateColumns: '1fr',
          }),
          ...theme.mq({ until: 'l', }, {
            paddingInlineStart: osakaIndent,
          }),
          ...theme.mq({ from: 'xl', }, {
            gridTemplateColumns: '1fr 1fr',
          }),
        }}
      />
      <div className={css({
        backgroundColor: theme.color('osaka', 'promotedBg'),
        display: 'grid',
        paddingInlineStart: osakaIndent,
        paddingInlineEnd: osakaIndent,
        ...theme.mq({ from: 'l', }, {
          gridTemplateColumns: '1fr 1fr',
        }),
      })}
      >
        <List
          articles={lists.promoted}
          promoted
          miscStyles={{
            ...theme.mq({ until: 'l', }, { display: 'none', }),
          }}
        />

        <List
          articles={lists.outbrain}
          outbrain
        />
      </div>
    </div>
  );
}

Osaka.propTypes = propTypes;

export default Osaka;
