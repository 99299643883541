// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';

import ListItem from '../../elements/ListItem';
import AdSlot from '../../../AdManager/AdSlot';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import Image from '../../../Image/Image';
import { PromotedItem, } from '../Leela/LeelaView';
import BlockLink from '../../../BlockLink/BlockLink';
import H from '../../../AutoLevels/H';
import HtzLink from '../../../HtzLink/HtzLink';
import Section from '../../../AutoLevels/Section';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import { isClickTrackerWrapper, isAdSlot, isTeaser, } from '../../../../utils/validateType';
import useWebViewChecker from '../../../../hooks/useWebViewChecker';
import { adaptArticleLink, } from '../../../../utils/adaptArticleLink';

const listItemStyle = {
  alignItems: 'flex-start',
  display: 'flex',
};

type ClickTrackerProps = {
  item: ClickTrackerBannerWrapperType,
  index: number,
  biAction: ?ListBiActionType,
};

ClickTrackerItem.defaultProps = {
  index: null,
};

function ClickTrackerItem({ item, index, biAction, }: ClickTrackerProps): Node {
  const { theme, } = useFela();
  return (
    <ClickTracker
      {...item}
      render={(banner: ClickTrackerBannerType) => {
        const { text, link, image, } = banner;
        return (
          <BlockLink
            miscStyles={{
              ...listItemStyle,
              marginTop: '1rem',
              border: [ '1px', 0, 'solid', theme.color('neutral', '-4'), ],
              backgroundColor: theme.color('primary', '-6'),
            }}
            href={link}
            onClick={setBiAction(index, item, biAction)}
          >
            <PromotedItem
              title={text}
              image={image}
              path={link}
              suffix
              paragraphHeight={{ maxHeight: '9rem', }}
              textType={-2}
            />
          </BlockLink>
        );
      }}
    />
  );
}

type ItemProps = {
  data: TeaserDataType,
  index: number,
  biAction: ?ListBiActionType,
  listLength: number,
  isPersonal: boolean,
};

function Item({ data, index, biAction, listLength, isPersonal, }: ItemProps): Node {
  const { theme, css, } = useFela();
  const isWebView = useWebViewChecker();

  const spanClassName = css({
    width: '18rem',
    flexShrink: '0',
    flexGrow: '0',
  });
  const hClassName = css({
    fontWeight: '700',
    marginStart: '1rem',
    flexGrow: '1',
    extend: [ theme.type(-2), ],
  });

  const href = isPersonal ? data.path : adaptArticleLink(data.path || '/', isWebView, data.origin);

  const image = data?.mobileImage || data?.image;

  return (
    <BlockLink
      miscStyles={{
        ...listItemStyle,
        marginTop: index ? '1rem' : undefined,
        // place separators only between items
        ...(index < listLength - 1
          ? {
            borderBottom: [ '1px', '1', 'solid', theme.color('neutral', '-4'), ],
          }
          : {
            paddingBottom: '1rem',
          }),
      }}
      href={href}
      onClick={setBiAction(index, data, biAction)}
    >
      <Section isFragment>
        <span className={spanClassName}>
          <Image
            image={image}
            imgOptions={{
              transforms: {
                width: '125',
                aspect: 'regular',
                quality: 'auto',
              },
            }}
            miscStyles={{
              width: '18rem',
              flexShrink: '0',
              flexGrow: '0',
            }}
          />
        </span>
        <H className={hClassName}>
          <HtzLink href={data.path}>{data.title}</HtzLink>
        </H>
      </Section>
    </BlockLink>
  );
}

type Props = {
  // gaAction: () => void,
  biAction: ?ListBiImpressionType,
  // biImpression: () => void,
  biImpression: ?ListBiActionType,
  /**
   * data object from polopoly
   */
  list: ListDataType,
  /**
   * Determine if the component should be lazyloaded. Defaults to `false`.
   * If lazyloaded, indicates how many pixels before entering the screen
   * should the image be loaded.
   * For example, when `{isLazyloadImages: true}`, the image will be
   * lazyloaded as soon as it enters the screen. When `{isLazyloadImages: '400px'}`
   * the image will be lazyloaded 400px before entering the screen.
   * Strings should be in css length units.
   */
  isLazyloadImages: boolean,
  inView: boolean,
  isLabel: boolean,
  isPersonal: boolean,
};

Zoidberg.defaultProps = {
  isPersonal: false,
};

// eslint-disable-next-line react/prop-types
function Zoidberg({
  list,
  isLazyloadImages,
  biAction,
  biImpression,
  inView,
  isLabel,
  isPersonal,
}: Props): Node {
  const { title, items, banners, } = list;
  const adSlots = banners?.adSlots || [];
  const clickTrackers = banners?.clickTrackerBanners || [];

  const { theme, css, } = useFela();
  const [ hasImpressionSent, setHasImpressionSent, ] = React.useState(false);

  React.useEffect(() => {
    inView && !hasImpressionSent && items.map((item, i) => {
      i < 5 && biImpression && isTeaser(item) && setBiImpression(i, item, biImpression)();
      setHasImpressionSent(true);
      return null;
    }
    );
  }, [ biImpression, hasImpressionSent, inView, items, list, ]);

  const wrapperClassName = css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    ...(isLabel ? {
      fontFamily: theme.fontStacks.commercial,
      border: '1px solid',
      borderColor: theme.color('neutral', '-4'),
      backgroundColor: theme.color('commercial', 'bg'),
    } : {}),
  });
  const hClassName = css({
    ...theme.type(1),
    fontWeight: '700',
    color: isLabel ? theme.color('commercial') : theme.color('zoidbergList', 'title'),
    marginBottom: '2rem',
    extend: [ borderBottom('2px', 1, 'solid', (isLabel ? theme.color('commercial') : theme.color('zoidbergList', 'title'))), ],
    ...(isLabel ? {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '1rem',
      paddingInlineStart: '1rem',
    } : {}),
  });

  const stdItemsLength: number = items && items.length;

  if (!stdItemsLength) {
    console.log(
      `List ${list.contentId} tried to render the Zoidberg view, but has no items.
      This is a client-side only list. Is it being rendered on the server?`
    );
    return null;
  }
  return (
    <div className={wrapperClassName} data-test={`zoidberg${isLabel ? 'Label' : ''}`}>
      {isLabel ? (
        <H className={hClassName}>
          <div>{theme.labelsI18n.promotedLabelsContent}</div>
          <div className={css({
            fontFamily: theme.fontStacks.commercial,
            color: theme.color('neutral', '-1'),
            fontWeight: 400,
            paddingEnd: '1rem',
            extend: [
              theme.type(-1),
              theme.mq({ until: 's', }, { paddingTop: '1.5rem', height: '100%', }),
              theme.mq({ from: 's', until: 'l', }, { paddingTop: '0.5rem', }),
              theme.mq({ from: 'l', }, { paddingTop: '0.7rem', }),
            ],
          })}
          >
            {theme.labelsI18n.advertorialContent}
          </div>
        </H>
      )
        : <H className={hClassName}>{title}</H>
      }
      <Section>
        {items.map((item, index) => (
          <ListItem key={item.contentId}>
            {isTeaser(item) ? (
              <Item
                data={item}
                index={index}
                biAction={biAction}
                listLength={stdItemsLength}
                isPersonal={isPersonal}
              />
            ) : null
            }
          </ListItem>
        ))}
        {clickTrackers && clickTrackers.length > 0
          ? clickTrackers.map((item, index) => (
            <ListItem key={item.contentId}>
              {isClickTrackerWrapper(item) ? (
                <ClickTrackerItem item={item} index={index} biAction={biAction} />
              ) : null}
            </ListItem>
          ))
          : null}
        {adSlots && adSlots?.length > 0
          ? adSlots.map(item => (
            <ListItem key={item.contentId}>
              {isAdSlot(item) ? (
                <AdSlot
                  wrapperMiscStyles={{
                    ...listItemStyle,
                    marginTop: '1rem',
                    ...(item.miscStyles || {}),
                  }}
                  {...item}
                />
              ) : null}
            </ListItem>
          ))
          : null}
      </Section>
    </div>
  );
}

export default Zoidberg;
