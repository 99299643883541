// @flow
import React, { useRef, useEffect, } from 'react';
import { FelaComponent, } from 'react-fela';
import * as d3 from 'd3';

import type { Node, } from 'react';
import type { Asset, } from '../../Graph/graphs/Line/Line';

const xAxisTickFormats = new Map([
  [ 'day', [ '%H:%M', d3.timeHour.every, 1, ], ],
  [ 'week', [ '%a', d3.timeDay.every, 1, ], ],
  [ 'month', [ '%d/%m', d3.timeDay.every, 5, ], ],
  [ 'year1', [ '%b', d3.timeMonth.every, 2, ], ],
  [ 'year3', [ '%b %y', d3.timeMonth.every, 6, ], ],
  [ 'year5', [ '%Y', d3.timeYear.every, 1, ], ],
]);

type Props = {
  data: Array<Asset>,
  theme: Object,
  time: string,
}

function Line({ data, time, theme, }: Props): Node {
  const width = 320;
  const height = 303;
  const margin = { top: 34, right: 10, bottom: 15, left: 50, };

  const svgRef = useRef(null);

  useEffect(
    () => {
      /* Extract graph's start & end points from the data */

      const xExtent = d3.extent(data, stock => stock.time);
      const [ yMin, yMax, ] = d3.extent(data, stock => stock.value);

      /* Calculate x & y inner padding */
      const yPadding = (yMax - yMin) / 10;

      const yScale = d3.scaleLinear()
        .range([ height - margin.bottom, margin.top, ])
        .domain([ yMin - yPadding, yMax + yPadding, ]);
      const xScale = d3.scaleTime()
        .range([ margin.left, width - margin.right, ])
        .domain(xExtent);

      const svg = d3.select(svgRef.current);

      svg.append('rect')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('fill', theme.color('neutral', '-1'));

      svg.append('g')
        .selectAll('line')
        .data(data)
        .enter()
        .append('line')
        .attr('stroke', theme.color('sales'))
        .attr('stroke-width', 1)
        .attr('x1', d => xScale(d.time))
        .attr('y1', d => yScale(d.value))
        .attr('x2', (d, i) => (data[i + 1] ? xScale(data[i + 1].time) : xScale(d.time)))
        .attr('y2', (d, i) => (data[i + 1] ? yScale(data[i + 1].value) : yScale(d.value)));

      // $FlowFixMe
      const [ timeFormat, timeFunction, every, ] = xAxisTickFormats.get(time);

      const xAxisRef = svg.append('g')
        .attr('transform', `translate(0, ${margin.top})`)
        .call(
          d3.axisTop()
            .scale(xScale)
            .tickFormat(d3.timeFormat(timeFormat))
            .ticks(timeFunction(every))
        );

      const yAxisRef = svg.append('g')
        .attr('transform', `translate(${margin.left}, 0)`)
        .call(d3.axisLeft().scale(yScale).ticks(5));

      xAxisRef.selectAll('.tick line').remove();
      xAxisRef.select('.domain').remove();

      yAxisRef.select('.domain').remove();

      /* Select all vertical axis ticks. */
      yAxisRef.selectAll('.tick line')
        .attr('stroke', '#505050')
        .attr('stroke-width', 0.5)
        .attr('x1', 0)
        .attr('x2', width - margin.left)
        .attr('opacity', 1);

      svg.selectAll('text')
        .attr('fill', theme.color('neutral', '-4'))
        .attr('style', `font-size: 12px; font-family: ${theme.fontStacks.base}; line-height: 18px;`);
    },
    [ data, margin.bottom, margin.left, margin.right, margin.top, theme, time, ]
  );

  return (
    <FelaComponent
      style={{
        backgroundColor: theme.color('neutral', '-1'),
      }}
    >
      {({ className, }) => (
        <svg
          ref={svgRef}
          className={className}
          viewBox={`0 0 ${width} ${height}`}
          width="100%"
          direction="ltr"
        />
      )}
    </FelaComponent>
  );
}

export default Line;
