/* global window document history */

import useMedia from './useMedia';
import { useUser, } from '../components/User/UserDispenser';
import useOneTime from './useOneTime';

function isInjectSectionServerSide(enabled, sectionUrl) {
  return !!enabled && !!sectionUrl;
}

function getBackToSectionCounter() {
  return JSON.parse(window.localStorage.getItem('backtoSectionCount')) || 0;
}

function incrementBackToSectionCounter() {
  let backToSectionCounter = JSON.parse(window.localStorage.getItem('backtoSectionCount'));
  if (!backToSectionCounter) {
    backToSectionCounter = 1;
  }
  else {
    backToSectionCounter += 1;
  }
  window.localStorage.setItem('backtoSectionCount', backToSectionCounter.toString());
}

function injectSectionClientSite(isMobile, userType, clientUserType, numberOfVisits) {
  // referrer test: pass only if referrer is google or facebook, or query string includes utm_source=mailchimp, indicating a newsletter traffic source.
  const referrer = document.referrer;
  const passReferrer = document.location.search.includes('utm_source=mailchimp') || (!!referrer && (referrer.includes('google.') || referrer.includes('facebook.')));
  if (!passReferrer) {
    return false;
  }

  // mobile test: pass only if user is a mobile client
  const passMobile = isMobile;
  if (!passMobile) {
    return false;
  }

  // user test: pass only if user type is one of the types defined in the setting
  const passUser = !!clientUserType && !!userType && userType.includes(clientUserType);
  if (!passUser) {
    return false;
  }

  // frequency test: pass only if number of visits is divided by the frequency setting number with no remainder
  const frequency = JSON.parse(numberOfVisits);
  const count = getBackToSectionCounter();
  const passFrequency = count && count > 0 && frequency && frequency > 0 && count % frequency === 0;
  if (passFrequency) {
    window.localStorage.setItem('backtoSectionCount', '0');
  }
  else {
    return false;
  }
  return true;
}

export default function useBackToSection(
  {
    enabled,
    sectionUrl,
    numberOfVisits,
    userType, }
) {
  const isMobile = useMedia({ query: { until: 's', }, });
  const userData = useUser();
  const clientUserType = userData.user.type || '';

  useOneTime(!!clientUserType && isMobile,
    () => {
      const injectSectionServerSide = isInjectSectionServerSide(enabled, sectionUrl);
      if (injectSectionServerSide) { // server side checks results - is enabled by server
        setTimeout((() => {
          incrementBackToSectionCounter();
          if (injectSectionClientSite(
            isMobile,
            userType,
            clientUserType,
            numberOfVisits
          )
          ) { // clident side checks
            window.history.replaceState(null, null, `${document.location.pathname}${document.location.search}#!/backToSection`);
            window.history.pushState(null, null, `${document.location.pathname}${document.location.search}`);
            window.addEventListener('popstate', () => {
              if (document.location.hash === '#!/backToSection') {
                window.history.replaceState(null, null, `${document.location.pathname}${document.location.search}`);
                setTimeout(() => {
                  document.location.replace(`${sectionUrl}?htm_source=site&htm_medium=link&htm_campaign=back_from_google&htm_content=article_page`);
                }, 0);
              }
            }, false);
          }
        }), 1000);
      }
    }
  );
}
