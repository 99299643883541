/* global fetch */
import React from 'react';
import {
  getOmnyEventsFromStorage,
  writeOmnyEventsToStorage,
  removeOmnyEventsFromStorage,
  postOmnySession,
} from './omnyUtils';

function OmnyDataCheck() {
  React.useEffect(() => {
    const allEvents = getOmnyEventsFromStorage();
    const sessionIds = Object.keys(allEvents);
    if (sessionIds.length <= 0) removeOmnyEventsFromStorage();
    else {
      sessionIds.forEach(sessionId => {
        postOmnySession(allEvents[sessionId])
          .then(() => {
            const currentAllEvents = getOmnyEventsFromStorage();
            delete currentAllEvents[sessionId];
            writeOmnyEventsToStorage(currentAllEvents);
          })
          .catch(err => {
            console.error(err);
          });
      });
    }
  }, []);

  return null;
}

export default OmnyDataCheck;
