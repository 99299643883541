// @flow
import * as React from 'react';

import { useFela, } from 'react-fela';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import ClickTrackerInfoFooter from '../../../ClickTracker/ClickTrackerInfoFooter';
import Image from '../../../Image/Image';
import useDarkModeChecker from '../../../../hooks/useDarkModeChecker';

import getImageAssets from '../../../../utils/getImageAssets';

type Props = {
  itemData: ClickTrackerBannerType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  gridArea: string,
  theme: Object,
};

const areasTemplate = `
"media"
"content"
"promoted"
`;

MichelleTeaserPromoted.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
  itemData: null,
};

const imageAssetsOptions = theme => ({
  bps: theme.bps,
  aspect: 'headline',
  sizes: [
    { from: 'xl', size: '277px', },
    { from: 'l', size: '206px', },
    { from: 'm', size: '232px', },
    { size: '184px', },
  ],
  widths: [ 368, 277, 232, 206, 184, ],
});


export default function MichelleTeaserPromoted({
  itemData,
  gridArea,
  isLazyloadImages,
  biAction,
  theme,
}: Props): React.Node {
  const isDarkMode = useDarkModeChecker();

  if (!itemData) return null;

  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={areasTemplate}
      rowTemplate="auto 1fr auto"
      gridGap="1rem"
      miscStyles={{
        backgroundColor: isDarkMode ? 'transparent' : '#f6f6f6',
        border: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
        fontFamily: theme.fontStacks.commercial,
      }}
    >
      <TeaserMedia data={itemData} isStacked onClick={biAction}>
        <Image
          image={itemData && itemData.clicktrackerimage}
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets(imageAssetsOptions(theme))}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        title={itemData.text || ''}
        path={itemData.link}
        {...itemData}
        // color={[ 'neutral', '-1', ]}
        onClick={biAction}
        wrapperMiscStyles={{
          paddingStart: '1rem',
          paddingEnd: '1rem',
        }}
      />
      <ClickTrackerInfoFooter companyName={itemData.advertiser || ''} />
    </Teaser>
  );
}
