// @flow
import React from 'react';
import dynamic from 'next/dynamic';
import Debug from '../Debug/Debug';

// Infographics
const VisualInfo = dynamic(
  () => import('./VisualInfo'),
  { loading: () => null, }
);

const Questions = dynamic(
  () => import('./Questions'),
  { loading: () => null, }
);

const Columns = dynamic(
  () => import('./Columns'),
  { loading: () => null, }
);

const Timeline = dynamic(
  () => import('./Timeline'),
  { loading: () => null, }
);

const Ids = dynamic(
  () => import('./Ids'),
  { loading: () => null, }
);

const Chat = dynamic(
  () => import('./Chat'),
  { loading: () => null, }
);

const InfographicList = dynamic(
  () => import('./InfographicList'),
  { loading: () => null, }
);

function Default({ inputTemplate, ...props }: {inputTemplate: string, }) {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(`
      Element of type: '${inputTemplate}' is not supported
      in this page and we don't have any component for it yet.
    `);
  }

  console.log(`!!!!Not supported infographic element: ${inputTemplate}`, { inputTemplate, props, });

  return (
    <Debug>
      <p>{`Infographic ${inputTemplate} is currently not supported in this page`}</p>
    </Debug>
  );
}
/* eslint-enable react/prop-types */


const infographicsMap = new Map([
  [ 'VisualInfo', VisualInfo, ],
  [ 'QAndA', Questions, ],
  [ 'ColumnsModel', Columns, ],
  [ 'Timeline', Timeline, ],
  [ 'Ids', Ids, ],
  [ 'ListInfo', InfographicList, ],
  [ 'Chat', Chat, ],
]);

type InfographicProps = {
  inputTemplate?: string;
  item: {
    inputTemplate: string;
  };
}

InfographicRichText.defaultProps = {
  inputTemplate: null,
};

export default function InfographicRichText({ item, ...props }: InfographicProps) {
  const content = item && typeof item === 'object' ? item : props;
  const Element = infographicsMap.get(content?.inputTemplate) || Default;

  return <Element {...content} />;
}
