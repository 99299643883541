// @flow
import * as React from 'react';
import dynamic from 'next/dynamic';
import { useRouter, } from 'next/router';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import type { ImageDataType, } from '../../../flowTypes/ImageDataType.js';
import isQueryValueTrue from '../../../utils/isQueryValueTrue';


export type MarketingMultiTypeProps = {
  contentId: string,
  type: string,

  miscStyles: StyleProps,

  metaData?: Object,
  abTestData?: Object,

  featureName: string,
  featureType: string,
  campaignName: string,
  campaignDetails: string,
  declineBehavior: string,

  textLine1: string,
  textLine2: string,

  mobileTextLine1: string,
  mobileTextLine2: string,

  htmlContent: Array<Object>,
  mobileHtmlContent: Array<Object>,

  actionName: string,
  actionType: string,
  actionUrl: string,
  actionText: string,
  actionCodeNumber: number,

  mobileActionName: string,
  mobileActionType: string,
  mobileActionUrl: string,
  mobileActionText: string,
  mobileActionCodeNumber: number,

  declineUrl: string,
  declineText: string,
  declineCodeNumber: number,

  mobileDeclineUrl: string,
  mobileDeclineText: string,
  mobileDeclineCodeNumber: number,

  image: ImageDataType,
  mobileImage: ImageDataType,
};

const type2Element = {
  TopStrip: dynamic(() => import('./TopStrip'), { loading: () => null, ssr: false, }),
  NarrowBottomStrip: dynamic(() => import('./NarrowBottomStrip/NarrowBottomStrip'), { loading: () => null, ssr: false, }),
  BottomStrip: dynamic(() => import('./BottomStrip/BottomStripNew'), { loading: () => null, ssr: false, }),
  DebtPopup: dynamic(() => import('./DebtPopup/DebtPopup'), { loading: () => null, ssr: false, }),
  SubscriptionEndPopup: dynamic(() => import('./SubscriptionEndPopup/SubscriptionEndPopup'), { loading: () => null, ssr: false, }),
  ScreenOverlay: dynamic(() => import('./PaywallOverlayScreen/PaywallOverlayScreen'), { loading: () => null, ssr: false, }),
  MidPage: dynamic(() => import('./Midpage/Midpage'), { loading: () => null, ssr: false, }),
  MiniReg: dynamic(() => import('./MiniReg/MiniReg'), { loading: () => null, ssr: false, }),
  EmailValidation: dynamic(() => import('./RequestEmailValidation/RequestEmailValidation'), { loading: () => null, ssr: false, }),
  ListBlock: dynamic(() => import('./ListBlock/ListBlock'), { loading: () => null, ssr: false, }),
  AppPromotionStrip: dynamic(() => import('./AppPromotionStrip/AppPromotionStrip.js'), { loading: () => null, ssr: false, }),
};
function Noop() {
  return null;
}

MarketingMultiTypeElement.defaultProps = {
  metaData: null,
  abTestData: null,
};

export default function MarketingMultiTypeElement(props: MarketingMultiTypeProps) {
  const { type, } = props;
  const Element = type2Element[type] || Noop;
  const router = useRouter();
  const isGooglebot = isQueryValueTrue(router.query.isBot);

  if (isGooglebot) {
    return null;
  }

  return (<Element {...props} />);
}
