// @flow
import React from 'react';
import { ZOIDBERG_LIST_QUERY, } from '@haaretz/graphql';

import ZoidbergLabelsView from './ZoidbergLabelsView';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  updateListDuplication: Function,
  updatePersonalList: Function,
  variables: {
    offset: number,
  },
  articleId: string,
  isPersonal: boolean,
  listData: ListDataType,
  isLabel: boolean,
};

ZoidbergLabels.defaultProps = {
  isLabel: true,
};


export default function ZoidbergLabels(props: Props) {
  return (
    <ListWrapper
      {...props}
      query={ZOIDBERG_LIST_QUERY}
      view="ZoidbergLabels"
    >
      {dataProps => <ZoidbergLabelsView {...dataProps} />}
    </ListWrapper>
  );
}
