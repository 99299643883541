// @flow
import React from 'react';
import { FelaComponent, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, ChildrenArray, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';

type ColorBaseType = string | string[];

type Props = {
  children: ChildrenArray<Node> | Node,
  miscStyles?: ?StyleProps,
  bgc?: ColorBaseType,
  color?: ColorBaseType,
};

function NoData({ miscStyles, children, bgc, color, }: Props): Node {
  return (
    <FelaComponent
      style={({ theme, }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Array.isArray(bgc)
          ? theme.color(bgc[0], bgc[1] ? bgc[1] : 'base')
          : theme.color(bgc, 'base'),
        color: Array.isArray(color)
          ? theme.color(color[0], color[1] ? color[1] : 'base')
          : theme.color(color, 'base'),
        extend: [
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      {children}
    </FelaComponent>
  );
}

NoData.defaultProps = {
  miscStyles: null,
  bgc: [ 'neutral', '-10', ],
  color: [ 'neutral', '-1', ],
};

export default NoData;
