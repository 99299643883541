import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseTypographyProp, } from '@haaretz/htz-css-tools';
import HtzLink from '../HtzLink/HtzLink';


const articleStyle = ({ theme, isBlock, }) => (isBlock
  ? {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomWidthActive: '2px',
    borderBottomColor: theme.color('articleLink', 'borderBlockLink'),
    color: theme.color('articleLink', 'colorBlockLink'),
    fontWeight: '700',
    ':hover': {
      color: theme.color('articleLink', 'hoverBlockLink'),
    },
    extend: [
      parseTypographyProp(theme.articleStyle.articleLink.typeScale, theme.type),
    ],
  }
  : {
    marginBottom: '2rem',
    fontWeight: '700',
    position: 'relative',
    zIndex: '1',
    color: theme.color('articleLink', 'color'),
    transitionProperty: 'background-position',
    backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 36%, ${theme.color(
      'articleLink',
      'bgImage'
    )}, ${theme.color('articleLink', 'bgImageGradient')} 50%, ${theme.color(
      'articleLink',
      'bgImageGradient'
    )} )`,
    backgroundSize: '100% 200%',
    backgroundPositionY: '2px',
    backgroundPositionX: '0',
    paddingBlockEnd: '0.5rem',
    ':hover': {
      ...theme.getTimingFunction('transition', 'swiftOut'),
      backgroundPosition: '0 100%',
      color: theme.color('articleLink', 'hoverColor'),
    },
    ':focus': {
      background: 'none',
      backgroundColor: theme.color('quaternary'),
    },
    extend: [
      parseTypographyProp(theme.articleStyle.articleLink.typeScale, theme.type),
      theme.getDuration('transition', -1),
      theme.getTimingFunction('transition', 'swiftIn'),
    ],
  });

const authorStyle = ({ theme, }) => ({
  fontWeight: '300',
  marginStart: '0.5rem',
  color: theme.color('neutral', '-2'),
});


const labelArticleStyle = ({ theme, }) => ({
  color: theme.color('neutral', 'base'),
  backgroundColor: theme.color('neutral', '-6'),
  marginBottom: '2rem',
  fontWeight: '700',
  position: 'relative',
  zIndex: '1',
  padding: '0.3rem',
  transitionProperty: 'background-color',
  transitionDuration: '0.3s',
  ':hover': {
    backgroundColor: theme.color('neutral', '-5'),
  },
});


ArticleLink.propTypes = {
  /** An object containing the article's properties */
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          contentName: PropTypes.string.isRequired,
        }),
      ])
    ),
    path: PropTypes.string.isRequired,
  }).isRequired,

  /** Is the currently viewed article */
  currentArticle: PropTypes.bool,
  /** Is this the focused item. Used mainly for a11y */
  focus: PropTypes.bool,
  /** Is this link part of an inline links-block */
  isBlock: PropTypes.bool,

  isLabel: PropTypes.bool,
};

ArticleLink.defaultProps = {
  currentArticle: false,
  focus: false,
  isBlock: false,
  isLabel: false,
};

const currentArticleStyle = ({ theme, }) => ({
  fontWeight: '700',
  backgroundColor: theme.color('articleLink', 'currentArticleBg'),
  color: theme.color('articleLink', 'currentArticleText'),
  extend: [
    parseTypographyProp(theme.articleStyle.articleLink.typeScale, theme.type),
  ],
});

const labelRelatedArticleTitleStyle = ({ theme, }) => ({
  fontWeight: '200',
  color: theme.color('labels', 'relatedArticleTitle'),
  ':after': {
    content: '" | "',
  },
});

function ArticleLink({ article, currentArticle, focus, isBlock, isLabel, }) {
  const { css, theme, } = useFela({ isBlock, isLabel, });

  return currentArticle ? (
    <span className={css(currentArticleStyle)}>
      {article.title}
      {isBlock && article.authors ? (
        <span className={css(authorStyle)}>
          {article.authors[0].contentName || article.authors[0]}
        </span>
      ) : null}
    </span>
  ) : (
    <HtzLink
      attrs={{ 'data-test': 'articleLink', }}
      href={article.path}
      content={(
        <span className={css(isLabel ? labelArticleStyle : articleStyle)}>
          {isLabel ? (
            <span className={css(labelRelatedArticleTitleStyle)}>
              {theme.labelsI18n.promotedContent}
            </span>
          ) : null}
          {article.title}
          {isBlock && article.authors ? (
            <span className={css(authorStyle)}>
              {article.authors[0].contentName || article.authors[0]}
            </span>
          ) : null}
        </span>
)}
      focus={focus}
    />
  );
}

export default ArticleLink;
