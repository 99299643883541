// @flow
import * as React from 'react';
import { CLAMPAZZO_LIST_QUERY, } from '@haaretz/graphql';
import ClampazzoView from './ClampazzoView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper.js';


type Props = {
  updateListDuplication: Function,
  variables: {},
  listData: ListDataType,
};

export default function Clampazzo(props: Props): React.Node {
  return (
    <ListWrapper query={CLAMPAZZO_LIST_QUERY} view="Clampazzo" {...props}>
      {dataProps => <ClampazzoView {...dataProps} />}
    </ListWrapper>
  );
}
