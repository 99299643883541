// @flow
import React from 'react';
import dynamic from 'next/dynamic';
import { FelaComponent, FelaTheme, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, ComponentType, } from 'react';

import Query from '../ApolloBoundary/Query';
import GraphQuery from './graph.graphql';
import NoData from '../NoData/NoData';
import Loading from '../Loading/Loading';

type Props = {
  indexId?: ?(number | string),
  time: 'day' | 'week' | 'month' | 'year1' | 'year3' | 'year5',
  type: 'line' | 'scatter',
  changeStats: Function,
  data: ?any,
  loadingHeight?: ?number,
};

type GraphProps = Props & {
  miscStyles?: ?Object,
};

const graphTypes: Object = new Map([
  [
    'line',
    dynamic(import('./graphs/Line/Line'), {
      loading: Loading,
      ssr: false,
    }),
  ],
  [
    'scatter',
    dynamic(import('./graphs/Scatter/Scatter'), {
      loading: Loading,
      ssr: false,
    }),
  ],
]);

const GraphWithData = ({ indexId, time, type, miscStyles, data, ...props }: GraphProps): Node => {
  const GraphElement: ComponentType<any> = graphTypes.get(type);
  return (
    <FelaTheme>
      {theme => (
        <FelaComponent
          style={{
            extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
          }}
        >
          <GraphElement time={time} theme={theme} data={data || null} {...props} />
        </FelaComponent>
      )}
    </FelaTheme>
  );
};

GraphWithData.defaultProps = {
  time: null,
  miscStyles: null,
};

Graph.defaultProps = {
  data: null,
  indexId: null,
  loadingHeight: null,
};

export default function Graph(props: Props) {
  if (!props.data) {
    const { indexId, type, time, loadingHeight, } = props;
    return (
      <Query
        query={GraphQuery}
        variables={{
          assetId: indexId,
          type,
          period: time,
        }}
      >
        {({ loading, error, data, }) => {
          if (error) return null;
          if (loading) {
            return <Loading minHeight={loadingHeight ? `${loadingHeight - 91}px` : '290px'} />;
          }
          if (data && data.graph && data.graph.dataSource.length > 1) {
            data.graph.dataSource.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.time = new Date(item.time).getTime();
            });
            return <GraphWithData {...props} data={data.graph.dataSource} />;
          }
          return (
            <NoData
              bgc={[ 'neutral', '-1', ]}
              color={[ 'neutral', '-10', ]}
              miscStyles={{
                minHeight: loadingHeight ? `${loadingHeight}px` : '310px',
              }}
            >
              אין נתונים להצגה
            </NoData>
          );
        }}
      </Query>
    );
  }
  return <GraphWithData {...props} data={props.data} />;
}
