// @flow
import React from 'react';

type Props = {
  ogImageUrl: string,
  metaTitle: string,
  twitterTitle: string,
  metaDescription: string,
  ogDescription: string,
  site: 'haaretz.co.il' | 'themarker.com' | 'haaretz.com',
};

const twitterHandler = {
  'haaretz.co.il': '@haaretz',
  'themarker.com': '@TheMarker',
  'haaretz.com': '@haaretzcom',
};

export default function TwitterMeta({
  ogImageUrl,
  metaTitle,
  twitterTitle,
  ogDescription,
  metaDescription,
  site, }: Props) {
  return (
    [
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />,
      <meta name="twitter:site" content={twitterHandler[site]} key="twitter:site" />,
      <meta name="twitter:title" content={twitterTitle || metaTitle} key="twitter:title" />,
      <meta name="twitter:description" content={ogDescription} key="twitter:description" />,
      <meta name="twitter:image" content={ogImageUrl} key="twitter:image" />,
    ]
  );
}
