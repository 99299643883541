// @flow

import React from 'react';
import { useFela, } from 'react-fela';
import ListView from '../ListView/NewListView';
import Image from '../Image/Image';
import getImageAssets from '../../utils/getImageAssets';
import Teaser from '../Teaser/NewTeaser';
import TeaserMedia from '../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../TeaserHeader/TeaserHeader';
import TeaserFooter from '../TeaserFooter/TeaserFooter';
import Button from '../Button/Button';
import { useEventTracker, } from '../../utils/EventTracker';

import type { TeaserDataType, } from '../../flowTypes/TeaserDataType';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';

type Props = {
  contentName: string,
  articles: TeaserDataType[],
  buttonText: ?string,
};

const listRowGap = [ { until: 's', value: '2rem', }, { from: 's', value: '3rem', }, ];

const recipeAreasTemplate = `
"media media media"
". content ."
". footer ."
`;

const RECIPE_COUNT_STEP = 10;

function MagazineRecipes({
  contentName: recipeType,
  articles: recipes = [],
  buttonText,
}: Props) {
  const [ maxRecipeCount, setMaxRecipeCount, ] = React.useState(RECIPE_COUNT_STEP);
  const [ firstNewRecipeIdx, setFirstNewRecipeIdx, ] = React.useState(-1);
  const firstNewRecipeRef = React.useRef(null);
  const { theme, } = useFela();
  const { biAction, } = useEventTracker();
  const lineage = useBreadcrumbsData();

  // Move focus to the first newly displayed teaser after using the "more" button
  React.useEffect(() => {
    const recipeEl = firstNewRecipeRef.current;
    if (!recipeEl) {
      return undefined;
    }
    recipeEl.tabIndex = -1;
    recipeEl.focus();
    const removeTabIndex = () => {
      recipeEl.removeAttribute('tabindex');
      recipeEl.removeEventListener('blur', removeTabIndex);
    };
    recipeEl.addEventListener('blur', removeTabIndex);

    return removeTabIndex;
  }, [ firstNewRecipeIdx, ]);

  const recipesToDisplay = recipes.slice(0, maxRecipeCount);
  const nextRecipesCount = recipes.length - recipesToDisplay.length;
  const hasMoreRecipes = nextRecipesCount > 0;

  const moreRecipesText = hasMoreRecipes
    ? (buttonText || theme.magazineRecipesI18n.more(nextRecipesCount, recipeType))
    : '';
  const renderMoreRecipes = () => {
    setFirstNewRecipeIdx(maxRecipeCount);
    setMaxRecipeCount(recipes.length);

    const subSection = (lineage || []).find(({ pathSegment, }) => Boolean(pathSegment));
    const campaignName = subSection?.pathSegment || 'n/a';
    biAction({
      actionCode: 169,
      featureType: 'Content',
      feature: 'Load more',
      campaignName,
      campaignDetails: moreRecipesText,
    });
  };

  return (
    <ListView
      outerBackgroundColor="transparent"
      padding="0"
      colGap="4rem"
      rowGap={listRowGap}
      colTemplate={[ { from: 's', value: '1fr 1fr', }, ]}
      miscStyles={{ marginBottom: listRowGap, }}
    >
      {recipesToDisplay.map((data, idx) => (
        <Teaser
          key={data.contentId}
          fwRef={idx === firstNewRecipeIdx ? firstNewRecipeRef : null}
          data={data}
          areasTemplate={recipeAreasTemplate}
          colTemplate="0 1fr 0"
          rowTemplate="auto auto 1fr"
          colGap={[ { until: 's', value: '2rem', }, ]}
          backgroundColor={[ 'bg', 'base', ]}
          miscStyles={{
            boxShadow: [ { until: 's', value: theme.cardStyle.cardElevatedBoxShadow, }, ],
            ':focus': { outline: 'none', },
          }}
        >
          <TeaserMedia data={data}>
            <Image
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'headline',
                sizes: [
                  { from: 'xl', size: '602px', },
                  { from: 'l', size: '477px', },
                  { from: 'm', size: '318px', },
                  { from: 's', size: '276px', },
                  { size: 'calc(100vw - 24px)', },
                ],
                widths: [ 602, 560, 477, 390, 351, 318, 296, ],
              })}
              image={data.image}
            />
          </TeaserMedia>
          <TeaserHeader
            {...data}
            isGridItem
            wrapperMiscStyles={{ paddingTop: '1rem', }}
          />
          <TeaserFooter
            showAuthor
            showTime={false}
            showCommentsCount={false}
            typeScale={-2}
            miscStyles={{ paddingBottom: [ { until: 's', value: '1rem', }, ], }}
            data={data}
          />
        </Teaser>
      ))}
      {hasMoreRecipes ? (
        <Button
          onClick={renderMoreRecipes}
          miscStyles={{
            backgroundColor: theme.color('bg', 'base'),
            gridColumn: [ { from: 's', value: '1/3', }, ],
            justifySelf: 'center',
            marginTop: '2rem',
          }}
        >
          {moreRecipesText}
        </Button>
      ) : null}
    </ListView>
  );
}

export default MagazineRecipes;
