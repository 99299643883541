import React from 'react';

import { Article as ArticleComponent, } from '@haaretz/htz-components';

import getElements from '../utils/getArticlePageElementsBS';


export default function Article(props) {
  return (
    <ArticleComponent
      {...props}
      site="htz"
      getElements={getElements}
    />
  );
}
