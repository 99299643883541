// @flow
import * as React from 'react';
import HtzLink from '../../../HtzLink/HtzLink';
import H from '../../../AutoLevels/H';

type Props = {
  data: {
    contentLists: { title: string, url: string, }[],
    contentName: string,
  },
  theme: Object,
  css: (Object | (Object => Object)) => string,
};

const wrapperStyle = ({ theme, }) => ({
  fontFamily: theme.topNewsStyle.fontFamily,
  gridArea: 'topNews',
  display: 'flex',
  alignItems: 'flex-end',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...theme.type(-1),
  ...theme.mq({ from: 'xl', }, theme.type(-2)),
  ...theme.mq({ until: 'l', }, { display: 'none', }),
});

const titleStyle = ({ theme, }) => ({
  color: theme.color('tertiary'),
  display: 'inline',
  fontWeight: '700',
});

const itemStyle = isLast => ({ theme, }) => ({
  paddingStart: '1rem',
  paddingEnd: '1rem',
  position: 'relative',
  color: theme.color(...(isLast ? [ 'topNews', 'lastItem', ] : [ 'topNews', 'item', ])),
  ':not(:last-of-type):after': {
    content: '""',
    position: 'absolute',
    height: '1em',
    borderEnd: '1px solid currentcolor',
    top: '50%',
    end: '0',
    transform: 'translateY(-50%) scaleX(1.5)',
  },
  ...theme.mq(
    { until: 'xl', },
    {
      ':nth-of-type(5)': { color: theme.color('primary', '+1'), },
      ':nth-of-type(5):after': { display: 'none', },
      ':nth-of-type(1n + 6)': { display: 'none', },
    }
  ),
});

export default function TopNewsItems({ data: { contentLists, contentName, }, css, theme, }: Props) {
  const items = contentLists.slice(0, 7);
  if (!items.length) return null;
  const hoverFocusStyle = {
    textDecoration: 'underline',
    textDecorationColor: theme.color('primary', '-2'),
  };
  const linkClassName = css({
    fontWeight: '700',
    '&:hover': hoverFocusStyle,
    '&:focus': hoverFocusStyle,
  });

  return (
    <div className={css(wrapperStyle)}>
      <H className={css(titleStyle)} offset={1}>
        {theme.topNewsStyle.href
          ? <HtzLink href={theme.topNewsStyle.href}>{contentName}</HtzLink>
          : contentName
            }
        {theme.topNewsStyle.contentNameSuffix}
      </H>
      <ul className={css({ display: 'flex', })}>
        {items.map((item, idx) => {
          const isLast = idx === items.length - 1;
          return (
            <li key={item.title} className={css(itemStyle(isLast))}>
              <HtzLink href={item.url} className={linkClassName}>
                {item.title}
              </HtzLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
