/* global fetch window sessionStorage document */
// @flow
import * as React from 'react';
import config from 'config';

import type { Node, } from 'react';
import { CookieUtils, UserService, } from '@haaretz/htz-user-utils/src';

import { useUser, } from '../User/UserDispenser';
import pixelEvent from '../../utils/pixelEvent';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useOneTime from '../../hooks/useOneTime';

type Props = {
  articleId: string,
  site: string,
};

const userStatsUrl = config.get('service.userStats');

export default function UserCookieValidation({ articleId, site, }: Props): Node {
  const isWebView = useWebViewChecker();
  const { user, } = useUser();
  const [ successState, setSuccessState, ] = React.useState(false);

  React.useEffect(() => {
    // Install Facebook Pixel (Anonymous who read more than six articles)
    if (!CookieUtils.getCookie('user_details') && CookieUtils.getCookie('ra') > 6) {
      pixelEvent('track', 'Donate');
    }
  }, []);

  const isSignedIn = user && typeof user.type === 'string' && user.type !== 'anonymous';
  const isUniversity = user && user.university === true;

  const shouldSendRequest = !isWebView && isSignedIn && (!isUniversity || successState === true);

  useOneTime(isUniversity && !!CookieUtils.getCookie('acl'), async () => {
    const userService = new UserService();

    const success = await userService.fetchAccessListByIp();
    if (success === true) {
      setSuccessState(true);
    }
    else {
      // handle logout
      console.error('AccessListByIp failed, logging out');

      const proxyDomain = getBaseHostname(window.location.hostname);

      const searchParams = new URLSearchParams({
        proxyDomain: `.${encodeURIComponent(proxyDomain)}`,
      });

      userService.logout({ user, searchParams, }).then(() => {
        sessionStorage.removeItem('userProduct');
        document.location.reload();
      }).catch(err => console.log('error logging out !!!!!!', err));
    }
  });

  useOneTime(shouldSendRequest, async () => {
    fetch(
      `${userStatsUrl}/getUserData?site=${site}&articleId=${articleId}&isPaying=${user.type === 'paying'}&isUniversity=${user.university === true}`,
      { credentials: 'include', }
    )
      .then(async res => {
        const body = await res.text();
        return { statusCode: res.status, body, };
      })
      .then(({ statusCode, body, }) => {
        if (statusCode >= 400) {
          console.error(body);
        }
      })
      .catch(err => console.error('[setHtzRUser] error %o', err));

    if (user.university !== true && user.type === 'paying') {
      fetch(
        `${userStatsUrl}/getProductsCookie?site=${site}`,
        { credentials: 'include', }
      ).catch(err => console.error('[setProductsCookie] error %o', err));
    }
  });

  return null;
}

/**
 * Gets the base hostname
 * @param {string} hostname - The full hostname
 * @return {string} - The base hostname
 */
function getBaseHostname(hostname) {
  const parts = hostname.split('.');
  const removedSubdomain = parts.shift();

  if (removedSubdomain === 'haaretz' || removedSubdomain === 'themarker') {
    return hostname;
  }

  const newHostname = parts.join('.');

  return newHostname;
}
