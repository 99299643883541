// @flow
import * as React from 'react';
import useRainbowToolsStats from './utils/useRainbowToolsStats';
import { useUser, } from '../User/UserDispenser';

type PropsType = {
  elementId: string,
  testGroup?: string,
  children: React.ChildrenArray<React.Element<any>> | null,
};


export default function RainbowLogger({ elementId, testGroup, children, }: PropsType) {
  const user = useUser();
  const marketingToolsStats = useRainbowToolsStats(user);

  // log exposure date of marketing-element on render.
  React.useEffect(() => {
    marketingToolsStats.log(elementId, testGroup);
  }, [ elementId, marketingToolsStats, testGroup, ]);

  return children;
}
