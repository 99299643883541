/* global document  */
import React from 'react';
import { useApolloClient, useLazyQuery, } from 'react-apollo';
import { GET_USER_NICKNAME_QUERY, } from '@haaretz/graphql';

function WriteUserNickNameToClient({ userNickName, }) {
  const client = useApolloClient();

  React.useEffect(() => {
    client.writeData({
      data: {
        userNickName,
      },
    });
  }, [ client, userNickName, ]);

  return null;
}

export default function GetUserNickName() {
  const [ nickname, setNickname, ] = React.useState(null);
  const [ getUserNickname, ] = useLazyQuery(
    GET_USER_NICKNAME_QUERY, {
      onCompleted: data => {
        if (data?.getUserNickname?.status === 'ok' && data?.getUserNickname?.nickname) {
          setNickname(data.getUserNickname.nickname);
        }
      },
    });

  React.useEffect(() => {
    getUserNickname();
  }, [ getUserNickname, ]);

  return <WriteUserNickNameToClient userNickName={nickname} />;
}
