/* global window */
/* global sessionStorage */
import React from 'react';
import PropTypes from 'prop-types';
import { FelaTheme, } from 'react-fela';

import Zen from '../Zen/Zen';
import Media from '../Media/Media';
import Osaka from './Osaka';
import LayoutContainer from '../PageLayout/LayoutContainer';
import getTransitionEnd from '../../utils/getTransitionEnd';

const propTypes = {
  articles: PropTypes.shape({}).isRequired,
  articleSection: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  /**
   * apollo client object
   */
  client: PropTypes.shape({ writeData: PropTypes.func, }).isRequired,
  /**
   * The scroll speed and direction (Y axis), as brought to us by: [`Scroll`](./#scroll)
   */
  velocity: PropTypes.number,
  y: PropTypes.number,
  isLabel: PropTypes.boolean,
};

const defaultProps = {
  velocity: null,
  y: 0,
  isLabel: false,
};

class OsakaWrapper extends React.Component {
  state = {
    display: false,
  };

  yToDiff = null;

  shouldComponentUpdate(nextProps) {
    if (nextProps.velocity > 0) {
      this.yToDiff = null;
    }
    else if (!this.state.display && !this.yToDiff && nextProps.velocity < 0) {
      this.yToDiff = this.props.y;
    }
    const yDiff = this.yToDiff ? this.yToDiff - nextProps.y : 0;
    const nextDisplay = nextProps.y > 400 && yDiff >= 400;

    return nextDisplay !== this.state.display;
  }

  componentWillUpdate(nextProps) {
    const display = !this.state.display;
    if (this.wrapperEl && display) {
      this.wrapperEl.style.display = 'block';
    }
    window.setTimeout(
      () => this.setState({ display, }, () => {
        this.props.client.writeData({
          data: { isOsakaDisplayed: this.state.display, },
        });
      }),
      50
    );
  }

  componentWillUnmount() {
    if (this.wrapperEl) {
      this.wrapperEl.removeEventListener(getTransitionEnd(this.wrapperEl), this.removeOsaka, false);
    }
    this.props.client.writeData({
      data: { isOsakaDisplayed: false, },
    });
  }

  removeOsaka = () => {
    if (!this.state.display) {
      this.wrapperEl.style.display = 'none';
    }
  };

  refCallback = el => {
    if (this.wrapperEl) {
      this.wrapperEl.removeEventListener(getTransitionEnd(this.wrapperEl), this.removeOsaka, false);
    }
    this.wrapperEl = el;
    if (this.wrapperEl) {
      this.wrapperEl.addEventListener(getTransitionEnd(this.wrapperEl), this.removeOsaka, false);
    }
  };

  render() {
    return (
      <Zen animate>
        <Media
          query={{ from: 'm', }}
          render={() => {
            const shouldDisplay = this.state.display;
            const {
              articles,
              isLabel,
            } = this.props;

            return (
              <FelaTheme>
                {theme => (
                  <LayoutContainer
                    attrs={{
                      ref: this.refCallback,
                    }}
                    miscStyles={{
                      backgroundColor: 'transparent',
                      display: 'none',
                      transform: `translate(50%, ${shouldDisplay ? '0%' : '-155%'})`,
                      transitionProperty: 'transform',
                      ...theme.getDelay('transition', -1),
                      ...theme.getDuration('transition', -1),
                      ...theme.getTimingFunction('transition', 'linear'),
                      position: 'fixed',
                      right: '50%',
                      top: theme.osakaStyle.top(isLabel),
                      width: '100%',
                      zIndex: theme.getZIndex('masthead', -100),
                    }}
                  >
                    <Osaka
                      lists={{ ...articles, }}
                    />
                  </LayoutContainer>
                )}
              </FelaTheme>
            );
          }}
        />
      </Zen>
    );
  }
}

OsakaWrapper.propTypes = propTypes;
OsakaWrapper.defaultProps = defaultProps;

export default OsakaWrapper;
