// @flow
import React from 'react';

import type { Node, } from 'react';
import { useFela, } from 'react-fela';
import { useMedia, } from '@haaretz/htz-components';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserLabelDisclaimer from '../../../TeaserLabelDisclaimer/TeaserLabelDisclaimer';
import Image from '../../../Image/Image';
import setBiAction from '../../../../utils/setBiAction';
import { isTeaser, } from '../../../../utils/validateType';
import useDarkModeChecker from '../../../../hooks/useDarkModeChecker';

const imgOptions: Object = {
  transforms: {
    aspect: 'headline',
    width: '600',
  },
};

type Props = {
  // gaAction: () => void,
  gaAction: ({
    category: string,
    action: string,
    label: string,
  }) => void,
  biAction: ?ListBiActionType,
  /**
   * data object from polopoly
   */
  list: ListDataType,
  /**
   * Determine if the component should be lazyload images
   */
  isLazyloadImages: boolean,
};

function Clampazzo({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: Props): Node {
  const { theme, } = useFela();
  const { title, items, } = list;
  const isMobile = useMedia({ query: { until: 's', }, });
  const isDarkMode = useDarkModeChecker();
  return (
    <ListView
      gridGap="1rem"
      padding="0"
      innerBackgroundColor={isDarkMode ? [ 'neutral', '-2', ] : [ 'commercial', 'bg', ]}
      sectionMiscStyles={{
        marginTop: '0',
      }}
      miscStyles={{
        paddingBottom: '2rem',
        ...(isDarkMode ? {} : { border: [ '1px', 1, 'solid', theme.color('neutral', '-5'), ], }),
        borderTop: [ { until: 's', value: [ '1px', 0, 'solid', theme.color('list', 'listViewHeaderCommercial'), ], }, ],
        display: [
          { until: 's', value: 'grid', },
          { from: 's', value: 'none', },
        ],
      }}
      attrs={{
        'data-test': 'clampazzo',
      }}
    >
      <ListViewHeader
        isTopBorderDisabled={isMobile}
        title={title}
        isCommercial
        miscStyles={{ gridArea: '1 / 1 / 2 / 2', }}
        backgroundColor="transparent"
        isSticky={false}
      />
      {items.map(
        (item, index) => {
          const itemBiAction = setBiAction(index, item, biAction);

          const image = item?.mobileImage || item?.image;

          return isTeaser(item) && (
          <Teaser
            key={item.contentId}
            areasTemplate={'"media" "content" "label" "."'}
            gridGap="1rem"
            backgroundColor={isDarkMode ? [ 'neutral', '-2', ] : [ 'commercial', 'bg', ]}
            miscStyles={{
              color: theme.color('bodyText', 'base'),
            }}
          >
            <TeaserMedia
              data={item}
              onClick={() => {
                if (index === 3) {
                  gaAction
                        && gaAction({
                          label: 'Labels Clicks',
                          category: 'editorial article-mobile',
                          action: item.representedContent || item.contentId,
                        });
                }
                itemBiAction && itemBiAction();
              }}
            >
              <Image
                image={image}
                imgOptions={imgOptions}
                lazyLoad={isLazyloadImages}
              />
            </TeaserMedia>
            <TeaserHeader
              wrapperMiscStyles={{
                marginRight: '1rem',
                marginLeft: '1rem',
              }}
              isGridItem
              {...item}
              onClick={() => {
                if (index === 3) {
                  gaAction
                        && gaAction({
                          label: 'Labels Clicks',
                          category: 'editorial article-mobile',
                          action: item.representedContent || item.contentId,
                        });
                }
                itemBiAction && itemBiAction();
              }}
            />
            <TeaserLabelDisclaimer
              companyName={item.note || ''}
              miscStyles={
              {
                paddingBottom: '3rem',
                marginRight: '1rem',
                marginLeft: '1rem',
              }}
            />
          </Teaser>
          );
        }
      )}
    </ListView>
  );
}

export default Clampazzo;
