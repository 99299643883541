import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';

import Image from '../../Image/Image';
import Kicker from '../../ArticleHeader/Kicker';
import Section from '../../AutoLevels/Section';
import H from '../../AutoLevels/H';

const propTypes = {
  /**
   * Article's image to display (image object or image url).
   */
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string, ]).isRequired,
  /**
   * Advertiser's name.
   */
  sourceName: PropTypes.string,
  /**
   * Article's title to display.
   */
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  sourceName: null,
};

const imgOptions = {
  transforms: {
    width: '84',
    aspect: 'regular',
    quality: 'auto',
  },
};

function Article({ title, image, sourceName, }) {
  const { css, theme, } = useFela();
  return (
    <Section
      className={css({
        display: 'flex',
        flexWrap: 'nowrap',
      })}
    >
      {typeof image === 'string' || image.path ? (
        <div
          className={css({
            flexShrink: '0',
            flexGrow: '0',
            height: '63px',
            width: '84px',
          })}
        >
          <img
            alt={image.alt || ''}
            src={image.path || image}
            className={css({ objectFit: 'cover', })}
            height="100%"
            width="100%"
          />
        </div>
      ) : (
        <Image imgOptions={imgOptions} image={image} hasWrapper={false} />
      )}
      <div
        className={css({
          ...theme.type(-2),
          marginStart: theme.osakaStyle.articleImgSpacing,
          maxHeight: '9rem',
          overflow: 'hidden',
        })}
      >
        {sourceName ? (
          <Kicker
            fontSize={[
              { until: 'xl', value: theme.osakaStyle.typeUntilXl, },
              { from: 'xl', value: theme.osakaStyle.typeFromXl, },
            ]}
            miscStyles={{
              fontWeight: '700',
            }}
            text={sourceName}
          />
        ) : null}
        <H
          className={css({
            fontWeight: 'bold',
            extend: [
              theme.type(theme.osakaStyle.typeFromXl, { fromBp: 'xl', }),
              theme.type(theme.osakaStyle.typeUntilXl, { untilBp: 'xl', }),
            ],
          })}
        >
          {title}
        </H>
      </div>
    </Section>
  );
}

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default Article;
