import React from 'react';
import PropTypes from 'prop-types';

import Osaka from '../../Osaka/OsakaController';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';
import WebViewExclude from '../../WebViewExclude/WebViewExclude';
import useSlotData from '../../../hooks/Page/useSlotData';

const propTypes = {
  /**
   * Article's ID
   */
  articleId: PropTypes.string.isRequired,
  /**
   * The elements composing the page’s header.
   */
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * The page type 'regularArticle' | 'homepage' | 'section'...
   */
  pageType: PropTypes.string.isRequired,
  /**
   * the background color passed to the LayoutRow component.
   */
  rowBgc: PropTypes.string,
  mastheadFullWidth: PropTypes.bool,
  themeColor: PropTypes.string,
};

const defaultProps = {
  rowBgc: null,
  mastheadFullWidth: false,
  themeColor: 'defaultTheme',
};


function Header({ pageType, articleId, rowBgc, mastheadFullWidth, themeColor, }) {
  const getComponent = useGetComponent();
  const header = useSlotData('header');
  return (
    <WebViewExclude>
      {header
        ? header.map(element => {
          const Element = element.inputTemplate === 'Grid'
            ? Osaka
            : getComponent(
              element.inputTemplate,
              {
                ...element,
                loadPriority: element.loadPriority,
                isExpanded: element.isExpanded,
                preventRender: element.preventRender,
              }
            );

          return (
            <Element
              key={element.contentId}
              {...element}
              {...(element.inputTemplate === 'PageNavigation'
                ? {
                  mastheadFullWidth,
                  pageType,
                  rowBgc,
                  themeColor,
                }
                : {})}
              articleId={articleId}
            />
          );
        })
        : null}
    </WebViewExclude>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
