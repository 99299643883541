import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Debug from '../Debug/Debug';

const Survey = dynamic(() => import('../Survey/Survey'), { ssr: true, });
const ArticleHtmlElement = dynamic(() => import('./components/ArticleHtmlElement'), { ssr: true, });
const BeforeAndAfter = dynamic(() => import('./components/BeforeAndAfter'), { ssr: true, });
const MagazineQuotes = dynamic(() => import('./components/MagazineQuotes'), { ssr: true, });

const propTypes = {
  content: {
    inputTemplate: PropTypes.string.isRequired,
    elementType: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired,
    contentName: PropTypes.string.isRequired,
    properties: PropTypes.shape({}),
  },
};

const defaultProps = {
  content: null,
};

const template = new Map([
  [ 'BeforeAndAfter', BeforeAndAfter, ],
  [ 'Quotes', MagazineQuotes, ],
  // [ 'com.tm.ArticleInteractiveHtmlElement', Survey, ],
  [ 'StaticElementRichTextElement', ArticleHtmlElement, ],
]);

function InteractiveElement({ content, ...props }) {
  let Element;
  if (props.content?.contentName && props.content?.contentName?.includes('surveys2019')) {
    Element = Survey;
  }
  else {
    Element = template.get(content?.inputTemplate);
  }

  return Element ? (
    <Element {...props} {...content} />
  ) : (
    <Debug>
      <p>{`${content?.inputTemplate} is not supported interactive element`}</p>
    </Debug>
  );
}

InteractiveElement.propTypes = propTypes;
InteractiveElement.defaultProps = defaultProps;

export default InteractiveElement;
