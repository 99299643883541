// @flow
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import React, { Fragment, useState, } from 'react';
import { FelaComponent, FelaTheme, } from 'react-fela';

import type { Node, } from 'react';
import type { Asset as LineAsset, } from '../Graph/graphs/Line/Line';
import type { Asset as ScatterAsset, } from '../Graph/graphs/Scatter/Scatter';

type Stats = Array<{ title: string, value: string | number, }>;

const numToString: (number | string) => string = num => (typeof num === 'number'
  ? num.toLocaleString('he', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  : num);

export const Stat = ({ children, title, miscStyles, }: any): Node => (
  <FelaComponent
    style={({ theme, }) => ({
      alignItems: 'center',
      display: 'flex',
      flexBasis: '100%',
      flexDirection: 'column',
      flexGrow: '1',
      flexShrink: '1',
      justifyContent: 'center',
      position: 'relative',
      ':not(:last-child)': {
        ':after': {
          backgroundColor: theme.color('neutral', '-4'),
          content: '""',
          end: '0',
          height: '5rem',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '2px',
        },
      },
    })}
  >
    <FelaComponent style={{ fontWeight: '700', }} as="span">
      {title}
    </FelaComponent>
    <FelaComponent
      style={({ theme, }) => ({
        ...theme.type(1),
        extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
      })}
    >
      {({ className, }) => children(className)}
    </FelaComponent>
  </FelaComponent>
);
export type GraphType = 'line' | 'scatter';
export type Period = 'day' | 'week' | 'month' | 'year1' | 'year3' | 'year5';
type Props = {
  graphType: GraphType,
  period: Period,
  render: ({ changeStats: (stock: LineAsset | ScatterAsset) => void, }) => Node,
  miscStyles: ?Object,
};

AssetStats.defaultProps = {
  miscStyles: null,
};

function AssetStats({ period, graphType, render, miscStyles, }: Props) {
  const [ stats, setStats, ] = useState([]);

  const getDateStat: (number, string) => { title: string, value: string, } = (date, time) => {
    let title: string;
    let value: string;
    switch (time) {
      case 'day':
        title = 'שעה';
        value = new Date(date).toLocaleTimeString('it-IT', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        break;
      default:
        title = 'תאריך';
        value = new Date(date).toLocaleDateString('en-GB');
    }
    return { title, value, };
  };

  const changeStats: Object => void = stock => {
    let newStats: Stats;

    if (graphType === 'line') {
      const { time, value, /* change */ }: LineAsset = stock || {};
      newStats = [
        { ...getDateStat(time, period), },
        { title: 'שער', value: value || '', },
        // { title: '% שינוי', value: change || '', },
      ];
    }
    else if (graphType === 'scatter') {
      const { x, y, name, }: ScatterAsset = stock || {};
      newStats = [
        { title: 'מניה', value: name || '', },
        { title: 'מחזור', value: x || '', },
        { title: 'תשואה', value: y || '', },
      ];
    }
    setStats(newStats);
  };

  return (
    <FelaTheme>
      {theme => (
        <Fragment>
          {render({ changeStats, })}
          <FelaComponent
            style={{
              backgroundColor: theme.color('neutral', '-2'),
              color: theme.color('neutral', '-10'),
              display: 'flex',
              height: '9rem',
              paddingBottom: '1rem',
              paddingTop: '1rem',
              ...theme.type(-2),
              extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
            }}
          >
            {stats && stats.length > 0 ? (
              <Fragment>
                {stats.map((stat, index) => (
                  <Stat
                    key={stat.title}
                    title={stat.title}
                    miscStyles={
                      index === stats.length - 1
                        ? {
                          // color:
                          //     Number(stat.value) < 0
                          //       ? theme.color('negative', '-2')
                          //       : theme.color('positive', '-2'),
                          direction: 'ltr',
                          // ':before': {
                          //   content:
                          //   // Number(stat.value) > 0
                          //   //   ? '"+"'
                          //   //          :
                          //            Number(stat.value) < 0
                          //              ? '"-"'
                          //              : '""',
                          // },
                          // ':after': {
                          //   content: '"%"',
                          // },
                        }
                        : {}
                    }
                  >
                    {className => (
                      <span className={className}>
                        {index === stats.length - 1
                          ? numToString(Math.abs(Number(stat.value)))
                          : stat.value}
                      </span>
                    )}
                  </Stat>
                ))}
              </Fragment>
            ) : null}
          </FelaComponent>
        </Fragment>
      )}
    </FelaTheme>
  );
}

export default AssetStats;
