import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';


const fbAppIds = new Map([
  [
    'htz',
    <>
      <meta property="og:site_name" content="Haaretz הארץ" />
      <meta property="fb:app_id" content="110687712359084" />
    </>,
  ],
  [
    'tm',
    <>
      <meta property="og:site_name" content="TheMarker Online" />
      <meta property="fb:app_id" content="52929925921" />
    </>,
  ],
  [
    'hdc',
    <>
      <meta property="og:site_name" content="Haaretz.com" />
      <meta property="fb:app_id" content="287530407927859" />
    </>,
  ],
]);

const FacebookAppId = ({ site, }) => (<Head>{fbAppIds.get(site)}</Head>);

FacebookAppId.propTypes = {
  site: PropTypes.string,
};
FacebookAppId.defaultProps = {
  site: 'htz',
};
export default FacebookAppId;
