// @flow
/* global document window */
import { useCallback, } from 'react';
import { useFela, } from 'react-fela';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';

const fontSizes = [ '4px', '5px', '6px', '7px', '9px', ];

function changeFontSize(delta, theme) {
  const html = document.documentElement;

  if (html) {
    const htmlFontSize = window.getComputedStyle(html, null).getPropertyValue('font-size');

    const idx = fontSizes.indexOf(htmlFontSize) + delta;

    const fontSize = idx > -1 && idx < fontSizes.length ? fontSizes[idx] : '6px';


    if (fontSize) {
      html.style.fontSize = fontSize;
    }
  }
}

function FontSizeManagerWebView() {
  const { theme, } = useFela();

  // $FlowFixMe
  const onChangeFontSize = useCallback(({ detail, }: { detail: number[], }) => {
    const value = detail?.[0] || -1;

    changeFontSize(value, theme);
  }, [ theme, ]);

  useDocumentEventListener('changeFontSize', onChangeFontSize, false);

  return null;
}


export default FontSizeManagerWebView;
