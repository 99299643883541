// @flow
import * as React from 'react';
import dynamic from 'next/dynamic';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';

export type ArticleEntry = {
  contentId: string,
  title: string,
  path: string,
  image: ImageDataType,
};

export type Props = {
  style: 'List' | 'Snake',
  seriesTitle: string,
  usePagination: boolean,
  itemsPerPage: number,
  articles: [ ArticleEntry, ],
  miscStyle: ?StyleProps,
};

const styles = {
  List: dynamic(import('./SeriesListView'), { loading: () => null, }),
  Snake: dynamic(import('./SeriesSnakeView'), { loading: () => null, }),
  Block: dynamic(import('./SeriesBlockView'), { loading: () => null, }),
};

export default function seriesArticles({ style, ...props }: Props) {
  const SeriesView = styles[style] || styles.List;

  return <SeriesView {...props} />;
}
