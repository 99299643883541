// @flow
import * as React from 'react';
import { MICHELLE_LIST_QUERY, } from '@haaretz/graphql';
import MichelleView from './MichelleView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type MichelleWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}


export default function MichelleWrapper(props: MichelleWrapperProps) {
  return (
    <ListWrapper {...props} query={MICHELLE_LIST_QUERY} view="Michelle">
      {dataProps => <MichelleView {...dataProps} />}
    </ListWrapper>
  );
}
