export function extractAuthorsNameFromArticle(authors) {
  const result = [];
  if (authors) {
    result.push(authors.map(author => author.contentName).join());
  }

  return result.join();
}

export function extractAuthorsIdFromArticle(authors) {
  const result = [];
  if (authors) {
    result.push(authors.map(author => author.contentId).join());
  }

  return result.join();
}
