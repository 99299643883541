// @flow

import { useQuery, } from 'react-apollo';
import { TOP_NEWS_QUERY, } from '@haaretz/graphql';

export default function useTopNewsData(config) {
  const { data, loading, error, } = useQuery(TOP_NEWS_QUERY, config);

  if (loading) return null;
  if (error) {
    console.error('TOPNEWS error', error);
    return null;
  }

  if (!data) return null;

  return data;
}
