// @flow
import * as React from 'react';

import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import Image from '../../../Image/Image';

import getImageAssets from '../../../../utils/getImageAssets';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type Props = {
  itemData: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  gridArea: string,
  backgroundColor: 'white' | 'transparent',
  inMagazine: boolean,
  theme: Object,
};

const areasTemplate = `
"media"
"content"
"."
"footer"
`;

MichelleTeaser.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const imageAssetsOptions = theme => ({
  bps: theme.bps,
  aspect: 'headline',
  sizes: [
    { from: 'xl', size: '277px', },
    { from: 'l', size: '206px', },
    { from: 'm', size: '232px', },
    { size: '184px', },
  ],
  widths: [ 368, 277, 232, 206, 184, ],
});

export default function MichelleTeaser({
  itemData,
  backgroundColor,
  inMagazine,
  gridArea,
  isLazyloadImages,
  biAction,
  theme,
}: Props): React.Node {
  const image = itemData?.mobileImage || itemData?.image;

  return (
    <Teaser
      data={itemData}
      backgroundColor={backgroundColor}
      gridArea={gridArea}
      areasTemplate={areasTemplate}
      rowTemplate="auto 1fr 0 auto"
      gridGap="1rem"
    >
      <TeaserMedia data={itemData} isStacked onClick={biAction}>
        <Image
          image={image}
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets(imageAssetsOptions(theme))}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...itemData}
        onClick={biAction}
      />
      <TeaserFooter
        data={itemData}
        showAuthor={theme.michelleStyle.showAuthor}
        showTime={theme.michelleStyle.showTime}
        typeScale={[
          { until: inMagazine ? 'l' : 'm', value: -3, },
          { from: inMagazine ? 'l' : 'm', value: -2, },
        ]}
      />
    </Teaser>
  );
}
