import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { InView, } from 'react-intersection-observer';
import { ReadArticleService, } from '@haaretz/htz-user-utils';

import {
  extractAuthorsIdFromArticle,
  extractAuthorsNameFromArticle,
} from '../GoogleAnalytics/helpers/extractAuthorsFromArticle';
import LayoutRow from './LayoutRow'; // eslint-disable-line import/no-named-as-default
import LayoutContainer from './LayoutContainer'; // eslint-disable-line import/no-named-as-default
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import HeaderSlot from './slots/Header';
import RainbowDataProvider from '../Marketing/RainbowDataProvider';
import RainbowSlot from '../Marketing/RainbowSlot';
import BIRequest from '../BI/BIRequest';
import NoSSR from '../NoSSR/NoSSR';
import ArticleGallery from '../ArticleGallery/ArticleGallery';
import useScrollYPosition from '../../hooks/useScrollYPosition';
import useSlotData from '../../hooks/Page/useSlotData';
import useArticleId from '../../hooks/Page/useArticleId';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import { useIsBot, } from '../../hooks/useIsBot';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import PreviewExclude from '../PreviewExclude/PreviewExclude';
import useAuthorsData from '../../hooks/Page/useAuthorsData';
import usePaywallType from '../../hooks/Page/usePaywallType';
import useArticleType from '../../hooks/Page/useArticleType';
import useArticleData from '../../hooks/Page/useArticleData';
import useIsBlock from '../../hooks/useIsBlock';
import { useUser, } from '../User/UserDispenser';
import usePrint from '../../hooks/Page/usePrint';

const GaDimensions = dynamic(import('../GoogleAnalytics/GaDimensions'), {
  ssr: false,
  loading: () => null,
});

// eslint-disable-next-line react/prop-types
function PreHeader({ children, rowBgc, }) {
  const { theme, } = useFela();
  const [ passedScrollThreshold, setPassedScrollThreshold, ] = React.useState(false);
  const { y, } = useScrollYPosition();
  const rowRef = React.useRef(null);

  React.useEffect(() => {
    const dfpOverMaxHeight = rowRef && rowRef.current && rowRef.current.clientHeight > 150;
    if (dfpOverMaxHeight) {
      setPassedScrollThreshold(true);
    }
    if (!passedScrollThreshold && y > 600) {
      setPassedScrollThreshold(true);
    }
  }, [ passedScrollThreshold, y, ]);

  return (
    <LayoutRow
      attrs={{ 'data-element': 'preHeaderLayoutRow', }}
      elementRef={rowRef}
      bgc={rowBgc}
      miscStyles={{
        zIndex: theme.getZIndex('masthead', -1),
        ...(passedScrollThreshold
          ? { position: 'relative', }
          : {
            position: [ { until: 's', value: 'sticky', }, { from: 's', value: 'relative', }, ],
            top: [ { until: 's', value: 0, }, ],
            zIndex: theme.getZIndex('masthead', 1),
          }),
      }}
    >
      {children}
    </LayoutRow>
  );
}

const mqType = PropTypes.oneOf([ 's', 'm', 'l', 'xl', ]);
const namedColorType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);
const responsiveNamedColorType = PropTypes.oneOfType([
  namedColorType,
  PropTypes.shape({
    from: mqType,
    until: mqType,
    value: namedColorType,
  }),
]);

const propTypes = {
  // should article layout render the postHeader slot
  renderPostHeader: PropTypes.bool,
  /**
   * the background color passed to all the LayoutRow components.
   */
  rowBgc: responsiveNamedColorType,
  /**
   * When present, will be passed down to the header slot as rowBgc
   */
  mastheadBgc: responsiveNamedColorType,
  /** should the masthead border bottom be full width */
  mastheadFullWidth: PropTypes.bool,
  /**
   * Article's path
   */
  path: PropTypes.string.isRequired,
  /**
   * children of ArticleLayout
   */
  children: PropTypes.element.isRequired,
  isPhotoBlog: PropTypes.bool,
  site: PropTypes.oneOf([ 'htz', 'tm', 'hdc', ]).isRequired,
  themeColor: PropTypes.string,
};

const defaultProps = {
  rowBgc: null,
  mastheadBgc: null,
  mastheadFullWidth: false,
  renderPostHeader: true,
  isPhotoBlog: false,
  site: 'htz',
  themeColor: 'defaultTheme',
};

function ArticlePageLayout({
  children,
  rowBgc,
  mastheadBgc,
  mastheadFullWidth,
  renderPostHeader,
  path,
  site,
  isPhotoBlog,
  themeColor,
}) {
  const { isBot, } = useIsBot();
  const isWebView = useWebViewChecker();

  const articleId = useArticleId();
  const preHeader = useSlotData('preHeader');
  const postHeaderSlot = useSlotData('postHeader');
  const postMain = useSlotData('postMain');
  const footer = useSlotData('footer');
  const authorsData = useAuthorsData();
  const { isPremiumContent, isSuperContent, } = usePaywallType();
  const isBlock = useIsBlock();
  const { user, } = useUser();
  const articleType = useArticleType();
  const articleData = useArticleData();
  const { isPrint, } = usePrint();


  const { pageType, } = articleData || {};

  const authors = authorsData || [];
  const isCloseArticle = isBlock;

  const postHeader = isWebView && Array.isArray(postHeaderSlot)
    ? postHeaderSlot.filter(item => !(item.id || '').includes('billboard'))
    : postHeaderSlot;

  const getComponent = useGetComponent();
  const getElements = slot => slot.map(element => {
    const Element = getComponent(element.inputTemplate, {
      ...element,
      loadPriority: element.loadPriority,
      isExpanded: element.isExpanded,
      preventRender: element.preventRender,
    });
    const { properties, ...elementWithoutProperties } = element;
    return (
      <Element
        key={element.contentId}
        {...elementWithoutProperties}
        {...properties}
      />
    );
  });

  const openOrClosedArticleString = isCloseArticle ? 'closed' : 'opened';
  // TODO: Get paywallType from usePaywallType hook
  const PremiumOrNotPremiumOrSuperPremiumString = isSuperContent ? 'superpremium' : isPremiumContent ? 'premium' : 'not_premium';

  return (
    <Fragment>
      <RainbowDataProvider>
        <NoSSR>{ReadArticleService.update(articleId, isBlock)}</NoSSR>
        {preHeader && !isPrint ? (
          <>
            <PreHeader bgc={rowBgc}>{getElements(preHeader)}</PreHeader>
          </>
        ) : null}
        {/* Layout row is inside Masthead Component because its miscStyles depend on state */}
        {/* //TODO : canary - check if top-strip banner on label is disable */}
        <RainbowSlot id="top-strip" />
        <HeaderSlot
          pageType="article"
          rowBgc={mastheadBgc || rowBgc}
          articleId={articleId}
          mastheadFullWidth={mastheadFullWidth}
          themeColor={themeColor}
        />
        {postHeader && renderPostHeader && !isPrint ? (
          <LayoutRow bgc={rowBgc}>
            <LayoutContainer miscStyles={{ paddingBottom: [ { from: 'l', value: '5rem', }, ], }}>
              {getElements(postHeader)}
            </LayoutContainer>
          </LayoutRow>
        ) : null}
        <LayoutRow bgc={rowBgc} tagName="main" id="pageRoot" miscStyles={{ flexGrow: 1, }}>
          {children}
          <ArticleGallery path={path} />
        </LayoutRow>
        <PreviewExclude>
          {postMain && !isPrint ? (
            <LayoutRow bgc={rowBgc} miscStyles={{ display: [ { until: 's', value: isBot ? undefined : 'none', }, ], }}>
              <InView rootMargin="1000px" triggerOnce>
                {({ inView, ref, entry, }) => (
                  <div ref={ref}>{inView || isBot ? getElements(postMain) : null}</div>
                )}
              </InView>
            </LayoutRow>
          ) : null}
        </PreviewExclude>
        {footer && !isPrint ? <LayoutRow bgc={rowBgc}>{getElements(footer)}</LayoutRow> : null}
        <PreviewExclude>
          <Fragment>
            <BIRequest
              pageType="Article"
              articleId={articleId}
              isBlock={isBlock}
              authors={[
                extractAuthorsNameFromArticle(authors),
                extractAuthorsIdFromArticle(authors),
              ]}
            />
            <WebViewExclude>
              <GaDimensions
                pageType={articleType || pageType}
                authors={extractAuthorsNameFromArticle(authors)}
                userType={user.type}
                articlePaywallMode={`${PremiumOrNotPremiumOrSuperPremiumString}_${openOrClosedArticleString}`}
                withPageView
              />
            </WebViewExclude>
          </Fragment>
        </PreviewExclude>
        <RainbowSlot id="bottom-strip" />
      </RainbowDataProvider>
    </Fragment>
  );
}

ArticlePageLayout.propTypes = propTypes;
ArticlePageLayout.defaultProps = defaultProps;

export default ArticlePageLayout;
