// @flow

import React, { type Node, Fragment, } from 'react';
import useWebViewChecker from '../../hooks/useWebViewChecker';

type WebViewIncludeProps = {
  children?: any,
};


function WebViewInclude({ children, }: WebViewIncludeProps): Node {
  const isWebView = useWebViewChecker();

  if (!isWebView) return null;

  return <Fragment>{children}</Fragment>;
}

WebViewInclude.defaultProps = {
  children: null,
};

export default WebViewInclude;
