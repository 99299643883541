/* global window document */
// @flow

import { memo, useCallback, useEffect, useState, } from 'react';

import usePrint from '../../hooks/Page/usePrint';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import pixelEvent from '../../utils/pixelEvent';
import { updateTeasersInPage, } from '../List/ListDuplication';
import useOneTime from '../../hooks/useOneTime';
import { writeToLocalStorage, } from '../../utils/writeToStorage';
import useRenew from '../../hooks/useRenew';

type Props = {
  articleId: String;
  writeToLocal: Function;
  writeToSession: Function;
}

function InitArticle({ articleId, writeToLocal, writeToSession, }: Props) {
  useRenew();

  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const { isPrint, } = usePrint();

  const onLoadElement = useCallback((e: any) => {
    const args = e.detail || [];

    if (args[0]) {
      window.deviceId = args[0];
    }

    if (args[1]) {
      window.appVersion = args[1];
    }

    if (args[2]) {
      window.anonymousId = args[2];
    }

    if (args[3]) {
      window.androidId = args[3];
    }

    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  useOneTime(!isWebView || (isWebView && isLoaded), () => writeToLocalStorage({
    key: 'lastArticleId',
    value: articleId,
    errorMessage: 'error writing from last article id to local storage',
  })
  );

  useEffect(() => {
    if (!isWebView || isLoaded) {
      pixelEvent('track', 'ViewContent', { article_id: articleId, });
      writeToSession(articleId);
      writeToLocal(articleId);
    }
  }, [ articleId, isLoaded, isWebView, writeToLocal, writeToSession, ]);

  useEffect(() => {
    // $FlowFixMe
    updateTeasersInPage([ { contentId: articleId, }, ]);
  }, [ articleId, ]);

  useEffect(() => {
    const onafterprint = () => { window.close(); };

    const onload = () => {
      window.print();
    };

    if (isPrint) {
      if (document.readyState === 'complete') {
        onload();
      }
      else {
        window.addEventListener('load', onload);
      }
      window.addEventListener('afterprint', onafterprint);
    }

    return () => {
      if (isPrint) {
        window.removeEventListener('afterprint', onafterprint);
        window.removeEventListener('load', onload);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  return null;
}

export default memo<any>(InitArticle);
