// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import ImgSource from './elements/ImgSource';
import DefaultImage from '../DefaultImage/DefaultImage';
import setColor from '../../utils/setColor';
import LazyLoadedImage from './LazyLoadedImage';
import { getDimensions, getSources, getSrcSet, formatImageTitle, } from './utils';
import useIsAnimationReduced from '../../hooks/useIsAnimationReduced';

import type { attrFlowType, } from '../../flowTypes/attrTypes';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import type { PicturePropsType, PictureSourceType, SourceOptionsType, } from '../../flowTypes/PicturePropsType';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import WebViewPreloader from '../WebViewPreloader/WebViewPreloader';
import usePrint from '../../hooks/Page/usePrint';

type PictureWrapperProps = {
  image: ImageDataType,
  sources: PictureSourceType[],
  defaultImg: {
    sourceOptions: SourceOptionsType,
    positionInImgArray?: number,
  },
  bgc: ?ColorPropType,
  miscStyles: ?StyleProps,
  children: React.Node,
}

type PictureInnerProps = {
  image: ImageDataType,
  lazyLoad: ?boolean,
  hasWrapper: ?boolean,
  isAnimationReduced: boolean,
  sources: PictureSourceType[],
  media: Array<?string>,
  alt: ?string,
  title: ?string,
  imgSrc: ?(string | string[]),
  imgSrcSet: ?string,
  defaultSizes: ?string,
  isPresentational: ?boolean,
  attrs: ?attrFlowType,
  theme: Object,
  onLoad?: ?Function,
};

type PlainPictureProps = {
  children: ({ mayLoad: boolean, ref?: ?(HTMLElement => void), }) => React.Node,
};

const PictureWrapperStyle = ({ image, sources, theme, defaultImg, bgc, miscStyles, }) => ({
  height: '0',
  width: '100%',
  position: 'relative',
  paddingBottom: getPaddingBottom(image, defaultImg),
  extend: [
    ...sources.map(({ from, until, misc, type, ...restOfSource }) => theme.mq(
      { from, until, misc, type, },
      { paddingBottom: getPaddingBottom(image, restOfSource), }
    )),
    parseComponentProp('backgroundColor', bgc || [ 'image', 'bgc', ], theme.mq, setColor, theme.color),
    // Trump all other styles with those defined in `miscStyles`
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

function getPaddingBottom(image, source) {
  const { files, } = image || {};
  const { width, height, } = getDimensions(files, source);

  return `${(height / width) * 100}%`;
}

PictureWrapper.defaultProps = { bgc: null, miscStyles: null, };
export function PictureWrapper({
  image,
  sources,
  defaultImg,
  bgc,
  miscStyles,
  children,
}: PictureWrapperProps): React.Node {
  const className = useFela({
    image,
    miscStyles,
    sources,
    defaultImg,
    bgc,
  }).css(PictureWrapperStyle);
  return <div className={className}>{children}</div>;
}

const PlainPicture = ({ children, }: PlainPictureProps): React.Node => children({ mayLoad: true, });

function PictureInner({
  image,
  isAnimationReduced,
  lazyLoad,
  hasWrapper,
  sources,
  media,
  alt,
  title,
  imgSrc,
  imgSrcSet,
  defaultSizes,
  isPresentational,
  attrs,
  theme,
  onLoad,
}: PictureInnerProps): React.Node {
  const [ loaded, setLoaded, ] = React.useState(!lazyLoad);
  const Renderer = lazyLoad ? LazyLoadedImage : PlainPicture;
  const isWebView = useWebViewChecker();

  return (
    <React.Fragment>
      {loaded ? null : <WebViewPreloader />}
      <Renderer>
        {({ mayLoad, ref, }) => (
          <picture>
            {sources.map((source, index) => (
              <ImgSource
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                {...(media[index] ? { media: media[index], } : {})}
                tagName="source"
                {...(source.mimeType ? { type: source.mimeType, } : {})}
                srcSet={mayLoad ? getSrcSet(image, source, isAnimationReduced) : null}
                {...(source.sourceOptions.sizes ? { sizes: source.sourceOptions.sizes, } : {})}
              />
            ))}
            <ImgSource
              imgRef={ref}
              hasWrapper={hasWrapper}
              alt={alt}
              title={title}
              src={mayLoad ? imgSrc : null}
              {...(mayLoad ? { srcSet: imgSrcSet, } : {})}
              sizes={defaultSizes}
              onLoad={(...args) => {
                if (typeof onLoad === 'function') {
                  onLoad(...args);
                }
              }}
              {...(lazyLoad || isWebView
                ? {
                  loading: 'lazy',
                  onLoad: (...args) => {
                    if (typeof onLoad === 'function') {
                      onLoad(...args);
                    }
                    setLoaded(true);
                  },
                  miscStyles: {
                    transition: theme.getTransitionString('opacity', 1, 'easeOut'),
                    opacity: loaded ? 1 : 0,
                  },
                }
                : {})}
              attrs={
              isPresentational
                ? {
                  ...attrs,
                  role: 'presentation',
                  'aria-hidden': true,
                }
                : attrs
            }
            />
          </picture>
        )}
      </Renderer>
    </React.Fragment>
  );
}

Picture.defaultProps = {
  attrs: null,
  bgcolor: null,
  lazyLoad: false,
  miscStyles: null,
  hasWrapper: true,
  isPresentational: false,
  disableTitle: false,
  onLoad: null,
};
export default function Picture({
  image,
  defaultImg,
  sources,
  hasWrapper,
  isPresentational,
  disableTitle,
  lazyLoad,
  attrs,
  bgcolor,
  miscStyles,
  onLoad,
}: PicturePropsType): React.Node {
  const { theme, } = useFela();
  const isAnimationReduced = useIsAnimationReduced();
  const { isPrint, } = usePrint();

  if (isPrint) return null;

  if (!sources) return null;
  if (!image) {
    const defaultImgTransforms = defaultImg.sourceOptions.transforms;
    // sourceOptions.transforms can be either an array or an object,
    // we need the aspect / width and height and they should be the same
    //  for every transform so we just take the first one
    const defaultImageTransform = Array.isArray(defaultImgTransforms)
      ? defaultImgTransforms[0]
      : defaultImgTransforms;

    // building the image to match image of sources
    const transformForDefaultImage = {
      sourceOptions: {
        transforms: {
          aspect: defaultImageTransform.aspect,
          width: defaultImageTransform.width,
          height: defaultImageTransform.height,
        },
      },
    };
    const transforms = [ transformForDefaultImage, ...sources, ].map(
      ({ from, until, sourceOptions, }: PictureSourceType) => {
        const sourceTransform = Array.isArray(sourceOptions.transforms)
          ? sourceOptions.transforms[0]
          : sourceOptions.transforms;

        const value = {
          aspect: sourceTransform.aspect,
          width: sourceTransform.width,
          height: sourceTransform.height,
        };
        return {
          ...(from ? { from, } : {}),
          ...(until ? { until, } : {}),
          // since value is an object and we dont always get a from or until
          // in order for parseComponentProp in DefaultImage
          //  to work correctly with the value key we always pass media type: all
          type: 'all',
          value,
        };
      }
    );

    return <DefaultImage transforms={transforms} />;
  }
  if (imageIsNotValid({ image, sources, defaultImg, })) return null;


  const { accessibility, credit, caption, photographer, } = image;
  const [ imgSrc, ...imgSrcSet ] = getSources(image, defaultImg, isAnimationReduced);

  const defaultSizes = defaultImg.sourceOptions.sizes;
  const media = getMedia({ sources, theme, });

  const Wrapper = hasWrapper ? PictureWrapper : React.Fragment;

  return (
    <Wrapper
      {...(hasWrapper
        ? {
          image,
          sources,
          defaultImg,
          miscStyles,
          bgc: bgcolor,
        }
        : {})}
    >
      <PictureInner
        image={image}
        isAnimationReduced={isAnimationReduced}
        lazyLoad={lazyLoad}
        hasWrapper={hasWrapper}
        sources={sources}
        media={media}
        alt={accessibility}
        title={disableTitle ? null : formatImageTitle({ caption, credit: credit || photographer, theme, })}
        imgSrc={imgSrc}
        imgSrcSet={imgSrcSet.length ? imgSrcSet : null}
        defaultSizes={defaultSizes}
        isPresentational={isPresentational}
        attrs={attrs}
        theme={theme}
        onLoad={onLoad}
      />
    </Wrapper>
  );
}

PictureInner.defaultProps = {
  onLoad: null,
};

// //////////////////////////////////////////////////////////////////////
//                          Helper Functions                          //
// //////////////////////////////////////////////////////////////////////

function getMedia({ sources, theme, }) {
  const finalMedia = sources.map((img, index) => {
    const { from, until, misc, type, } = img;
    const imgHasMedia = [ from, until, misc, type, ]
      // eslint-disable-next-line eqeqeq
      .some(item => item != undefined);
    return imgHasMedia ? theme.getMqString({ from, until, misc, type, }, true, true) : undefined;
  });
  return finalMedia;
}

// Added this check to safely handle cases of faulty infographic image
// (i.e. an infographic with a single image in its imgArray)
function imageIsNotValid({ image, sources, defaultImg, }) {
  return !image.files
    || !image.files[defaultImg.positionInImgArray || 0]
    || sources.some(({ positionInImgArray, }) => !image.files[positionInImgArray || 0]);
}
