import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import { useInView, } from 'react-intersection-observer';

import { stylesPropType, } from '../../propTypes/stylesPropType';
import ArticleLink from './articleLink';
import useOneTime from '../../hooks/useOneTime';
import { useEventTracker, } from '../../utils/EventTracker';

const propTypes = {
  /**
   * An array of article objects.
   */
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Article title to display.
       */
      title: PropTypes.string.isRequired,
      /**
       * Article's destination.
       */
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Should be passed if the component should have a MarginBottom style.
   */
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,

  isLabel: PropTypes.bool,
};

const defaultProps = {
  miscStyles: null,
  isLabel: false,
};

const articleWrapperStyle = ({ theme, isLabel, }) => ({
  paddingInlineStart: !isLabel ? '1em' : null,
  position: 'relative',
  display: 'flex',
  alignItems: 'baseline',
  color: theme.color('primary', '+1'),
  marginBottom: '2rem',
  ':before': !isLabel ? {
    marginInlineStart: '-1em',
    marginInlineEnd: '0.5em',
    transform: 'translateY(-50%)',
    display: 'inline-block',
    content: '""',
    borderRadius: '50%',
    minHeight: '1rem',
    minWidth: '1rem',
    backgroundColor: theme.color('primary', 'base'),
  } : null,
  ':last-child': {
    marginBottom: 0,
  },
});

function listStyle({ theme, miscStyles, }) {
  return {
    extend: [
      parseComponentProp('marginBottom', '5rem', theme.mq),
      parseComponentProp('marginTop', '5rem', theme.mq),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}
function RelatedArticles({
  articles,
  miscStyles,
  isLabel,
}) {
  const { css, } = useFela({ miscStyles, isLabel, });
  const { biAction, biImpression, } = useEventTracker();

  const [ ref, inView, ] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  useOneTime(articles && inView, () => articles.map((article, i) => {
    biImpression && biImpression({
      actionCode: 109,
      ArticleId: article.contentId,
      NoInList: i + 1,
      ViewName: 'Related article',
    });
    return null;
  }));

  return (
    <ul className={css(listStyle)} data-test="relatedArticles" ref={ref}>
      {articles.map((article, i) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
          className={css(articleWrapperStyle)}
          key={article.contentId}
          onClick={() => {
            biAction({
              actionCode: 109,
              ArticleId: article.contentId,
              NoInList: i + 1,
              ViewName: 'Related article',
            });
          }}
        >
          <ArticleLink article={article} isLabel={isLabel} NoInList={i + 1} />
        </li>
      ))
          }
    </ul>
  );
}

RelatedArticles.propTypes = propTypes;
RelatedArticles.defaultProps = defaultProps;

export default RelatedArticles;
