// @flow
import React, { Fragment, useState, } from 'react';
import { FelaComponent, } from 'react-fela';

import StaticGraph from '../StaticGraph/StaticGraph';

type Props = {
  assetId: string,
}

function PeriodButton({ display, isActive, onClick, }) {
  return (
    <FelaComponent
      style={({ theme, }) => ({
        ...(isActive
          ? {
            backgroundColor: theme.color('primary'),
            color: theme.color('bg', 'base'),
            fontWeight: '700',
          }
          : {
            backgroundColor: theme.color('neutral'),
          }),
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        flexGrow: '1',
      })}
      onClick={onClick}
    >
      {({ className, }) => (
        <button className={className} onClick={onClick} type="button">
          {display}
        </button>
      )}
    </FelaComponent>
  );
}

function MobileGraphController({ assetId, }: Props) {
  const [ selectedPeriod, setPeriod, ] = useState('day');
  const [ selectedIndex, setIndex, ] = useState(0);

  const periods = [
    { value: 'day', display: 'יומי', },
    { value: 'week', display: 'שבועי', },
    { value: 'month', display: 'חודשי', },
    { value: 'year1', display: 'שנתי', },
    { value: 'year3', display: '3 שנים', },
    { value: 'year5', display: 'מקס', },
  ];

  function changeSelectedTime({ value, index, }) {
    setPeriod(value);
    setIndex(index);
  }

  return (
    <Fragment>
      <StaticGraph
        type="line"
        period={selectedPeriod}
        assetId={assetId}
        miscStyles={{
          marginStart: '-2rem',
          marginEnd: '-2rem',
        }}
      />
      <FelaComponent
        style={({ theme, }) => ({
          color: theme.color('neutral', '-5'),
          display: 'flex',
          marginBottom: '1rem',
          marginStart: '-2rem',
          marginEnd: '-2rem',
          ...theme.type(-1),
        })}
      >
        {
              periods.map(({ display, value, }, index) => (
                <PeriodButton
                  key={value}
                  display={display}
                  isActive={selectedIndex === index}
                  onClick={() => changeSelectedTime({ value, index, })}
                />
              ))
            }
      </FelaComponent>
    </Fragment>
  );
}

export default MobileGraphController;
