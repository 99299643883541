import React from 'react';
import PropTypes from 'prop-types';

const PremiumContentMetaHelper = ({ isPremiumContent, }) => (
  [
    <meta key="isPremiumContent" property="isPremiumContent" content={isPremiumContent.toString()} />,
    <meta key="article:content_tier" property="article:content_tier" content="free" />,
  ]
);

PremiumContentMetaHelper.propTypes = {
  isPremiumContent: PropTypes.bool.isRequired,
};

export default PremiumContentMetaHelper;
