import useArticleData from './useArticleData';
import useBreadcrumbsData from './useBreadcrumbsData';

export default function useBlogElement() {
  const data = useArticleData();
  const breadCrumbs = useBreadcrumbsData();

  const articleData = data || {};

  const { isShowDisclaimerElement, } = articleData || {};

  const isBlog = isShowDisclaimerElement === true;
  const blog = isBlog && breadCrumbs?.length ? breadCrumbs[1].name : null;

  return { isBlog, blog, };
}
