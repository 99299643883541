// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import HtzLink from '../HtzLink/HtzLink';
import Image from '../Image/Image';
import type { HTMLEmbedDataType, } from '../../flowTypes/HTMLEmbedDataType';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';
import type { ParagraphDataType, } from '../../flowTypes/ParagraphDataType';
import HtmlNode from '../HtmlNode/HtmlNode';
import ActionButtons from '../ActionButtons/ActionButtons';
import useMedia from '../../hooks/useMedia';
import useGetMediaComponent from '../../hooks/useGetMediaComponent';
import { isImage, isEmbed, } from '../../utils/validateType.js';
import { getEmbedProps, getImageProps, } from '../../utils/getMediaProps';

type Props = {
  exclusive: string,
  title: string,
  subtitle: string,
  cardBody: ParagraphDataType[],
  media: ImageDataType | HTMLEmbedDataType,
  url: string,
  linkText: string,
  isAnimate: Boolean,
};

let globalIndex = 0;

PromotionalCardElement.defaultProps = {
  exclusive: null,
  title: '',
  subtitle: '',
  cardBody: [],
  media: null,
  url: null,
  linkText: null,
  isAnimate: true,
};

const fadeInAnimation = {
  '0%': {
    visibility: 'visible',
    opacity: 0,
    transform: 'translateY(50%)',
  },
  '100%': {
    visibility: 'visible',
    opacity: 1,
    transform: 'translateY(0)',
  },
};

function cardStyle({ theme, isOdd, isAnimate, inView, }) {
  return {
    display: 'grid',
    gridTemplateAreas: `"header"
                        "media"
                        "content"`,
    gridGap: '4rem',
    marginBottom: '5rem',
    marginTop: '5rem',
    paddingBottom: '6rem',
    borderBottom: `1px solid ${theme.color('neutral', '-6')}`,
    visibility: isAnimate ? 'hidden' : null,
    extend: [
      isAnimate && inView ? {
        animationName: fadeInAnimation,
        animationTimingFunction: 'ease-out',
        animationFillMode: 'forwards',
        animationDuration: '1s',
        animationIterationCount: 1,
      } : {},
      theme.mq({ from: 'l', }, {
        gridGap: '2rem 6rem',
        borderBottom: 'none',
        paddingBottom: '4rem',
        gridTemplateColumns: isOdd ? '4fr 3fr' : '3fr 4fr',
        gridTemplateRows: '0 auto 1fr 0',
        gridTemplateAreas: isOdd
          ? `"   .     media"
             "header   media"
             "content  media"
             "   .     media"`
          : `"media      .   "
             "media   header"
             "media   content"
             "media      .   "`,
      }),
    ],
  };
}

function mediaStyle({ theme, }) {
  return {
    gridArea: 'media',
    overflow: 'hidden',
    ...theme.mq({ from: 'l', }, { marginTop: 0, }),
  };
}

function headerStyle({ theme, }) {
  return {
    gridArea: 'header',
  };
}

function contentStyle({ theme, }) {
  return {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
  };
}

function kickerStyle({ theme, }) {
  return {
    color: theme.color('commercial'),
    fontWeight: 700,
    marginBottom: '2rem',
    extend: [
      theme.type(-1, { untilBp: 'l', }),
      theme.type(-2, { fromBp: 'l', }),
    ],
  };
}

function titleStyle({ theme, }) {
  return {
    extend: [
      theme.type(6),
    ],
  };
}

function subtitleStyle({ theme, }) {
  return {
    extend: [
      theme.mq({ until: 'l', }, { marginTop: '1rem', }),
      theme.type(4, { until: 'l', }),
      theme.type(3, { from: 'l', }),
    ],
  };
}

function linkStyle({ theme, }) {
  return {
    marginTop: '2rem',
    fontWeight: 700,
    textDecoration: 'underline',
    extend: [
      theme.type(1, { untilBp: 'l', }),
      theme.type(0, { fromBp: 'l', }),
    ],
  };
}

function getMediaProps(
  media: ?(ImageDataType | HTMLEmbedDataType),
  theme: Object
): ?Object {
  if (media) {
    if (isImage(media)) {
      return getImageProps(media, {
        bps: theme.bps,
        aspect: 'square',
        sizes: [ { size: '516px', }, { size: '440px', }, { size: '350px', }, ],
        widths: [ 516, 440, 350, ],
      });
    }
    if (isEmbed(media)) return getEmbedProps(media);
  }

  return null;
}

export default function PromotionalCardElement({ exclusive, title, subtitle, cardBody, media, url, linkText, isAnimate, }: Props) {
  const [ index, setIndex, ] = React.useState(0);
  const isMobile = useMedia({ query: { until: 'l', }, });
  const getMediaComponent = useGetMediaComponent(Image);
  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: isMobile ? 0.2 : 0.6, });

  React.useEffect(() => {
    setIndex(globalIndex);
    globalIndex += 1;
  }, []);

  const isOdd = index % 2 !== 0;
  const { css, theme, } = useFela({ isOdd, isAnimate, inView, isMobile, });
  const MediaComponent = getMediaComponent(media.kind);
  const mediaProps = getMediaProps(media, theme);

  return (
    <article className={css(cardStyle)} ref={ref}>
      <header className={css(headerStyle)}>
        {exclusive
          ? <div className={css(kickerStyle)}>{exclusive}</div>
          : null}
        <div className={css(titleStyle)}>{title}</div>
        <div className={css(subtitleStyle)}>{subtitle}</div>
      </header>
      <div className={css(mediaStyle)}>
        <MediaComponent {...mediaProps} />
      </div>
      <div className={css(contentStyle)}>
        <div>
          {cardBody.map(val => (
            <HtmlNode
              miscStyles={{
                margin: '1rem 0',
              }}
              {...val}
            />
          ))}
        </div>
        {url && linkText
          ? <HtzLink href={url} className={css(linkStyle)}>{linkText}</HtzLink>
          : null}

        {url
          ? (
            <ActionButtons
              miscStyles={{ marginTop: '2rem', justifyContent: 'start', gap: '3rem', }}
              elementName={title}
              elementUrl={url}
              globalButtonsStyles={{
                color: theme.color('bg', 'base'),
                backgroundColor: theme.color('bodyText', 'base'),
                width: [ { until: 'l', value: '3.5rem', }, { from: 'l', value: '3rem', }, ],
                minWidth: [ { until: 'l', value: '3.5rem', }, { from: 'l', value: '3rem', }, ],
                height: [ { until: 'l', value: '3.5rem', }, { from: 'l', value: '3rem', }, ],
                minHeight: [ { until: 'l', value: '3.5rem', }, { from: 'l', value: '3rem', }, ],
                borderRadius: '50%',
                overflow: 'hidden',
                paddingInlineStart: '0',
                paddingInlineEnd: '0',
                ':hover': {
                  color: theme.color('bg', 'base'),
                },
              }}
              buttons={[ { name: 'facebook', round: true, }, { name: 'twitter', }, 'whatsapp', ]}
              size={3}

            />
          )
          : null}
      </div>
    </article>
  );
}
