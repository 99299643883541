/* global sessionStorage localStorage window */
import React, { useCallback, } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useFela, } from 'react-fela';
import { useApolloClient, } from 'react-apollo';
import useOneTime from '../../hooks/useOneTime';
import useBackToSection from '../../hooks/useBackToSection';
import AriaLive from '../AriaLive/AriaLive';
import DeviceTypeInjector from '../DeviceTypeInjector/DeviceTypeInjector';
import PageSchema from '../PageSchema/PageSchema';
import RouteChangeListener from '../EventListeners/RouteChangeListener';
import PremiumContentMeta from '../PremiumContentMeta/PremiumContentMeta';
import UserInjector from '../User/UserInjector';
import FacebookAppId from '../FacebookAppId/FacebookAppId';
import LastArticlesUpdate from '../LastArticlesUpdate/LastArticlesUpdate';
import OmnyDataCheck from '../AudioPlayerWithActions/OmnyPodcast/OmnyDataCheck';
import PermutiveArticlePage from '../Scripts/Permutive/PermutiveArticlePage';
import UserCookieValidation from '../UserCookieValidation/UserCookieValidation';
import { writeToLocalStorage, writeToSessionStorage, } from '../../utils/writeToStorage';
import { updateTeasersInPage, } from '../List/ListDuplication';
import ArticleChartbeatConfig from '../Scripts/ArticleChartbeatConfig';
import { GetComponentProvider, } from '../../hooks/GetComponentContext/useGetComponent.js';
import useSeoData from '../../hooks/Page/useSeoData';
import MainArticleLayout from './MainArticleLayout';
import Scripts from '../Scripts/Scripts';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import { useIsBot, } from '../../hooks/useIsBot';
import WebViewInclude from '../WebViewInclude/WebViewInclude';
import FontSizeManagerWebView from '../FontSizeManagerWebView/FontSizeManagerWebView';
import ArticleSeo from '../ArticleSeo/ArticleSeo';

import useArticleId from '../../hooks/Page/useArticleId';
import useArticleType from '../../hooks/Page/useArticleType';
import useArticleHeaderData from '../../hooks/Page/useArticleHeaderData';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import useAuthorsData from '../../hooks/Page/useAuthorsData';
import InitArticle from './InitArticle';
import usePaywallType from '../../hooks/Page/usePaywallType';
import useIsLabel from '../../hooks/Page/useIsLabel';
import useBlogElement from '../../hooks/Page/useBlogElement';
import useIsBlock from '../../hooks/useIsBlock';
import ArticleDataGetter from './ArticleDataGetter';
import useScripts from '../../hooks/Page/useScripts';
import useTagsData from '../../hooks/Page/useTagsData';
import { AnalyticsBS, } from '../AnalyticsBS/AnalyticsBS';
import usePreview from '../../hooks/Page/usePreview';
import { useEventTracker, } from '../../utils/EventTracker';

const RecipeArticle = dynamic(() => import('../ArticleTypes/RecipeArticle/RecipeArticle'));
const ReviewArticle = dynamic(() => import('../ArticleTypes/ReviewArticle/ReviewArticle'));
const MagazineArticle = dynamic(() => import('../ArticleTypes/MagazineArticle/MagazineArticle'));
const FaceArticle = dynamic(() => import('../ArticleTypes/Face/FaceArticle'));
const LiveBlogArticle = dynamic(() => import('../ArticleTypes/LiveBlogArticle/LiveBlogArticle'));
const PodcastArticle = dynamic(() => import('../ArticleTypes/PodcastArticle/PodcastArticle'));
const TimelineArticle = dynamic(() => import('../ArticleTypes/TimelineArticle/TimelineArticle'));
const StoryArticle = dynamic(() => import('../ArticleTypes/StoryArticle/StoryArticle'));
const StandardArticle = dynamic(() => import('../ArticleTypes/StandardArticle/StandardArticle'));
const GameArticle = dynamic(() => import('../ArticleTypes/GameArticle/GameArticle'));
const ConferenceArticle = dynamic(() => import('../ArticleTypes/ConferenceArticle/ConferenceArticle'));
const TwentyQuestionsArticle = dynamic(() => import('../ArticleTypes/TwentyQuestionsArticle/TwentyQuestionsArticle'));
const RiddleArticle = dynamic(() => import('../ArticleTypes/RiddleArticle/RiddleArticle'));

const getArticleComponent = new Map([
  [ 'recipeArticle', RecipeArticle, ],
  [ 'reviewArticle', ReviewArticle, ],
  [ 'magazineArticle', MagazineArticle, ],
  [ 'liveBlogArticle', LiveBlogArticle, ],
  [ 'podcastArticle', PodcastArticle, ],
  [ 'timelineArticle', TimelineArticle, ],
  [ 'storyArticle', StoryArticle, ],
  [ 'gameArticle', GameArticle, ],
  [ 'twentyQuestionsArticle', TwentyQuestionsArticle, ],
  [ 'riddleArticle', RiddleArticle, ],
  [ 'standardArticle', StandardArticle, ],
  [ 'faceArticle', FaceArticle, ],
  [ 'conferenceArticle', ConferenceArticle, ],
]);

const GoogleAnalytics = dynamic(import('../GoogleAnalytics/GoogleAnalytics'), {
  ssr: false,
  loading: () => null,
});

const AbuseCheck = dynamic(import('../User/AbuseCheck'), {
  loading: () => null,
  ssr: false,
});

const AdBlockRedirect = dynamic(import('../AdBlock/AdBlockRedirect'), {
  loading: () => null,
  ssr: false,
});

const propTypes = {
  /**
   * An object containing route information from Next, such as the `pathname`
   * and `query` object.
   */
  url: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({
      path: PropTypes.string.isRequired,
      articleType: PropTypes.string.isRequired,
      isBlock: PropTypes.bool,
      isPreview: PropTypes.bool,
      isBot: PropTypes.bool,
    }).isRequired,
    asPath: PropTypes.string,
  }).isRequired,
  responseWriteHead: PropTypes.func,
  responseEnd: PropTypes.func,
  getElements: PropTypes.func.isRequired,
  site: PropTypes.oneOf([ 'htz', 'tm', 'hdc', ]).isRequired,
  articleData: PropTypes.Object,
};
const defaultProps = {
  responseWriteHead: null,
  responseEnd: null,
  site: 'htz',
  articleData: null,
};

function Article({ url, responseWriteHead, responseEnd, getElements, site, articleData, }) {
  const isWebView = useWebViewChecker();
  const isBlock = useIsBlock();
  const { biAction, } = useEventTracker();

  const { backButtonUserType,
    backButtonSectionUrl,
    backButtonNumberOfVisits,
    backButtonEnabled,
    appSection, } = articleData?.Page?.slots?.article || {};

  const {
    asPath,
    query: { path, loglevel, },
  } = url;

  const articleId = useArticleId();
  const { isBot, } = useIsBot();


  useOneTime(site && site === 'hdc', () => {
    window.__HTZ = window.__HTZ || {};
    try {
      if (typeof window.__HTZ.cancelRefresh === 'function') {
        window.__HTZ.cancelRefresh();
      }

      const refreshTimer = setTimeout(
        () => {
          if (typeof window !== 'undefined') {
            setTimeout(() => {
              window.localStorage.setItem('refresh', 'true');
              window.location.reload();
            }, 1500);
          }
        },
        3600000
      );
      window.__HTZ.cancelRefresh = function cancelRefresh() {
        clearTimeout(refreshTimer);
        window.__HTZ.cancelRefresh.activated = true;
        window.localStorage.removeItem('refresh');
      };
    }
    catch (err) { console.log(err); }
  }
  );

  useOneTime(typeof window !== 'undefined' && window.localStorage.getItem('refresh'), () => {
    biAction({
      actionCode: 4,
      feature: 'Auto refresh page',
      featureType: 'Content',
    });
    window.localStorage.removeItem('refresh');
  });

  // TODO: remove before deploy to prod
  const articleType = useArticleType();
  const isLabel = useIsLabel();

  const { paywallType, isFree, isSuperContent, isPremiumContent, isOpen, } = usePaywallType();
  const { isPreview, } = usePreview();
  const { isBlog, blog, } = useBlogElement();

  if (loglevel === 'debug') {
    console.log('!!!!articleId', articleId);
    console.log('!!!!articleType', articleType);
    console.log('!!!!paywallType', paywallType, { isFree, isSuperContent, isPremiumContent, isOpen, });
    console.log('!!!!isLabel', isLabel);
    console.log('!!!!blogElement', { isBlog, blog, });
    console.log('!!!!articleData', articleData);
  }

  const ArticleTypeComponent = getArticleComponent.get(articleType) || StandardArticle;

  const writeToSession = useCallback(() => {
    try {
      const history = JSON.parse(sessionStorage.getItem('readingHistory')) || [];
      const idIndex = history.indexOf(articleId);
      if (idIndex > -1) {
        history.splice(idIndex, 1);
      }

      updateTeasersInPage([ { contentId: articleId, }, ]);

      history.unshift(articleId);
      writeToSessionStorage({
        key: 'readingHistory',
        value: JSON.stringify(history, null, 2),
        errorMessage: 'error writing reading history to session storage ',
      });
    }
    catch (e) {
      console.error(e);
    }
  }, [ articleId, ]);

  const writeToLocal = useCallback(() => {
    try {
      const history = JSON.parse(localStorage.getItem('readingHistory')) || [];
      const historyCount = JSON.parse(localStorage.getItem('readingHistoryCount')) || 0;
      writeToLocalStorage({
        key: 'readingHistoryCount',
        value: historyCount + 1,
        errorMessage: 'error writing reading history count',
      });
      const idIndex = history.indexOf(articleId);

      if (idIndex > -1) {
        history.splice(idIndex, 1);
      }

      history.unshift(articleId);
      // keep history length to max of 50
      if (history.length > 50) history.shift();
      writeToLocalStorage({
        key: 'readingHistory',
        value: JSON.stringify(history, null, 2),
        errorMessage: 'error writing reading history to session storage ',
      });
    }
    catch (e) {
      console.error(e);
    }
  }, [ articleId, ]);

  const { theme, css, } = useFela();

  const client = useApolloClient();
  const seoData = useSeoData();
  const header = useArticleHeaderData();
  const breadcrumbs = useBreadcrumbsData();
  const authors = useAuthorsData();
  const scripts = useScripts();
  const tags = useTagsData();

  useBackToSection({ enabled: backButtonEnabled,
    sectionUrl: (backButtonSectionUrl || ''),
    numberOfVisits: backButtonNumberOfVisits,
    userType: backButtonUserType,
  });

  // const {
  //   pageType,
  //   slots,
  //   articleData,
  //   lineage,
  //   jsonld,
  //   pageDateTimeString,
  //   seoData,
  //   redirectUri,
  //   scripts,
  // } = {};

  const { jsonLinkedData, } = articleData?.Page || {};

  const titleSEO = (seoData && seoData.title)
  || `${breadcrumbs?.[0]?.name} - ${breadcrumbs?.[1] ? breadcrumbs[1]?.name : ''} - ${breadcrumbs?.length > 2 ? breadcrumbs?.[breadcrumbs?.length - 1]?.name : ''
  }`;

  const titleSEOWebView = breadcrumbs?.[0]?.name || seoData?.title || '';

  const title = isWebView ? titleSEOWebView : titleSEO;

  const twitterTitle = (seoData && seoData.twitterTitle);
  // || (articleData && articleData.contentName); // TODO: not exist

  const shareUrl = seoData ? (seoData.ogUrl || seoData.canonicalLink) : null;

  // const isPremiumContent = seoData ? seoData.isPremiumContent : null;

  // const isSuperContent = true; // articleData ? articleData.isSuperContent : false; // TODO: not exist

  const photoBlogChannel = articleData?.Page?.slots?.article?.photoBlogChannel;

  const isPhotoBlog = !!photoBlogChannel;

  const coverColor = articleData?.coverColor || theme.color('bg', 'base');

  const commentsElementId = articleData ? articleData.commentsElementId : null;

  const pageDateTimeString = articleData?.pageDateTimeString;

  const authorsName = Array.isArray(authors)
    ? authors.map(item => item.name).join(', ')
    : '';
  const authorsContentId = Array.isArray(authors)
    ? authors.map(item => item.contentId).join(', ')
    : '';

  // // the articleType passed from server may not be equal to this articleType
  // // (e.g. `regularArticle` and `blogArticle` are both mapped to `standardArticle`).
  const specificArticleType = articleType || null;

  client.writeData({
    data: {
      pageType: 'Article',
      articleType: specificArticleType,
      articleId,
      authorsContentId,
      path,
      isCloseArticle: isBlock,
      canonicalUrl: seoData ? seoData.canonicalUrl : null,
      commentsElementId,
      isBlocked: isBlock,
      isBot,
      isPremiumContent,
      isSuperContent,
      title,
      twitterTitle,
      pageDateTimeString,
      // place properties to reset in the client store when a new article is loaded
      isOsakaDisplayed: false,
      isLabel,
    },
  });
  const tagContentNames = Array.isArray(tags) ? tags.map(item => item.name) : [];
  const articlePubDate = header && header.datePublishedISO;

  const includeExclusive = header && header.includeExclusiveInTitle
    ? header.exclusive
    : null;


  // // category could be News/World/Sport etc..
  const permutiveCategory = (breadcrumbs || []).reduce(
    (prevVal, currVal, idx) => (idx === 0 && !currVal.pathSegment ? '' : `${currVal.pathSegment}/${prevVal}`),
    ''
  );

  const titleText = `${title}${isWebView ? '' : theme.seoI18n.titlePrefix}`;
  return (
    <GetComponentProvider value={getElements}>
      <Head>
        <title>
          {titleText}
        </title>
      </Head>
      <ArticleSeo />
      <InitArticle
        writeToLocal={writeToLocal}
        writeToSession={writeToSession}
        articleId={articleId}
      />
      <WebViewInclude>
        <Head>
          <meta name="id" content={articleId} />
          {appSection ? [
            <meta key="app-section-name" name="app-section-name" content={appSection?.name || ''} />,
            <meta key="app-section-url" name="app-section-url" content={appSection?.url || ''} />,
          ] : null }
        </Head>
      </WebViewInclude>
      <WebViewExclude>
        <Scripts scriptTag="chartBeat">
          <ArticleChartbeatConfig
            section={breadcrumbs?.[1]?.name}
            author={authorsName}
            site={site}
          />
        </Scripts>
      </WebViewExclude>

      {// render <PremiumContentMeta/> only when isPremiumContent is defined
        isPremiumContent !== null ? (
          <PremiumContentMeta isPremiumContent={isPremiumContent} />
        ) : null}
      <WebViewExclude>
        <FacebookAppId site={site} />
      </WebViewExclude>
      <RouteChangeListener />
      <WebViewInclude>
        <FontSizeManagerWebView />
      </WebViewInclude>
      <UserInjector />
      {isLabel ? null : <AdBlockRedirect />}
      {/* <WebViewExclude>
        <GoogleAnalytics withEC />
      </WebViewExclude> */}
      <LastArticlesUpdate />
      <OmnyDataCheck />
      {!isWebView && site === 'htz' && !isPreview ? <AbuseCheck /> : null}
      <>
        <AriaLive />
        <DeviceTypeInjector />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            fontFamily: isLabel ? theme.fontStacks.commercial : null,
          })}
        >
          {[
            'magazineArticle',
            'storyArticle',
            'timelineArticle',
            'riddleArticle',
            'twentyQuestionsArticle',
            'faceArticle',
            'conferenceArticle',
          ].includes(articleType) ? (
            <ArticleTypeComponent
              isPhotoBlog={isPhotoBlog}
              photoBlogChannel={photoBlogChannel}
              coverColor={coverColor}
              path={path}
              asPath={asPath}
              articleData={articleData}
              site={site}
              shareUrl={shareUrl}
              canonicalUrl={seoData && seoData.canonicalUrl}
              showTrinityPlayer={scripts && scripts.scriptsToRender && scripts.scriptsToRender.includes('trinityPlayer')}
            />
            ) : (
              <MainArticleLayout
                path={path}
                isBlock={isBlock}
                site={site}
              >
                <ArticleTypeComponent
                  isPhotoBlog={isPhotoBlog}
                  path={path}
                  shareUrl={shareUrl}
                  showTrinityPlayer={scripts && scripts.scriptsToRender && scripts.scriptsToRender.includes('trinityPlayer')}
                  site={site}
                  canonicalUrl={seoData && seoData.canonicalUrl}
                />
              </MainArticleLayout>
            )}
        </div>
        <UserCookieValidation articleId={articleId} site={site} />
      </>
      {isWebView ? null : (
        <Scripts scriptTag="permutive">
          <PermutiveArticlePage
            tags={tagContentNames}
            titleSEO={titleSEO}
            category={permutiveCategory}
            authors={authorsName}
            pubDate={articlePubDate}
            description={seoData && seoData.metaDescription}
          />
        </Scripts>
      )}
      {jsonLinkedData ? <PageSchema jsonld={jsonLinkedData} /> : null}
      <AnalyticsBS />
    </GetComponentProvider>
  );
}

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default function (props) {
  const {
    url,
    responseEnd,
    responseWriteHead,
  } = props || {};

  const {
    asPath,
    query: { path, },
  } = url;

  return (
    <ArticleDataGetter
      asPath={asPath}
      path={path}
      responseEnd={responseEnd}
      responseWriteHead={responseWriteHead}
    >
      {({ articleData, }) => <Article {...props} articleData={articleData} />}
    </ArticleDataGetter>
  );
}
