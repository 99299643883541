// @flow
import React from 'react';
import { useFela, } from 'react-fela';

import type { ChildrenArray, Node, } from 'react';

import ArticleLayout from '../PageLayout/ArticleLayout';
import LayoutContainer from '../PageLayout/LayoutContainer';
import useArticleType from '../../hooks/Page/useArticleType';

type Props = {
  children: ChildrenArray<Node> | Node,
  path: string,
  isBlock: boolean,
}

function MainArticleLayout({ children, ...props }: Props) {
  const { theme, } = useFela();
  const articleType = useArticleType();

  return (
    <ArticleLayout {...props}>
      <LayoutContainer
        tagName="article"
        {... articleType === 'liveBlogArticle'
          ? {
            bgc: theme.color('bg', 'base'),
          }
          : {}
        }
        miscStyles={
          articleType === 'standardArticle' || articleType === 'liveBlogArticle'
            ? {
              display: [ { from: 'l', value: 'flex', }, ],
            }
            : {}
        }
      >
        {children}
      </LayoutContainer>
    </ArticleLayout>
  );
}

export default MainArticleLayout;
