import React, { Component, Fragment, } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import { useFela, FelaComponent, } from 'react-fela';
import Comment from './Comment.js';
import Media from '../Media/Media';
import AdSlot from '../AdManager/AdSlot';
import { useAdUnitPathTemplate, createAdSlotPath, } from '../AdManager/utils';

/**
 * Defines how far away ads can be inserted (every X comments)
 * @param {number} - adSpacing - The minimal number of comments between each ad position
 */
const AD_SPACING = 5;
const MAX_AD_SLOTS = 5;

const wrapperStyle = ({ theme, }) => ({
  backgroundColor: theme.color('bg', 'base'),
});

const adUnitCodeFromIndex = idx => `${config.get('domain')}.fullbanner.talkback.${idx}.desktop`;

const commentsVerticalMargin = '4rem';

// eslint-disable-next-line react/prop-types
const CommentAdSlot = ({ adSlotId: adUnitCode, }) => {
  const { theme, css, } = useFela();
  const adUnitPathTemplate = useAdUnitPathTemplate();
  const adUnitPath = createAdSlotPath(adUnitPathTemplate, adUnitCode);

  return (
    <AdSlot
      className={css({
        marginTop: commentsVerticalMargin,
        marginBottom: commentsVerticalMargin,
      })}
      adCaption={theme.commentI18n.commercial}
      adUnitPath={adUnitPath}
      sizes={[ [ 468, 60, ], ]}
      sizeMapping={[
        { viewport: [ 0, 0, ], sizes: [], },
        { viewport: [ 600, 100, ], sizes: [ [ 468, 60, ], ], },
      ]}
    />
  );
};

const placeAdSlot = commentIdx => {
  const adIdx = Math.floor(commentIdx / AD_SPACING) + 1;
  const isEnoughSpacing = (commentIdx + 1) % AD_SPACING === 0;
  return isEnoughSpacing && adIdx <= MAX_AD_SLOTS ? (
    <CommentAdSlot adSlotId={adUnitCodeFromIndex(adIdx)} />
  ) : null;
};

class CommentList extends Component {
  static propTypes = {
    /**
     * An Array of comment objects
     */
    comments: PropTypes.arrayOf(PropTypes.object),
    /**
     * An Object holding the Plus Rates of all the comments in the `CommentsElement`.
     * The Object has an id key for each comment that has at least one Plus vote.
     * The corresponding value is the rate of Plus votes the comment has.
     */
    commentsMinusRate: PropTypes.shape({
      id: PropTypes.number,
    }),
    /**
     * An Object holding the Minus Rates of all the comments in the `CommentsElement`.
     * The Object has an id key for each comment that has at least one Minus vote.
     * The corresponding value is the total number of Minus votes the comment has.
     */
    commentsPlusRate: PropTypes.shape({
      id: PropTypes.number,
    }),
    /**
     * A callback passed on to the reply `<CommentForm />`
     * @param {String} commentAuthor - the new comment author
     * @param {String} commentTextHtml - the new comment text innerHTML
     * @param {String} parentCommentId - the parent CommentId - defaults to '0' if there is no `parentCommentId`
     */
    initNewComment: PropTypes.func,
    /**
     * A callaback that initiates a vote, `<Likes />` sends up the commentId and the rate ("plus"/"minus")
     */
    initVote: PropTypes.func.isRequired,
    /** True when a CommentList renders subComments */
    isSubComment: PropTypes.bool,
    /**
     * A callback that causes the parent `<Comment />` to open its reply `<CommentForm />`
     * Replying to comments can only go one level deep so when replying to subComments,
     * we are actually replying to the parent of the subComment
     */
    openParentReplyForm: PropTypes.func,
    /** The author of the parent comment, null if it is not a subComment list */
    parentAuthor: PropTypes.string,
    /**
     * A CallBack that sends up the the commentId in order to report the Comment as abusive
     * @param {String} commentId
     * @returns {Promise} A promise fullfilled with an object of shape { reportSuccess: Boolean }
     */
    reportAbuse: PropTypes.func.isRequired,
    /**
     * A Boolean indicating that a comment abuse report may not be
     * proccessed at this time
     */
    reportAbuseDisabled: PropTypes.bool,
    /**
     * A callback that gets the called when submitting the sign up to notification form in `<CommentSent />`
     * @param {String} - notificationEmail - The email the user entered
     */
    signUpNotification: PropTypes.func,
    userType: PropTypes.string,
    /**
     * Disables the Reply button
     */
    disableReply: PropTypes.bool,
    /**
     * enable / disable choosing nickname
     */
    enableNickname: PropTypes.bool,
    /**
     * Creates a ref callback by invoking with the comment's index.
     */
    createCommentWrapperRef: PropTypes.func,
    isWebView: PropTypes.bool.isRequired,
    isPageLoaded: PropTypes.bool.isRequired,
    subSingleCommentId: PropTypes.string,
    isSingleComment: PropTypes.bool,
  };

  static defaultProps = {
    comments: [],
    isSubComment: false,
    commentsPlusRate: {},
    commentsMinusRate: {},
    openParentReplyForm: undefined,
    parentAuthor: null,
    isSingleComment: null,
    subSingleCommentId: null,
    initNewComment: undefined,
    signUpNotification: undefined,
    userType: null,
    disableReply: false,
    enableNickname: true,
    reportAbuseDisabled: false,
    createCommentWrapperRef: () => null,
  };

  state = {};

  render() {
    const {
      comments,
      parentAuthor,
      commentsPlusRate,
      commentsMinusRate,
      initVote,
      reportAbuse,
      reportAbuseDisabled,
      initNewComment,
      signUpNotification,
      openParentReplyForm,
      isSubComment,
      userType,
      disableReply,
      enableNickname,
      createCommentWrapperRef,
      isWebView,
      isPageLoaded,
      isSingleComment,
      subSingleCommentId,
    } = this.props;
    return (
      <FelaComponent style={wrapperStyle}>
        {({ className, theme: { bps, typeConf, }, }) => (
          <div className={className} data-test="commentsContainer">
            {comments.map((comment, idx) => (
              <Fragment key={comment.commentId}>
                <Comment
                  wrapperRef={createCommentWrapperRef(idx)}
                  bps={bps}
                  typeConf={typeConf}
                  key={comment.commentId}
                  commentId={comment.commentId}
                  author={comment.author}
                  title={comment.title}
                  authorNickname={comment.authorNickname}
                  authorRate={comment.authorRate}
                  commentText={comment.commentText}
                  parentAuthor={parentAuthor}
                  publishingDateForDisplay={comment.publishingDateForDisplay}
                  commentNumber={comment.number || ''}
                  subComments={comment.subComments}
                  isEditorPick={comment.isEditorPick}
                  // need to pass these down in case subComments have plus or minus rates
                  commentsPlusRate={commentsPlusRate}
                  commentsMinusRate={commentsMinusRate}
                  initVote={initVote}
                  reportAbuse={reportAbuse}
                  reportAbuseDisabled={reportAbuseDisabled}
                  initNewComment={initNewComment}
                  signUpNotification={signUpNotification}
                  openParentReplyForm={openParentReplyForm}
                  isSubComment={isSubComment}
                  isFirstSubComment={isSubComment && idx === 0}
                  isLastSubComment={isSubComment && idx === comments.length - 1}
                  userType={userType}
                  disableReply={disableReply}
                  enableNickname={enableNickname}
                  isWebView={isWebView}
                  isPageLoaded={isPageLoaded}
                  likes={comment.likes}
                  dislikes={comment.dislikes}
                  isSingleComment={isSingleComment}
                  subSingleCommentId={subSingleCommentId}
                />
                <Media
                  query={{ from: 'm', }}
                  render={() => (isSubComment ? null : placeAdSlot(idx))}
                />
              </Fragment>
            ))}
          </div>
        )}
      </FelaComponent>
    );
  }
}

export default CommentList;
