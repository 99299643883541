// @flow

import getElementsFactory from '../../../utils/getElementsFactory';

import MarketingMultiTypeElement from '../elements/MarketingMultiTypeElement';

const elements = new Map(
  [
    [ 'MultiTypeMarketingElement', MarketingMultiTypeElement, ],
  ]
);

export default getElementsFactory(elements);
