// @flow

import * as React from 'react';

import NoSSR from '../NoSSR/NoSSR';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import RainbowLogger from './RainbowLogger';

import getElement from './utils/getRainbowElements';


type PropType = {
  tool: any,
  /** extra styles to pass to the rendered rainbow element */
  miscStyles?: StyleProps,
}

/**
 * Render a component by its input-template
 * @param {Object} data the PAPI data for the component
 * @param {Object} miscStyles responsive styles to pass for the rendered tool
 */
function renderRainbowTool(data, miscStyles) {
  const Element = getElement(data?.element?.inputTemplate);
  const metaData = data?.metaData?.entries?.reduce((acc, entry) => ({
    ...acc,
    [entry.key]: entry.value,
  }), {});

  const [ abTestName, abGroup, ] = (data?.testGroup || '').split(':');
  let abTestData;

  if (abTestName && abGroup) {
    abTestData = { abTestName, abGroup, };
  }

  return (
    <RainbowLogger key={data.toolId} elementId={data.toolId} testGroup={data.testGroup}>
      <Element {...data?.element} metaData={metaData} abTestData={abTestData} miscStyles={miscStyles} />
    </RainbowLogger>
  );
}

RainbowTool.defaultProps = {
  miscStyles: null,
};

export default function RainbowTool({ tool, miscStyles, }: PropType) {
  return (
    <NoSSR>
      <WebViewExclude>
        {renderRainbowTool(tool, miscStyles,)}
      </WebViewExclude>
    </NoSSR>
  );
}
