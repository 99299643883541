// @flow
import React, { Fragment, useState, useEffect, } from 'react';
import { useFela, } from 'react-fela';
// import { Select, IconLinechart, IconScatterchart, } from '@haaretz/htz-components';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';

import Select from '../Select/Select';
import IconLinechart from '../Icon/icons/IconLinechart';
import IconScatterchart from '../Icon/icons/IconScatterchart';

import Tab from '../Tab/Tab';
import TabList from '../TabList/TabList';
import TabPanel from '../TabPanel/TabPanel';
import Graph from '../Graph/Graph';
import StockStats from '../AssetStats/AssetStats';
import Tabs from '../Tabs/Tabs';

import type { Period, } from '../AssetStats/AssetStats';

export type Props = {
  assetId: string,
  scatter: boolean,
  miscStyles: ?Object,
  width?: number,
  height?: number,
  margin?: { top: number, right: number, bottom: number, left: number, },
  periods: ?Array<{ display: string, value: Period, }>,
  disableScatter?: boolean,
  disableTimeTabs?: boolean,
  loadingHeight?: ?number,
};

const tabRule: Object => Object = ({ theme, isActive, }) => ({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingInlineStart: '2rem',
  paddingInlineEnd: '2rem',
  position: 'relative',
  flexGrow: 1,
  textAlign: 'center',
  ...(isActive
    ? {
      backgroundColor: theme.color('primary'),
      color: theme.color('neutral', '-10'),
      fontWeight: '700',
      ':not(:first-of-type)': {
        ':before': {
          content: '""',
          position: 'absolute',
          top: '0',
          start: '-1px',
          height: '100%',
          width: '1px',
          backgroundColor: theme.color('primary'),
        },
      },
    }
    : {
      backgroundColor: theme.color('neutral', '-1'),
    }),
  ':not(:last-of-type)': {
    ':after': {
      content: isActive ? '' : '""',
      position: 'absolute',
      top: 'calc(50% - 1px)',
      end: '0',
      height: '50%',
      width: '1px',
      transform: 'translateY(-50%)',
      backgroundColor: 'currentColor',
    },
  },
  '& > button': {
    outline: 'none',
  },
});

type TabCompProps = {
  index: number,
  isActive: boolean,
  onClick: () => void,
  setActive: number => void,
  display: string,
  value: string,
};

function TabComp({ index, isActive, onClick, setActive, display, value, }: TabCompProps): Node {
  return (
    <Tab
      index={index}
      isActive={isActive}
      controls={`graph-${value}`}
      presentation
      rule={tabRule}
      setActiveTab={setActive}
      onClick={onClick}
    >
      <span>{display}</span>
    </Tab>
  );
}

const defaultTabs = [
  { value: 'day', display: 'יומי', },
  { value: 'week', display: 'שבועי', },
  { value: 'month', display: 'חודשי', },
  { value: 'year1', display: 'שנתי', },
  { value: 'year3', display: '3 שנים', },
  { value: 'year5', display: 'מקסימום', },
];

const iconMiscStyles = {
  marginTop: '-0.5rem',
  marginEnd: '0.5rem',
  position: 'relative',
  top: '0.5rem',
};
const GRAPHS = {
  line: {
    value: 'line',
    display: (
      <span>
        <IconLinechart
          miscStyles={iconMiscStyles}
          size={[ { until: 'xl', value: 4, }, { from: 'xl', value: 3, }, ]}
        />
        גרף קוי
      </span>
    ),
  },
  scatter: {
    value: 'scatter',
    display: (
      <span>
        <IconScatterchart
          miscStyles={iconMiscStyles}
          size={[ { until: 'xl', value: 4, }, { from: 'xl', value: 3, }, ]}
        />
        גרף פיזור
      </span>
    ),
  },
};

GraphController.defaultProps = {
  miscStyles: null,
  width: 574,
  height: 308,
  margin: { top: 34, right: 10, bottom: 15, left: 60, },
  scatter: false,
  periods: null,
  disableScatter: false,
  disableTimeTabs: false,
  loadingHeight: null,
};
function GraphController({
  assetId,
  miscStyles,
  width,
  height,
  margin,
  scatter,
  periods,
  disableScatter,
  disableTimeTabs,
  loadingHeight,
}: Props): Node {
  const [ selectedPeriod, setPeriod, ] = useState(Array.isArray(periods) ? periods[0].value : 'year1');
  const [ selectedIndex, setIndex, ] = useState(periods
    ? periods.findIndex(period => period.value === selectedPeriod)
    : 3);
  const [ selectedGraph, setGraph, ] = useState(GRAPHS.line);
  const { css, theme, } = useFela();

  useEffect(() => {
    setGraph(GRAPHS.line);
  }, [ scatter, ]);

  const changeSelectedTime = ({
    period,
    index,
  }: {
    period: Period, // eslint-disable-line react/no-unused-prop-types
    index: number, // eslint-disable-line react/no-unused-prop-types
  }): void => {
    setPeriod(period);
    setIndex(index);
  };

  return (
    <TabPanel
      className={css({
        extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
      })}
      id={`stock-${assetId}`}
    >
      <Tabs activeTab={selectedIndex}>
        {({ setActiveTab, activeTab, }) => (
          <Fragment>
            {!disableTimeTabs && (
              <TabList
                miscStyles={{
                  color: theme.color('neutral', '-5'),
                  display: 'flex',
                  marginBottom: '1rem',
                  ...theme.type(-1, { fromBp: 'xl', }),
                }}
              >
                {[ ...(periods || defaultTabs), ].map((tab, index) => (
                  <TabComp
                    index={index}
                    onClick={() => changeSelectedTime({ period: tab.value, index, })}
                    isActive={activeTab === index}
                    display={tab.display}
                    value={tab.value}
                    setActive={index => setActiveTab(index)}
                  />
                ))}
                <div
                  style={{
                    flexGrow: '1',
                    display: scatter && !disableScatter ? 'flex' : 'none',
                  }}
                >
                  {scatter && !disableScatter ? (
                    <Select
                      onChange={selectedItem => {
                        setGraph(selectedItem);
                      }}
                      controlledSelectedItem={selectedGraph}
                      variant="graph"
                      items={[ GRAPHS.line, GRAPHS.scatter, ]}
                      attrs={{
                        'aria-hidden': true,
                        className: css({ flexGrow: 1, display: 'flex', }),
                      }}
                      wrapperMiscStyles={{
                        flexGrow: 1,
                        display: 'flex',
                      }}
                      miscStyles={{
                        flexGrow: '1',
                        display: 'flex',
                        '& button:only-of-type': { fontWeight: 'bold', },
                      }}
                      buttonMiscStyles={{
                        paddingBottom: '0.5rem',
                        paddingTop: '0.5rem',
                        paddingStart: '0',
                        whiteSpace: 'nowrap',
                        minWidth: '15rem',
                        width: '100%',
                        ...theme.type(-1, { untilBp: 'xl', }),
                        ...theme.type(-2, { fromBp: 'xl', }),
                      }}
                    />
                  ) : null}
                </div>
              </TabList>
            )}
            <TabPanel id={`graph-${selectedPeriod}`}>
              <StockStats
                period={selectedPeriod}
                graphType={selectedGraph.value}
                render={({ changeStats, }) => (assetId ? (
                  <Graph
                    type={selectedGraph.value}
                    indexId={assetId}
                    time={selectedPeriod}
                    changeStats={changeStats}
                    width={width}
                    height={height}
                    margin={margin}
                    loadingHeight={loadingHeight}
                  />
                ) : null)
                }
                miscStyles={{
                  marginBottom: '2px',
                }}
              />
            </TabPanel>
          </Fragment>
        )}
      </Tabs>
    </TabPanel>
  );
}

export default GraphController;
