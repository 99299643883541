/* global fetch localStorage */

import React from 'react';
import { useEventTracker, } from '../utils/EventTracker';
import useOneTime from './useOneTime';
import { useUser, } from '../components/User/UserDispenser';
import { writeToLocalStorage, } from '../utils/writeToStorage';

const RENEWED_KEY = 'renewedDateV2';

function useRenew() {
  const [ renewFetched, setRenewFetched, ] = React.useState(null);
  const { biImpression, } = useEventTracker();
  const { isLoggedIn, } = useUser();

  useOneTime(true, () => {
    setRenewFetched(!!localStorage.getItem(RENEWED_KEY));
  });

  useOneTime(typeof biImpression === 'function' && isLoggedIn && renewFetched === false, () => {
    fetch('/renew', {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        writeToLocalStorage({ key: RENEWED_KEY, value: Date.now().toString(), });
        biImpression({
          feature: 'Authorization cookie',
          featureType: 'Personal',
          campaignName: data?.renewed === true ? 'New cookie created' : 'Cookie already created',
        });
      });
  }, [ biImpression, ]);
}

export default useRenew;
