import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseTypographyProp, } from '@haaretz/htz-css-tools';
import Image from '../Image/Image';
import IconQuote from '../Icon/icons/IconQuote';
import FadeinViewport from './helper/FadeinViewport';

Quote.propTypes = {
  /** The Quote's Main content. */
  text: PropTypes.string.isRequired,
  /** The Quote's source (the quotee). */
  credit: PropTypes.string,
  /** List of images (usually of the cited person). */
  imagesList: PropTypes.arrayOf(PropTypes.object),
};

Quote.defaultProps = {
  credit: null,
  imagesList: null,
};

const imgOptions = {
  transforms: {
    width: '100',
    aspect: 'square',
  },
};

/*
 * The quote component may result with three different decorations,
 * depends on the props its given.
 *  * [Image](#imageQuote)
 *    When `imagesList` contains an image properties, it will be rendered before the quote.
 *  * [Quote](#iconQuote)
 *    When there isn't any valid image, but `credit` contains a string,
 *    a quote [`<Icon/>`](#Icon) will be rendered before the quote.
 *  * [Border-Top](#borderQuote)
 *    Default. when both `imagesList` and `credit` are empty or contains invalid content,
 *    there will be a simple border on top of the quote.
 */
export default function Quote({
  text,
  credit,
  imagesList,
}) {
  const { css, theme, } = useFela();
  const hasImage = imagesList?.files?.length > 0;
  const hasCredit = credit && credit.trim().length > 0;

  const quoteType = hasImage ? 'image' : hasCredit ? 'quote' : 'border';

  return (
    <FadeinViewport threshold={0.5} mediaQuery={{ until: 'l', }}>
      <blockquote
        className={css({
          marginBottom: '3rem',
          fontWeight: theme.quoteStyle.fontWeight,
          color: theme.color('quote', 'text'),
          '&:before': {
            content: quoteType === 'border' ? '""' : '',
            backgroundColor: theme.color('quote', 'visualElement'),
            width: '16rem',
            display: quoteType === 'border' ? 'inline-block' : 'none',
            marginBottom: theme.quoteStyle.visualElementMarginBottom,
          },
          extend: Array.isArray(theme.quoteStyle.visualElementHeight)
            ? theme.quoteStyle.visualElementHeight.map(
              ({ from, until, value, }) => theme.mq(
                { from, until, },
                { '&:before': { height: value, }, })
            ) : [
              {
                '&:before': {
                  height: theme.quoteStyle.visualElementHeight,
                },
              },
            ],
        })}
      >
        {quoteType === 'image' ? (
          <Image
            imgOptions={imgOptions}
            image={imagesList}
            miscStyles={{
              width: '10rem',
              paddingBottom: '10rem',
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'inline-block',
              marginBottom: theme.quoteStyle.imageMarginBottom,
            }}
          />
        ) : quoteType === 'quote' ? (
          <IconQuote
            size={theme.quoteStyle.iconQuoteSize}
            color={[ 'quote', 'visualElement', ]}
            miscStyles={{
              marginBottom: theme.quoteStyle.iconQuoteMarginBottom,
              ...(theme.direction === 'ltr' ? { transform: 'rotate(180deg)', } : {}),
            }}
          />
        ) : null}
        <QuoteText>{text}</QuoteText>
        <Cite>{credit}</Cite>
      </blockquote>
    </FadeinViewport>
  );
}

// /////////////////////////////////////////////////////////////////////
//                          Inner Components                          //
// /////////////////////////////////////////////////////////////////////

QuoteText.propTypes = { children: PropTypes.node.isRequired, };
function QuoteText({ children, }) {
  const { css, theme, } = useFela();
  return (
    <p
      className={css({
        marginBottom: '1rem',
        extend: [
          parseTypographyProp(theme.quoteStyle.type, theme.type),
        ],
      })}
    >
      {children}
    </p>
  );
}

Cite.propTypes = { children: PropTypes.node, };
Cite.defaultProps = { children: null, };
function Cite({ children, }) {
  const { css, theme, } = useFela();
  return (
    children && (
      <span
        className={css({
          backgroundColor: theme.color('quote', 'creditHighlight'),
          color: theme.color('quote', 'creditText'),
          paddingInlineEnd: '1rem',
          paddingInlineStart: '1rem',
          paddingBottom: theme.quoteStyle.citeVerticalPadding,
          paddingTop: theme.quoteStyle.citeVerticalPadding,
          extend: [
            parseTypographyProp(theme.quoteStyle.citeType, theme.type),
          ],
        })}
      >
        {children}
      </span>
    )
  );
}
