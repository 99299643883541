import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';
import Button from '../Button/Button';
import EventTracker from '../../utils/EventTracker';

// import HtzLink from '../HtzLink/HtzLink';

NonPayingNickNameAlert.propTypes = {
  /** Callback that gets called when the close button is clicked */
  closeDisplay: PropTypes.func.isRequired,
  isReplyForm: PropTypes.bool,
  biImpression: PropTypes.func,
};
NonPayingNickNameAlert.defaultProps = {
  isReplyForm: false,
  biImpression: () => null,
};
function NonPayingNickNameAlert({ closeDisplay, isReplyForm, biImpression, }) {
  const { theme, css, } = useFela();
  const [ isImpressionSend, setIsImpressionSend, ] = React.useState(false);
  const {
    buttons: { nonPayingNickNamePromotionBtn, nonPayingNickNameNoThanks, promotionUrl, },
    texts: { nonPayingNickNameAlertText, },
  } = theme.commentFormI18n;

  const impressionParams = {
    actionCode: 3,
    feature: 'Talkbacks',
    featureType: 'Content',
    campaignName: 'Nickname Promotion',
    campaignDetails: nonPayingNickNamePromotionBtn,
  };


  React.useEffect(() => {
    if (!isImpressionSend) {
      biImpression(impressionParams);
      setIsImpressionSend(true);
    }
  }, [ impressionParams, biImpression, isImpressionSend, ]);

  return (
    <EventTracker>
      {({ biAction, biActionMapper, }) => (
        <div
          className={css({
            backgroundColor: theme.color('input', 'primaryBg'),
            paddingInlineStart: '4rem',
            paddingInlineEnd: '4rem',
            paddingTop: '5.4rem',
            paddingBottom: 'calc(11rem - 1px)',
            marginTop: '2rem',
            ...(isReplyForm ? { marginBottom: '-2rem', } : {}),
            textAlign: 'center',
            //   paddingTop: '9.4rem',
            ...borderBottom('1px', 8, 'solid', theme.color('comments', 'divider')),
          })}
        >
          <p
            className={css({
              paddingBottom: '8rem',
              paddingInlineEnd: '5rem',
              paddingInlineStart: '5rem',
              color: theme.color('comments', 'nameAlertTxt'),
            })}
          >
            {nonPayingNickNameAlertText}
          </p>
          <div className={css({ marginBottom: '3rem', })}>
            <Button
              miscStyles={{ ...theme.type(-1), }}
              variant="salesOpaque"
              href={promotionUrl(nonPayingNickNamePromotionBtn)}
              onClick={() => biAction({
                actionCode: 3,
                feature: 'Talkbacks',
                featureType: 'Marketing',
                campaignName: 'Nickname Promotion',
                campaignDetails: nonPayingNickNamePromotionBtn,
              })
              }
            >
              {nonPayingNickNamePromotionBtn}
            </Button>
          </div>
          <button
            type="button"
            className={css({
              ...theme.type(-2),
              textDecoration: 'underline',
              color: theme.color('comments', 'nameAlertTxt'),
            })}
            onClick={closeDisplay}
          >
            {nonPayingNickNameNoThanks}
          </button>
        </div>
      )}
    </EventTracker>
  );
}

export default NonPayingNickNameAlert;
