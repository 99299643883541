import React from 'react';
import PropTypes from 'prop-types';
import ReadingHistoryCountProvider from '../../../ReadingHistory/ReadingHistoryCountProvider';

const ShouldRenderSpotProvider = ({ children, renderFrequency, }) => (
  <ReadingHistoryCountProvider>
    {readingHistoryCount => {
      let shouldRenderSpot;
      // When renderFrequency is not defined or equal to 0, show spot list.
      if (!renderFrequency || renderFrequency === 0) {
        shouldRenderSpot = true;
      }
      else {
        shouldRenderSpot = readingHistoryCount && readingHistoryCount % renderFrequency === 0;
      }
      return children(shouldRenderSpot);
    }}
  </ReadingHistoryCountProvider>
);

ShouldRenderSpotProvider.defaultProps = {
  renderFrequency: null,
};

ShouldRenderSpotProvider.propTypes = {
  children: PropTypes.func.isRequired,
  renderFrequency: PropTypes.number,
};

export default ShouldRenderSpotProvider;
