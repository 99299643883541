import React from 'react';
import dynamic from 'next/dynamic';
import {
  // General Elements
  ArticleBody,
  ArticleBodyImage,
  ArticleGraphController,
  ArticlesReadCounter,
  Breadcrumbs,
  BreakingNewsBox,
  PromotionalCardElement,
  ChangeableElementGroup,
  ClickTrackerWrapper,
  Debug,
  ElementGroup,
  Embed,
  Enhancement,
  Error,
  AdSlot,
  AdSlotPrioritySelector,
  GridContainer,
  GridElement,
  HtmlElement,
  HtmlNode,
  HtzFooter,
  HtzLink,
  Image,
  ImageGallery,
  InfographicRichText,
  Interactive,
  InteractiveRichText,
  LinksBlock,
  List,
  MagazineRecipes,
  Masthead,
  MobileListWrapper,
  Outbrain,
  PodcastEpisode,
  Quote,
  RelatedArticles,
  RssFeed,
  SeriesArticles,
  SpecialPromotions,
  TableScore,
  Tags,
  TopNews,
  Video,
  WrappedComments,
  withDfpSideEffect,

  // List views
  Bender,
  Farnsworth,
  Leela,
  Zoidberg,
  ZoidbergLabels,
  Clampazzo,
  Marco,
  Michelle,
  Spot,
  Pazuzu,

  // icons
  IconAlefLogo,
  IconHaaretzLogo,
  IconAlefLogoTransparent,
  IconHtzLoader,
  IconHaaretzLabel,
  // utils
  getElementsFactory,
} from '@haaretz/htz-components';

const ArticleBodyAudio = dynamic(() => import('../components/ArticleBodyAudio/ArticleBodyAudio'));

const ArticleNarration = dynamic(() => import('../components/ArticleNarration/ArticleNarration'));

const Newsletter = dynamic(() => import('../components/Newsletter/Newsletter'), {
  loading: () => null,
  ssr: false,
});

// List views
const StoryDesktop = dynamic(() => import('../components/StoryListViews/StoryDesktop'), {
  loading: () => null,
});
const StoryMobile = dynamic(() => import('../components/StoryListViews/StoryMobile'), {
  loading: () => null,
});

const tagsFromTagElement = ({ tagsList, }) => tagsList.map(x => x.contentName);

const fileUpload = dynamic(
  () => import('../components/FileUpload'),
  {
    ssr: false,
    loading: () => null,
  }
);

// Infographics
const VisualInfo = dynamic(
  () => import('../components/Infographics/VisualInfo'),
  { loading: () => null, }
);

const Questions = dynamic(
  () => import('../components/Infographics/Questions'),
  { loading: () => null, }
);

const Columns = dynamic(
  () => import('../components/Infographics/Columns'),
  { loading: () => null, }
);

const Timeline = dynamic(
  () => import('../components/Infographics/Timeline'),
  { loading: () => null, }
);

const Ids = dynamic(
  () => import('../components/Infographics/Ids'),
  { loading: () => null, }
);

const Chat = dynamic(
  () => import('../components/Infographics/Chat'),
  { loading: () => null, }
);

const InfographicList = dynamic(
  () => import('../components/Infographics/InfographicList'),
  { loading: () => null, }
);

const MagazineQuotes = dynamic(() => import('../components/Interactive/MagazineQuotes'), { ssr: true, });

// eslint-disable-next-line react/prop-types
function MastheadLogo({ miscStyles, themeColor, }) {
  return (
    <IconHaaretzLogo
      attrs={{ 'aria-hidden': true, }}
      size={3.5}
      color={[ themeColor, 'mastheadLogo', ]}
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function MastheadLabelLogo() {
  return (
    <IconHaaretzLabel
      attrs={{ 'aria-hidden': true, }}
      size={5}
      fill={[ 'bodyText', 'base', ]}
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

const elements = new Map([
  [ 'HtmlNode', HtmlNode, ],
  [ 'Enhancement', Enhancement, ],
  /* HeaderSlots components */
  [ 'PageNavigation', Masthead, ],
  // [ 'com.tm.PageTitle', Breadcrumbs, ],

  /* MainSlot components */
  // [ 'com.htz.StandardArticle', ArticleBody, ],
  [ 'Outbrain', Outbrain, ],
  [ 'CommentsPlaceHolder', WrappedComments, ],

  /* FooterSlot components */
  [ 'PageFooter', HtzFooter, ],

  /* ArticleBody components */
  [ 'CardRichTextElement', PromotionalCardElement, ],
  [ 'EmbedRichTextElement', Embed, ],
  [ 'Embed', Embed, ],
  [ 'FileElementRichTextElement', fileUpload, ],
  [ 'interactive', Interactive, ],
  [ 'InteractiveRichTextElement', Interactive, ],
  // [ 'com.tm.ArticleInteractiveHtmlElement', Interactive, ],
  // [ 'com.polobase.audioEmbed', ArticleBodyAudio, ],
  // [ 'articleNarration', ArticleNarration, ],ƒ
  [ 'articleHeaderAudio', ArticleBodyAudio, ],
  [ 'QuoteRichTextElement', Quote, ],
  [ 'Quotes', MagazineQuotes, ],
  // [ 'linksBlock', LinksBlock, ],
  [ 'core_link_Link', HtzLink, ],
  // [ 'com.polobase.quickNewsletterRegistration', MobileNewsletter, ],
  [ 'RelatedArticles', RelatedArticles, ],
  [ 'SeriesArticles', SeriesArticles, ],
  [ 'PodcastEpisode', PodcastEpisode, ],
  [ 'htz_link_Link', SpecialPromotions, ],
  // [ 'com.polobase.MemberEventElement', ArticlesReadCounter, ],
  /*
   * This is only a temporary solution to avoid hitting preformance, and SHOULD BE AVOIDED as much as possible.
   * This creates a coupling between different concerns: (rendring components, accessing data)
   * but in this case as tags are deeply nested inside an array we use this to avoid searching them inside the entire page data
   * TODO: expose just the tags in graphql, then replace this temprary solution with one that fetchs the data from graphql
   */
  // [
  //   'tagsElement',
  //   withDfpSideEffect(Tags, {
  //     sideEffect: tagsElement => dfpTargeting.setTags(tagsFromTagElement(tagsElement)),
  //   }),
  // ],
  [
    'GraphRichTextElement',
    // eslint-disable-next-line react/prop-types
    ({ assetId, ...props }) => (assetId ? (
    // eslint-disable-next-line react/prop-types
      <ArticleGraphController assetId={assetId} {...props} />
    ) : null),
  ],

  /* Misc components */
  [ 'GridItem', GridElement, ],
  [ 'Grid', GridContainer, ],
  [ 'image', Image, ],
  [ 'infographic', Image, ],
  [ 'htz_image_Image', Image, ],
  // [ 'com.tm.BlogImage', Image, ],
  // [ 'com.tm.ImageGalleryElement', ImageGallery, ],
  // [ 'gallery', ImageGallery, ],
  // [ 'video', Video, ],
  [ 'HtmlElementRichTextElement', HtmlElement, ],
  [ 'HtmlElement', HtmlElement, ],
  // [ 'com.tm.newsLetterQuickRegistrationRespAuto', ResponsiveNewsletter, ],
  [ 'RegistrationRichTextElement', Newsletter, ],
  // [ 'com.tm.newsLetterPersonal', PersonalNewsletter, ],
  // [
  //   'com.tm.ListElement',
  //   () => (
  //     <Debug>
  //       <p>Old list, NOT SUPPORTED</p>
  //     </Debug>
  //   ),
  // ],
  [ 'htz_list_List', List, ],
  [ 'ListPersonal', List, ],
  // [ 'com.polobase.whtzMobileSiteListsWrapper', MobileListWrapper, ],
  // [ 'com.tm.element.group', ChangeableElementGroup, ],
  [ 'SideBox', ChangeableElementGroup, ],
  // [ 'com.tm.element.group', ChangeableElementGroup, ],
  [ 'HeaderNewsList', props => {
    if (props.kind === 'breakingNews') return <BreakingNewsBox {...props} />;
    return <TopNews {...props} />;
  }, ],
  [ 'topNews', TopNews, ],
  // [ 'com.tm.ElementGroup', ElementGroup, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  [ 'ClickTrackerWrapper', ClickTrackerWrapper, ],
  [ 'TenRecipes', MagazineRecipes, ],
  [ 'TableScore', TableScore, ],
  [ 'TableScoreRichTextElement', TableScore, ],
  [ 'OnlifeFeed', RssFeed, ],
  // [ 'error', Error, ],
  [ null, null, ],

  // Infographic
  [ 'InfographicRichTextElement', InfographicRichText, ],
  [ 'VisualInfo', VisualInfo, ],
  [ 'QAndA', Questions, ],
  [ 'ColumnsModel', Columns, ],
  [ 'Timeline', Timeline, ],
  [ 'Ids', Ids, ],
  [ 'ListInfo', InfographicList, ],
  [ 'Chat', Chat, ],

  // List views
  [ 'Bender', Bender, ],
  [ 'Farnsworth', Farnsworth, ],
  [ 'Leela', Leela, ],
  [ 'Zoidberg', Zoidberg, ],
  [ 'ZoidbergLabels', ZoidbergLabels, ],
  [ 'Clampazzo', Clampazzo, ],
  [ 'Marco', Marco, ],
  [ 'Michelle', Michelle, ],
  [ 'Spot', Spot, ],
  [ 'StoryDesktop', StoryDesktop, ],
  [ 'StoryMobile', StoryMobile, ],

  // Icons
  [ 'articleHeaderMetaLogo', IconAlefLogo, ],
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'mastheadLabelLogo', MastheadLabelLogo, ],
  [ 'siteLogo', IconHaaretzLogo, ],
  [ 'siteIconTransparent', IconAlefLogoTransparent, ],
  [ 'loaderIcon', IconHtzLoader, ],
]);

const getArticleElements = getElementsFactory(elements);

export default getArticleElements;
