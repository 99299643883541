import React from 'react';
import { useFela, } from 'react-fela';


const OrderCommentContext = React.createContext();

export function OrderCommentsProvider(props) {
  const { theme, } = useFela();

  const {
    commentsSectionI18n: {
      selectItems: {
        dateDescendingItemTxt,
      },
    },
  } = theme;

  const [ sortMethod, setSortMethod, ] = React.useState({
    value: 'lastToFirst',
    display: dateDescendingItemTxt,
  });


  return <OrderCommentContext.Provider {...props} value={{ sortMethod, setSortMethod, }} />;
}


export function useOrderComments() {
  const context = React.useContext(OrderCommentContext);
  if (context === undefined) {
    throw new Error('useOrderComments must be used within a OrderCommentProvider');
  }
  return context;
}
