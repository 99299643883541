// @flow
import React from 'react';
import type { Node, } from 'react';
import { ARTICLE_GALLERY_QUERY, } from '@haaretz/graphql';
import { useQuery, } from 'react-apollo';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';

import ImageGallery from '../ImageGallery/ImageGallery';
import { getImagesOfArticle, } from '../Image/utils';
import useArticleBodyData from '../../hooks/Page/useArticleBodyData';
import useArticleData from '../../hooks/Page/useArticleData';

type Props = {
  path: string,
};

const closeGallery: Object => void = client => {
  client.writeData({
    data: {
      pageGallery: {
        isOpen: false,
        startWith: null,
        __typename: 'PageGallery',
      },
    },
  });
};

// eslint-disable-next-line operator-linebreak
const getImageIndex: (Array<ImageDataType>, string) => number = (images, contentId) => images.findIndex(imageObj => imageObj.contentId === contentId);

function ArticleGallery({ path, }: Props): Node {
  const article = useArticleData();
  const body = useArticleBodyData();

  const { loading, error, data, client, } = useQuery(ARTICLE_GALLERY_QUERY);
  if (loading) return null;
  if (error) return null;
  const {
    pageGallery: { isOpen, startWith, } = {},
  } = data || {};

  const articleData = { ...article, body, };

  const images: Array<ImageDataType> = getImagesOfArticle(articleData);

  return isOpen ? (
    <ImageGallery
      startAt={getImageIndex(images, startWith)}
      fullScreenOnly
      exitFullScreenAction={() => closeGallery(client)}
      images={images}
    />
  ) : null;
}

export default ArticleGallery;
