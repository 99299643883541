// @flow
import React, { useState, } from 'react';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { FelaTheme, FelaComponent, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { useQuery, } from 'react-apollo';
import graphQuery from '../Graph/graph.graphql';


import Line from './graphs/Line';

type Props = {
  type: 'line' | 'scatter',
  period: 'day' | 'week' | 'month' | 'year1' | 'year3' | 'year5',
  assetId: string,
  miscStyles?: ?StyleProps,
};

function StaticGraph({ type, period, assetId, miscStyles, }: Props) {
  const { data, loading, error, } = useQuery(
    graphQuery,
    {
      variables: { type, period, assetId, },
    }
  );

  if (loading) return null;
  if (error) return null;
  if (!data) return null;

  const dataSource = data?.graph?.dataSource;


  return (
    <FelaTheme>
      {theme => (
        <FelaComponent
          style={{
            extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
          }}
        >
          {dataSource ? <Line data={dataSource} time={period} theme={theme} /> : null}
        </FelaComponent>
      )}
    </FelaTheme>
  );
}

StaticGraph.defaultProps = {
  miscStyles: null,
};

export default StaticGraph;
